<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Guardians</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>Add up to 4 people as your guardians.</p>
        </div>
      </notification-box>

      <h3 class="mb-4">Your guardians:</h3>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <div
          v-if="!localFormatted.guardians.length"
          class="text-no-result"
          key="empty"
        >
          - Add at least one guardian -
        </div>
        <div
          key="list"
          v-else
        >
          <transition-group
            name="component-fade"
            mode="out-in"
          >
            <item-person
              v-for="(item, index) in localFormatted.guardians"
              :key="item + index"
              class="mb-2"
              :id="item"
              :editable="editable"
              type="person"
              @remove="removeGuardian(index)"
            >
              <template v-slot:details="{ entity }">
                <div
                  v-if="entity.type === 'person'"
                  class="text-sm text-cutoff opacity-70 pt-1"
                >
                  <span
                    v-if="!entity.address"
                    class="font-italic"
                    >- Address Required -</span
                  >
                  <span v-else>
                    {{ entity.address }}
                  </span>
                </div>
              </template>
            </item-person>
          </transition-group>
        </div>
      </transition>

      <div class="text-label my-4">Add a guardian:</div>
      <div class="mb-2">
        <btn-add-large
          label="Add person"
          class="mb-2"
          @click="openDrawerPerson = true"
          @clickDisabled="disabledMessage()"
          :disabled="isMaximumSelected"
        />
      </div>
      <v-input
        :error-messages="
          showErrors.guardians && errorMessages.guardians
            ? errorMessages.guardians
            : null
        "
      />

      <v-expand-transition>
        <div
          v-if="this.local.guardians.length > 1"
          class="mb-4"
        >
          <h2 class="mb-4">
            How would you like to your guardians to work together?
          </h2>
          <item-select
            v-for="(item, index) in appointmentOptions"
            :key="item.key"
            :value="item.key === localFormatted.guardiansAppointment"
            @select="selectAppointmentOption(item.key)"
            :title="item.label"
            :text="item.text"
            :class="{ 'mb-2': index + 1 < appointmentOptions.length }"
          />
          <v-input
            :error-messages="
              showErrors.guardiansAppointment &&
              errorMessages.guardiansAppointment
                ? errorMessages.guardiansAppointment
                : null
            "
          />
        </div>
      </v-expand-transition>

      <v-expand-transition>
        <div
          v-if="this.local.guardians.length > 1"
          class="mb-4"
        >
          <h2 class="mb-4">
            If one or more of my joint enduring guardians die:
          </h2>
          <item-select
            v-for="(item, index) in appointmentTerminationOptions"
            :key="item.key"
            :value="item.key === localFormatted.appointmentTermination"
            @select="selectAppointmentTerminationOption(item.key)"
            :title="item.label"
            :text="item.text"
            :class="{
              'mb-2': index + 1 < appointmentTerminationOptions.length,
            }"
          />
          <v-input
            :error-messages="
              showErrors.appointmentTermination &&
              errorMessages.appointmentTermination
                ? errorMessages.appointmentTermination
                : null
            "
          />
        </div>
      </v-expand-transition>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add guardian"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addGuardian"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="['address']"
      />
    </drawer>
  </div>
</template>

<script>
import { cloneDeep } from "lodash"

import entitiesForms from "../../../mixins/entitiesForms.js"
import forms from "../../../mixins/forms.js"
import {
  appointmentOptions,
  appointmentTerminationOptions,
} from "../../epgLists"

const storeName = "poa_wa_epg"
const documentId = "poaWaEpg"

export default {
  name: "AppointGuardians",
  mixins: [
    forms(storeName, documentId),
    entitiesForms("guardians", "Maximum of 4 guardians."),
  ],
  data: () => ({
    showErrors: {
      guardians: false,
      guardiansAppointment: false,
      appointmentTermination: false,
      originalSubstitutes: [],
    },
    editable: { address: true },
    appointmentOptions: appointmentOptions,
    appointmentTerminationOptions: appointmentTerminationOptions,
  }),
  mounted() {
    this.originalSubstitutes = cloneDeep(this[storeName].substituteGuardians)
  },
  methods: {
    async save() {
      const removedGuardians = this.getRemovedGuardians()
      if (removedGuardians.length) {
        let modifiedSubstitutes = this.originalSubstitutes
        modifiedSubstitutes.forEach((s) => {
          if (removedGuardians.includes(s.inSubstitutionOf))
            s.inSubstitutionOf = null
        })
        modifiedSubstitutes = modifiedSubstitutes.filter(
          (s) => s.inSubstitutionOf !== null
        )
        this.$store.dispatch("account/save_poa", {
          id: documentId,
          data: {
            ...this.localFormatted,
            substituteGuardians: modifiedSubstitutes,
          },
        })
      } else
        this.$store.dispatch("account/save_poa", {
          id: documentId,
          data: this.localFormatted,
        })
    },
    getRemovedGuardians() {
      const removed = []
      this[storeName].guardians.forEach((guardian) => {
        if (!this.localFormatted.guardians.includes(guardian)) {
          removed.push(guardian)
        }
      })
      return removed
    },
    addGuardian(id) {
      this.setAllShowErrors(false)
      this.local.guardians.push(id)
      this.openDrawerPerson = false
    },
    removeGuardian(index) {
      this.setAllShowErrors(false)
      this.$delete(this.local.guardians, index)
    },
    selectAppointmentOption(option) {
      this.setAllShowErrors(false)
      this.local.guardiansAppointment = option
    },
    selectAppointmentTerminationOption(option) {
      this.setAllShowErrors(false)
      this.local.appointmentTermination = option
    },
  },
  computed: {
    excludePeople() {
      return [
        ...this.childEntities,
        ...this.localFormatted.guardians,
        ...this.local.substituteGuardians.map((s) => s.entity),
      ]
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (!this.localFormatted.guardians.length)
        msgs.guardians = "Select at least one guardian."
      if (this.localFormatted.guardians.length > 4)
        msgs.guardians = "Maximum of 4 guardians."
      if (
        this.localFormatted.guardians.length > 1 &&
        !this.localFormatted.guardiansAppointment
      )
        msgs.guardiansAppointment = "Required"
      if (
        this.localFormatted.guardians.length > 1 &&
        !this.localFormatted.appointmentTermination
      )
        msgs.appointmentTermination = "Required"
      return msgs
    },
    localFormatted() {
      let guardiansAppointment = this.local?.guardiansAppointment || null
      let guardiansAppointmentOption = guardiansAppointment
        ? this.appointmentOptions.find((o) => o.key === guardiansAppointment) ||
          null
        : null
      let appointmentTermination = this.local?.appointmentTermination || null
      let appointmentTerminationOption = appointmentTermination
        ? this.appointmentTerminationOptions.find(
            (o) => o.key === appointmentTermination
          ) || null
        : null
      return {
        guardians: this.local.guardians || [],
        appointmentTermination:
          this.local.guardians.length <= 1
            ? null
            : appointmentTerminationOption
            ? appointmentTermination
            : null,
        guardiansAppointment:
          this.local.guardians.length <= 1
            ? null
            : guardiansAppointmentOption
            ? guardiansAppointment
            : null,
      }
    },
  },
}
</script>
