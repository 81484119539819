<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Views, wishes and preferences</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            Your attorney(s) must consider your views, wishes and preferences
            when making decisions for you but these are not instructions to your
            attorney(s).
          </p>
          <p>
            This section allows you to provide information about yourself,
            including what is most important to you in life now and into the
            future.
          </p>
          <p>
            What would you like your attorney(s) to know about you? Please
            include details about:
          </p>
          <ul>
            <li>Your views about where you would prefer to live.</li>
            <li>Your health care preferences.</li>
            <li>Any other views, wishes or preferences.</li>
          </ul>
        </div>
      </notification-box>

      <h3 class="mb-4">
        Would you like to provide your views, wishes and preferences?
      </h3>

      <div class="toggle-yes-no">
        <item-select
          :value="showTextbox"
          @select="setShowTextbox(true)"
          title="Yes"
        />
        <item-select
          :value="!showTextbox"
          @select="setShowTextbox(false)"
          title="No"
        />
      </div>

      <v-textarea
        :rules="[maxLengthRule]"
        v-if="showTextbox"
        v-bind="textAreaFieldGrow"
        v-model="local.wishes"
        :error-messages="
          showErrors.wishes && errorMessages.wishes
            ? errorMessages.wishes
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from "../../../mixins/forms.js"
import { isEmptyTrimmed } from "../../../utils"

const storeName = "poa_qld_epa"
const documentId = "poaQldEpa"

export default {
  name: "ViewsWishesPreferences",
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      wishes: false,
    },
    showTextbox: false,
  }),
  mounted() {
    this.showTextbox = !!this.local.wishes
  },
  methods: {
    setShowTextbox(value) {
      this.setAllShowErrors(false)
      this.showTextbox = value
    },
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: this.localFormatted,
      })
    },
  },
  computed: {
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.showTextbox && isEmptyTrimmed(this.localFormatted.wishes))
        msgs.wishes = "Please enter your wishes."
      return msgs
    },
    localFormatted() {
      return {
        wishes: this.showTextbox ? this.local.wishes : null,
      }
    },
  },
}
</script>
