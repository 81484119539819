<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Medical Treatment Decision Makers</h2>
      <p>
        Your medical treatment decision maker has legal authority to make
        medical treatment decisions on your behalf, if you do not have
        decision-making capacity to make the decision.
      </p>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>Add up to 4 people as your medical treatment decision makers.</p>
          <p>
            Your medical treatment decision maker is the first person you list
            below who is reasonably available, and willing and able to make the
            decision.
          </p>
          <p>
            If appointing more than one, use the arrows to the right of the
            medical treatment decision makers to change their order.
          </p>
        </div>
      </notification-box>

      <h3 class="mb-4">
        Your medical treatment decision maker{{ decisionMakerSuffix }}:
      </h3>
      <div
        v-if="!localFormatted.decisionMakers.length"
        class="text-no-result"
        key="empty"
      >
        - Add at least one medical treatment decision maker -
      </div>
      <div
        key="list"
        v-else
      >
        <transition-group
          name="component-fade"
          mode="out-in"
        >
          <div
            v-for="(item, index) in localFormatted.decisionMakers"
            :key="item"
            class="mb-4 d-flex"
          >
            <item-person
              :id="item"
              :rank="index + 1"
              @remove="removeDecisionMaker(index)"
              class="flex-grow-1"
              :editable="editable"
              type="person"
              :is-adult="true"
              style="max-width: calc(100% - 40px)"
            >
              <template v-slot:details="{ entity }">
                <div
                  v-if="entity.type === 'person'"
                  class="text-sm text-cutoff opacity-70 pt-1"
                >
                  <span
                    v-if="!allRequiredFieldsComplete(entity)"
                    class="font-italic"
                    >- Information required -</span
                  >
                  <span v-else>
                    {{ entity.address }}
                  </span>
                </div>
              </template>
            </item-person>
            <div class="d-flex flex-column ml-1 justify-space-between">
              <div style="height: 50%">
                <v-btn
                  icon
                  @click="index > 0 ? moveUp(index) : null"
                  :color="index > 0 ? 'black' : 'grey lighten-2'"
                  :style="index === 0 ? 'cursor: default;' : ''"
                  ><v-icon>mdi-chevron-up</v-icon></v-btn
                >
              </div>
              <div style="height: 50%">
                <v-btn
                  icon
                  @click="
                    local.decisionMakers.length - index > 1
                      ? moveDown(index)
                      : null
                  "
                  :color="
                    local.decisionMakers.length - index > 1
                      ? 'black'
                      : 'grey lighten-2'
                  "
                  :style="
                    local.decisionMakers.length - index > 1
                      ? ''
                      : 'cursor: default;'
                  "
                  ><v-icon>mdi-chevron-down</v-icon></v-btn
                >
              </div>
            </div>
          </div>
        </transition-group>
      </div>

      <div class="text-label my-4">Add a medical treatment decision maker:</div>
      <div class="mb-2">
        <btn-add-large
          label="Add person"
          class="mb-2"
          @click="openDrawerPerson = true"
          @clickDisabled="disabledMessage()"
          :disabled="isMaximumSelected"
        />
      </div>
      <v-input
        :error-messages="
          showErrors.decisionMakers && errorMessages.decisionMakers
            ? errorMessages.decisionMakers
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add decision maker"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addDecisionMaker"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="required"
        :is-adult="true"
      />
    </drawer>
  </div>
</template>

<script>
import { vic_mtdm_required } from "../../../../../../config/poaRequiredFields"
import entitiesForms from "../../../mixins/entitiesForms.js"
import forms from "../../../mixins/forms.js"

const storeName = "poa_vic_mtdm"
const documentId = "poaVicMtdm"

export default {
  name: "AppointDecisionMakers",
  mixins: [
    forms(storeName, documentId),
    entitiesForms(
      "decisionMakers",
      "Maximum of 4 medical treatment decision makers."
    ),
  ],
  data: () => ({
    showErrors: {
      decisionMakers: false,
    },
    editable: { address: true, dob: true, phone: true },
    required: vic_mtdm_required,
  }),
  methods: {
    addDecisionMaker(id) {
      this.setAllShowErrors(false)
      this.local.decisionMakers.push(id)
      this.openDrawerPerson = false
    },
    removeDecisionMaker(index) {
      this.setAllShowErrors(false)
      this.$delete(this.local.decisionMakers, index)
    },
    selectAppointmentOption(option) {
      this.setAllShowErrors(false)
      this.local.appointment = option
    },
    selectAppointmentTerminationOption(option) {
      this.setAllShowErrors(false)
      this.local.appointmentTermination = option
    },
    moveUp(index) {
      this.moveDecisionMaker(index, index - 1)
    },
    moveDown(index) {
      this.moveDecisionMaker(index, index + 1)
    },
    moveDecisionMaker(from, to) {
      this.local.decisionMakers.splice(
        to,
        0,
        this.local.decisionMakers.splice(from, 1)[0]
      )
    },
  },
  computed: {
    excludePeople() {
      return [...this.childEntities, ...this.localFormatted.decisionMakers]
    },
    decisionMakerSuffix() {
      return this.local.decisionMakers.length > 1 ? "s" : ""
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (!this.localFormatted.decisionMakers.length)
        msgs.decisionMakers = "Add at least one decision maker."
      if (this.localFormatted.decisionMakers.length > 4)
        msgs.decisionMakers = "Maximum of 4 decisionMakers."
      return msgs
    },
    localFormatted() {
      return {
        decisionMakers: this.local.decisionMakers || [],
      }
    },
  },
}
</script>
