<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Unwanted medical treatments</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            It is strongly recommended that before completing this document you
            discuss your options with your doctor who knows your medical history
            and views. The doctor will also be able to explain any medical terms
            that you are unsure about and will confirm that you were able to
            understand the decisions you have made in the document and that you
            made those decisions voluntarily. You can also ask your doctor to
            witness your signature.
          </p>
          <p>
            It is your legal right to refuse any medical treatment. However, you
            may not be entitled to insist on receiving a particular treatment
            (if, for example, your health-care provider’s professional opinion
            is that the treatment would not be of benefit to you).
          </p>
        </div>
      </notification-box>

      <h3 class="mb-4">
        Are there specific medical treatments that you do not want?
      </h3>

      <item-select
        :value="local.advanceConsentDecision.unwantedMedicalTreatments.feeding"
        @select="toggleOption('feeding')"
        title="Artificial feeding/tube feeding"
        class="mb-2"
      />

      <item-select
        :value="local.advanceConsentDecision.unwantedMedicalTreatments.dialysis"
        @select="toggleOption('dialysis')"
        title="Renal dialysis"
        class="mb-2"
      />

      <item-select
        :value="
          local.advanceConsentDecision.unwantedMedicalTreatments.transfusions
        "
        @select="toggleOption('transfusions')"
        title="Blood transfusions"
        class="mb-2"
      />

      <item-select
        :value="showOtherTextbox"
        @select="toggleShowOther()"
        title="Other:"
        class="mb-2"
      >
        <v-expand-transition>
          <div
            class="mx-4"
            v-if="showOtherTextbox"
          >
            <h4 class="my-4">Please specify</h4>
            <v-textarea
              :rules="[maxLengthRule]"
              v-bind="textAreaFieldGrow"
              v-model="
                local.advanceConsentDecision.unwantedMedicalTreatments.other
              "
              :error-messages="
                showErrors.other && errorMessages.other
                  ? errorMessages.other
                  : null
              "
            />
          </div>
        </v-expand-transition>
      </item-select>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from "../../../mixins/forms.js"
import { trimIfExists } from "../../../utils"

const storeName = "poa_nt_app"
const documentId = "poaNtApp"

export default {
  name: "MedicalTreatments",
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      other: false,
    },
    showOtherTextbox: false,
  }),
  mounted() {
    this.showOtherTextbox =
      this.local.advanceConsentDecision.unwantedMedicalTreatments.other
  },
  methods: {
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: {
          advanceConsentDecision: {
            ...this.localFormatted.advanceConsentDecision,
            unwantedMedicalTreatments: {
              ...this.localFormatted.advanceConsentDecision
                .unwantedMedicalTreatments,
              other: trimIfExists(
                this.localFormatted.advanceConsentDecision
                  .unwantedMedicalTreatments.other
              ),
            },
          },
        },
      })
    },
    toggleOption(option) {
      this.local.advanceConsentDecision.unwantedMedicalTreatments[option] =
        !this.local.advanceConsentDecision.unwantedMedicalTreatments[option]
    },
    toggleShowOther() {
      this.showErrorsSwitch(false, "other")
      this.showOtherTextbox = !this.showOtherTextbox
    },
  },
  computed: {
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (
        this.showOtherTextbox &&
        !this.local.advanceConsentDecision.unwantedMedicalTreatments.other
      )
        msgs.other = "Please specify"
      return msgs
    },
    localFormatted() {
      return {
        advanceConsentDecision: {
          ...this.local.advanceConsentDecision,
          unwantedMedicalTreatments: {
            feeding:
              this.local &&
              this.local.advanceConsentDecision.unwantedMedicalTreatments
                .feeding
                ? true
                : false,
            dialysis:
              this.local &&
              this.local.advanceConsentDecision.unwantedMedicalTreatments
                .dialysis
                ? true
                : false,
            transfusions:
              this.local &&
              this.local.advanceConsentDecision.unwantedMedicalTreatments
                .transfusions
                ? true
                : false,
            other:
              this.local && this.showOtherTextbox
                ? this.local.advanceConsentDecision.unwantedMedicalTreatments
                    .other
                : null,
          },
        },
      }
    },
  },
}
</script>
