<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Advance Health Directive</h2>

      <h3 class="mb-4">Have you made an Advance Health Directive?</h3>

      <div class="toggle-yes-no">
        <item-select
          :value="localFormatted.advanceHealthDirective"
          @select="setAdvanceHealthDirective(true)"
          title="Yes"
        />
        <item-select
          :value="localFormatted.advanceHealthDirective === false"
          @select="setAdvanceHealthDirective(false)"
          title="No"
        />
      </div>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from "../../../mixins/forms.js"

const storeName = "poa_wa_epg"
const documentId = "poaWaEpg"

export default {
  name: "Circumstances",
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      advanceHealthDirective: false,
    },
  }),
  methods: {
    setAdvanceHealthDirective(value) {
      this.setAllShowErrors(false)
      this.local.advanceHealthDirective = value
    },
  },
  computed: {
    localFormatted() {
      return {
        advanceHealthDirective: this.local.advanceHealthDirective,
      }
    },
  },
}
</script>
