<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Binding refusals of health care</h2>

      <h3 class="mb-4">
        If you have any binding refusals of health care please list them:
      </h3>
      <v-textarea
        :rules="[maxLengthRule]"
        v-bind="textAreaFieldGrow"
        v-model="local.bindingRefusals"
        :dense="true"
        :error-messages="
          showErrors.bindingRefusals && errorMessages.bindingRefusals
            ? errorMessages.bindingRefusals
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from "../../../mixins/forms.js"
import { trimIfExists } from "../../../utils"

const storeName = "poa_sa_acd"
const documentId = "poaSaAcd"

export default {
  name: "BindingRefusals",
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {},
  }),
  methods: {
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: {
          bindingRefusals: trimIfExists(this.localFormatted.bindingRefusals),
        },
      })
    },
  },
  computed: {
    localFormatted() {
      return {
        bindingRefusals: this.local.bindingRefusals
          ? this.local.bindingRefusals
          : null,
      }
    },
  },
}
</script>
