<template>
  <div>
    <div>
      <section-item
        @edit="open('appoint-attorneys')"
        class="mb-1"
      >
        <div class="text-label mb-2">Attorneys</div>
        <ul
          v-if="attorneys.length"
          class="mt-2"
        >
          <li
            v-for="item in attorneys"
            :key="item.id"
            class="mb-1"
          >
            {{ item.name }}
            <chip-required
              v-if="!item.address || !item.address.trim().length"
              text="Address required"
              @click="openEditPerson(item.id)"
              :class="{ shake: shake }"
            />
          </li>
        </ul>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('appoint-attorneys')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to add at least 1 attorney.
          </div>
        </div>
      </section-item>

      <dialog-full
        :open.sync="dialogOpen"
        title="Enduring Power of Attorney"
        :path="gaUrl"
      >
        <component
          v-if="openForm"
          :is="`dialog-${openForm}`"
          @close="close"
          @next="next"
        />
      </dialog-full>
    </div>
    <drawer
      title="Edit Person"
      :open.sync="openEdit"
    >
      <div class="pa-4">
        <slot name="form">
          <form-person
            v-if="openEdit"
            :id="editId"
            @close="openEdit = false"
            @next="openEdit = false"
            :editable="editable"
          />
        </slot>
      </div>
    </drawer>
  </div>
</template>

<script>
import { tas_epa_required } from "../../../../config/poaRequiredFields"
import poa from "../mixins/poa.js"
import DialogAppointAttorneys from "./forms/epa/AppointAttorneys.vue"

const storeName = "poa_tas_epa"
const documentDir = "tasEpa"

export default {
  name: "PoaEnduringPowerOfAttorney",
  mixins: [poa(storeName)],
  components: {
    DialogAppointAttorneys,
  },

  data: () => ({
    editable: { address: true },
    required: tas_epa_required,
    documentDir: documentDir,
  }),
  methods: {},
  computed: {
    attorneys() {
      return this.entities_people.filter((o) =>
        this[storeName].attorneys.includes(o.id)
      )
    },
  },
}
</script>
