<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Attorneys</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>Add up to 4 people as your attorneys.</p>
        </div>
      </notification-box>

      <h3 class="mb-4">Your attorneys:</h3>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <div
          v-if="!localFormatted.attorneys.length"
          class="text-no-result"
          key="empty"
        >
          - Add at least one attorney -
        </div>
        <div
          key="list"
          v-else
        >
          <transition-group
            name="component-fade"
            mode="out-in"
          >
            <item-person
              v-for="(item, index) in localFormatted.attorneys"
              :key="item + index"
              class="mb-2"
              :id="item"
              :editable="editable"
              type="person"
              @remove="removeAttorney(index)"
            >
              <template v-slot:details="{ entity }">
                <div
                  v-if="entity.type === 'person'"
                  class="text-sm text-cutoff opacity-70 py-1"
                >
                  <span
                    v-if="!entity.address"
                    class="font-italic"
                    >- Address Required -</span
                  >
                  <span v-else>
                    {{ entity.address }}
                  </span>
                </div>
              </template>
            </item-person>
          </transition-group>
        </div>
      </transition>

      <div class="text-label my-4">Add an attorney:</div>
      <div class="mb-2">
        <btn-add-large
          label="Add person"
          class="mb-2"
          @click="openDrawerPerson = true"
          @clickDisabled="disabledMessage()"
          :disabled="isMaximumSelected"
        />
      </div>
      <v-input
        :error-messages="
          showErrors.attorneys && errorMessages.attorneys
            ? errorMessages.attorneys
            : null
        "
      />

      <v-expand-transition>
        <div
          v-if="this.local.attorneys.length > 1"
          class="mb-4"
        >
          <h2 class="mb-4">How must your attorneys make decisions?</h2>
          <item-select
            :value="localFormatted.appointment === 'jointly'"
            @select="selectAppointmentOption('jointly')"
            title="Jointly"
            class="mb-2"
          />
          <item-select
            :value="localFormatted.appointment === 'jointly-and-severally'"
            @select="selectAppointmentOption('jointly-and-severally')"
            title="Jointly and severally"
          />
          <v-input
            :error-messages="
              showErrors.appointment && errorMessages.appointment
                ? errorMessages.appointment
                : null
            "
          />
        </div>
      </v-expand-transition>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add attorney"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addAttorney"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="['address']"
      />
    </drawer>
  </div>
</template>

<script>
import entitiesForms from "../../../mixins/entitiesForms.js"
import forms from "../../../mixins/forms.js"

const storeName = "poa_tas_epa"
const documentId = "poaTasEpa"

export default {
  name: "AppointAttorneys",
  mixins: [
    forms(storeName, documentId),
    entitiesForms("attorneys", "Maximum of 4 attorneys."),
  ],
  data: () => ({
    showErrors: {
      attorneys: false,
      appointment: false,
    },
    editable: { address: true },
  }),
  methods: {
    addAttorney(id) {
      this.setAllShowErrors(false)
      this.local.attorneys.push(id)
      this.openDrawerPerson = false
    },
    removeAttorney(index) {
      this.setAllShowErrors(false)
      this.$delete(this.local.attorneys, index)
    },
    selectAppointmentOption(option) {
      this.setAllShowErrors(false)
      this.local.appointment = option
    },
  },
  computed: {
    excludePeople() {
      return [...this.childEntities, ...this.localFormatted.attorneys]
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (!this.localFormatted.attorneys.length)
        msgs.attorneys = "Select at least one attorney."
      if (this.localFormatted.attorneys.length > 4)
        msgs.attorneys = "Maximum of 4 attorneys."
      if (
        this.localFormatted.attorneys.length > 1 &&
        !this.localFormatted.appointment
      )
        msgs.appointment = "Required"
      return msgs
    },
    localFormatted() {
      return {
        attorneys: this.local.attorneys || [],
        appointment:
          this.local.attorneys.length > 1 &&
          (this.local.appointment === "jointly" ||
            this.local.appointment === "jointly-and-severally")
            ? this.local.appointment
            : null,
      }
    },
  },
}
</script>
