<template>
  <div>
    <div>
      <section-item
        @edit="open('matters')"
        class="mb-1"
      >
        <div class="text-label">Matters</div>
        <div v-if="!this.local.matters">
          <chip-required
            :class="{ shake: shake }"
            @click="open('matters')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to specify the matters for which the
            attorney{{ attorneySuffix }} can act.
          </div>
        </div>
        <div v-else>
          <p>
            Your attorneys are to be appointed for
            <span v-if="this.local.matters === 'all'">
              <b>all matters</b>.
            </span>
            <span v-if="this.local.matters === 'personal'">
              <b>personal matters only</b>.
            </span>
            <span v-if="this.local.matters === 'financial'">
              <b>financial matters only</b>.
            </span>
            <span v-if="this.local.matters === 'specified'">
              some specified matters.
            </span>
            <span v-if="this.local.revocation">
              You have also specified details about a previous Enduring Power of
              Attorney that you wish to not be revoked.
            </span>
          </p>
        </div>
      </section-item>

      <section-item
        @edit="open('attorneys')"
        class="mb-1"
      >
        <div class="text-label mb-2">Attorneys</div>
        <ul
          v-if="attorneys.length"
          class="mt-2"
        >
          <li
            v-for="item in attorneys"
            :key="item.entity"
            class="mb-2"
          >
            {{ item.name }}
            <div
              v-if="item.alternatives.length"
              class="pt-2"
            >
              <i>alternative{{ item.alternatives.length > 1 ? "s" : "" }}:</i>
              <ul class="mt-1">
                <li
                  v-for="alternative in item.alternatives"
                  :key="alternative.id + item.entity"
                >
                  {{ alternative.name }}
                  <chip-required
                    v-if="!allRequiredFieldsComplete(alternative)"
                    text="Information required"
                    @click="openEditPerson(alternative.id)"
                    :class="{ shake: shake }"
                  />
                </li>
              </ul>
            </div>
            <chip-required
              v-if="!allRequiredFieldsComplete(item)"
              text="Information required"
              @click="openEditPerson(item.entity)"
              :class="{ shake: shake }"
            />
          </li>
        </ul>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('attorneys')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to add at least 1 attorney.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('commencement')"
        class="mb-1"
      >
        <div class="text-label">Commencement</div>
        <div v-if="commencementSummary">
          {{ commencementSummary }}
        </div>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('commencement')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to specify when this Enduring Power of
            Attorney is to commence.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('conditions')"
        class="mb-1"
      >
        <div class="text-label optional">(Optional)</div>
        <div class="text-label">Conditions and instructions</div>
        <div
          v-if="!hasConditions"
          class="text-no-result text-left"
        >
          Click 'Edit' on the right to optionally add conditions and
          instructions.
        </div>
        <div v-else>You have added some conditions/instructions.</div>
      </section-item>

      <section-item
        @edit="open('signature-assistance')"
        class="mb-1"
      >
        <div class="text-label optional">(Optional)</div>
        <div class="text-label">Assistance with signing</div>
        <div
          v-if="!local.signatureAssistance"
          class="text-no-result text-left"
        >
          Click 'Edit' on the right to complete this section if you need someone
          to sign your Enduring Power of Attorney on your behalf due to a
          physical disability.
        </div>
        <div v-else>
          You have specified that you require someone else to sign your Enduring
          Power of Attorney for you due to a physical disability.
        </div>
      </section-item>

      <dialog-full
        :open.sync="dialogOpen"
        title="Enduring Power of Attorney"
        :path="gaUrl"
        ref="dialog"
      >
        <component
          v-if="openForm"
          :is="`dialog-${openForm}`"
          @close="close"
          @next="next"
          @top="scrollTop"
        />
      </dialog-full>
    </div>
    <drawer
      title="Edit Person"
      :open.sync="openEdit"
    >
      <div class="pa-4">
        <slot name="form">
          <form-person
            v-if="openEdit"
            :id="editId"
            @close="openEdit = false"
            @next="openEdit = false"
            :editable="editable"
          />
        </slot>
      </div>
    </drawer>
  </div>
</template>

<script>
import { vic_epa_required } from "../../../../config/poaRequiredFields"
import poa from "../mixins/poa.js"
import { joinWithAndOnLast } from "../utils"
import DialogAttorneys from "./forms/epa/Attorneys.vue"
import DialogCommencement from "./forms/epa/Commencement.vue"
import DialogConditions from "./forms/epa/Conditions.vue"
import DialogMatters from "./forms/epa/Matters.vue"
import DialogSignatureAssistance from "./forms/epa/SignatureAssistance.vue"

const storeName = "poa_vic_epa"
const documentDir = "vicEpa"

export default {
  name: "PoaEnduringPowerOfAttorney",
  mixins: [poa(storeName)],
  components: {
    DialogAttorneys,
    DialogMatters,
    DialogConditions,
    DialogCommencement,
    DialogSignatureAssistance,
  },

  data: () => ({
    editable: { address: true },
    required: vic_epa_required,
    documentDir: documentDir,
  }),
  methods: {
    joinWithAndOnLast: joinWithAndOnLast,
    scrollTop() {
      this.$refs.dialog.scrollTop2()
    },
  },
  computed: {
    attorneys() {
      return this[storeName].attorneys.map((o) => ({
        ...this.entities_people.find((e) => e.id === o.entity),
        ...o,
        alternatives: o.alternatives.map((a) => ({
          ...this.entities_people.find((e) => e.id === a),
          id: a,
        })),
      }))
    },
    attorneySuffix() {
      return this.attorneys.length === 1 ? "" : "s"
    },
    hasConditions() {
      return (
        this[storeName].conflictTransactions ||
        this[storeName].gifts ||
        this[storeName].maintenanceOfDependants ||
        this[storeName].paymentToAttorneys ||
        this[storeName].additionalConditions
      )
    },
    commencementSummary() {
      if (this[storeName].commencement === "immediately") {
        return "This Enduring Power of Attorney commences immediately."
      } else if (this[storeName].commencement === "incapacity") {
        return "This Enduring Power of Attorney commences when you cease to have capacity for the matters."
      }
      return null
    },
  },
}
</script>
