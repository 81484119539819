<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Matters</h2>
      <p>
        Please select the matters for which your attorneys can make decisions:
      </p>
      <item-select
        v-for="item in matterOptions.slice(0, -1)"
        class="mb-2"
        :key="item.key"
        :value="local.matters === item.key"
        :title="item.label"
        :text="item.text"
        @select="local.matters = item.key"
      />
      <item-select
        v-for="item in matterOptions.slice(-1)"
        class="mb-2"
        :key="item.key"
        :value="local.matters === item.key"
        :title="item.label"
        :text="item.text"
        @select="local.matters = item.key"
      >
        <div
          v-if="local.matters === 'specified'"
          class="mx-4"
        >
          <h4 class="mb-2 mt-4">
            Matters:
            <span
              class="definition-link"
              style="float: right"
              @click="openExample('specified')"
            >
              See example
            </span>
          </h4>

          <v-textarea
            :rules="[maxLengthRule]"
            v-bind="textAreaFieldGrow"
            v-model="local.specifiedMatters"
            :error-messages="
              showErrors.specifiedMatters && errorMessages.specifiedMatters
                ? errorMessages.specifiedMatters
                : null
            "
          />
        </div>
      </item-select>
      <v-input
        :error-messages="
          showErrors.matters && errorMessages.matters
            ? errorMessages.matters
            : null
        "
      />
      <br />
      <div class="text-lg font-weight-bold mb-4">
        Have you previously made an Enduring Power of Attorney that is currently
        valid?
      </div>
      <div class="toggle-yes-no">
        <item-select
          :value="local.hasPriorEpa === true"
          @select="selectHasPriorEpaOption(true)"
          title="Yes"
        />
        <item-select
          :value="local.hasPriorEpa === false"
          @select="selectHasPriorEpaOption(false)"
          title="No"
        />
        <v-input
          :error-messages="
            showErrors.hasPriorEpa && errorMessages.hasPriorEpa
              ? errorMessages.hasPriorEpa
              : null
          "
        />
      </div>

      <transition name="fade">
        <div v-if="local.hasPriorEpa">
          <h2 class="mb-4">Revocation</h2>
          <p>
            Under section 55 of the <b>Powers of Attorney Act 2014</b> any
            existing enduring power of attorney previously made by you will be
            revoked on making this enduring power of attorney, unless you
            specify otherwise.
          </p>
          <p>
            An <i>existing enduring power of attorney</i> is taken to include an
            enduring power of attorney made under the
            <b>Powers of Attorney Act 2014</b> or the
            <b>Instruments Act 1958</b> and an appointment of an enduring
            guardian made under the
            <b>Guardianship and Administration Act 1986</b>.
          </p>

          <div
            class="border-2 rounded pa-4 mb-4"
            style="border-color: #ddd !important"
          >
            <h3 class="mb-4">Optional</h3>
            <p>
              Is there an existing Enduring Power of Attorney or part of an
              existing Enduring Power of Attorney that you do not want to revoke
              when making this Enduring Power of Attorney?
            </p>

            <div class="toggle-yes-no">
              <item-select
                :value="showRevocationBox"
                @select="selectRevocationOption(true)"
                title="Yes"
              />
              <item-select
                :value="!showRevocationBox"
                @select="selectRevocationOption(false)"
                title="No"
              />
            </div>

            <div v-if="showRevocationBox">
              <p>
                <strong>
                  I specify that the following existing enduring power of
                  attorney or parts of an existing enduring power of attorney
                  made by me are not revoked by this enduring power of attorney
                  (specify date made, if known):
                </strong>
              </p>
              <p
                class="definition-link"
                @click="openExample('revocation')"
              >
                See example
              </p>
              <v-textarea
                :rules="[maxLengthRule]"
                v-bind="textAreaFieldGrow"
                v-model="local.revocation"
                :error-messages="
                  showErrors.revocation && errorMessages.revocation
                    ? errorMessages.revocation
                    : null
                "
              />
            </div>
          </div>
        </div>
      </transition>
      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="showExamples"
      title="Example"
      class="faq-drawer"
    >
      <div
        v-if="showSpecifiedExample"
        class="pa-4"
      >
        <div class="text-lg font-weight-bold mb-4">Specified matters</div>
        <p>
          For example, you may wish to specify that your attorney(s) can make
          decisions for the following particular matters only:
        </p>
        <ul>
          <li class="example-text">
            “The sale of my house at 1 Smith Street, Melbourne, Victoria 3000.”
          </li>
          <li class="example-text">
            “The nursing home in which I am to reside in the event that I
            require care.”
          </li>
        </ul>
      </div>
      <div
        v-if="showRevocationExample"
        class="pa-4"
      >
        <div class="text-lg font-weight-bold mb-4">Revocation</div>
        <p>
          For example, if you are now making an Enduring Power of Attorney
          ('EPA') for financial matters only, but have previously made an EPA
          for both personal and financial matters, you may wish to specify that
          you do not want to revoke the previous EPA to the extent that it
          relates to personal matters. Consequently, the new EPA would take
          effect in relation to financial matters only and the previous EPA
          would remain in effect in terms of personal matters only.
        </p>
        <p>Sample wording for the above example might be as follows:</p>
        <p class="example-text">
          The appointment of John Smith as my attorney for personal matters in
          the enduring power of attorney made by me on 1 January 2015.
        </p>
      </div>
    </drawer>
  </div>
</template>

<script>
import forms from "../../../mixins/forms.js"
import { isEmptyTrimmed, trimIfExists } from "../../../utils"
import { matterOptions } from "../../epaLists.js"

const storeName = "poa_vic_epa"
const documentId = "poaVicEpa"

export default {
  name: "ConditionsAndLimitations",
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      matters: false,
      specifiedMatters: false,
      revocation: false,
      hasPriorEpa: false,
    },
    matterOptions: matterOptions,
    showRevocationBox: false,
    exampleOpen: false,
    showExamples: false,
    showSpecifiedExample: false,
    showRevocationExample: false,
  }),
  mounted() {
    this.showRevocationBox = !!this.local.revocation
  },
  methods: {
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: {
          ...this.localFormatted,
          revocation: trimIfExists(this.localFormatted.revocation),
          specifiedMatters: trimIfExists(this.localFormatted.specifiedMatters),
        },
      })
    },
    selectHasPriorEpaOption(value) {
      if (!value) {
        this.local.revocation = null
        this.showRevocationBox = false
      }
      this.setAllShowErrors(false)
      this.$set(this.local, "hasPriorEpa", value)
    },
    selectRevocationOption(value) {
      this.setAllShowErrors(false)
      this.showRevocationBox = value
    },
    openExample(type) {
      this.showSpecifiedExample = false
      this.showRevocationExample = false
      this.showExamples = true
      this.showSpecifiedExample = type === "specified"
      this.showRevocationExample = type === "revocation"
    },
  },
  computed: {
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.local.matters === null)
        msgs.matters = "Please answer this question."
      if (
        this.localFormatted.matters === "specified" &&
        isEmptyTrimmed(this.localFormatted.specifiedMatters)
      )
        msgs.specifiedMatters = "Please specify the matters."
      if (this.localFormatted.hasPriorEpa === null)
        msgs.hasPriorEpa = "Please answer this question."
      if (
        this.showRevocationBox &&
        isEmptyTrimmed(this.localFormatted.revocation)
      )
        msgs.revocation = "Please fill out this section or select 'No'."
      return msgs
    },
    localFormatted() {
      return {
        matters: this.local.matters || null,
        specifiedMatters:
          this.local.matters === "specified"
            ? this.local.specifiedMatters
            : null,
        hasPriorEpa: this.local.hasPriorEpa,
        revocation:
          this.local.hasPriorEpa === true &&
          this.local.revocation &&
          this.showRevocationBox
            ? this.local.revocation
            : null,
      }
    },
  },
}
</script>
