<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Appointment of substitute attorneys</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            A substitute attorney is a person you can appoint to act as your
            attorney in the event that your originally appointed attorney is
            ever unavailable, unsuitable or unable to act as your attorney.
          </p>
          <p>
            If you appoint a substitute attorney, you must also specify the
            circumstances under which you want your substitute attorney to act,
            and the attorney(s) you want your substitute attorney to act in
            place of.
          </p>
          <p>Add up to 4 people as your substitute attorneys.</p>
        </div>
      </notification-box>

      <h3 class="mb-4">Your substitute attorneys:</h3>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <div
          v-if="!localFormatted.substituteAttorneys.length"
          class="text-no-result"
          key="empty"
        >
          - Add at least one attorney -
        </div>
        <div
          key="list"
          v-else
        >
          <transition-group
            name="component-fade"
            mode="out-in"
          >
            <item-person
              v-for="(item, index) in localFormatted.substituteAttorneys"
              :key="item.entity"
              class="mb-2"
              :id="item.entity"
              :editable="editable"
              type="person"
              @remove="removeAttorney(index)"
              ref="substituteAttorneysRef"
            >
              <template v-slot:details="{ entity }">
                <div
                  v-if="entity.type === 'person'"
                  class="text-sm text-cutoff opacity-70 py-1"
                >
                  <span
                    v-if="!entity.address"
                    class="font-italic"
                    >- Address Required -</span
                  >
                  <span v-else>
                    {{ entity.address }}
                  </span>
                </div>
              </template>
              <div class="mx-4 my-2">
                <h4 class="mb-2">In substitution of:</h4>
                <item-select
                  v-for="(attorney, inSubstitutionOfIndex) in attorneys"
                  :key="attorney.id + index + inSubstitutionOfIndex"
                  :value="item.inSubstitutionOf === attorney.id"
                  @select="selectInSubstitutionOf(index, attorney.id)"
                  :title="attorney.name"
                  :class="{
                    'mb-2': inSubstitutionOfIndex + 1 < attorneys.length,
                  }"
                />
                <v-input
                  class="mb-2"
                  :error-messages="
                    showErrors[`inSubstitutionOf${index}`] &&
                    errorMessages[`inSubstitutionOf${index}`]
                      ? errorMessages[`inSubstitutionOf${index}`]
                      : null
                  "
                />
                <h4 class="mb-2">
                  Under which circumstance do you want this substitute to act?
                </h4>
                <v-textarea
                  :rules="[maxLengthRule]"
                  v-bind="textAreaFieldGrow"
                  v-model="item.circumstances"
                  :error-messages="
                    showErrors[`circumstances${index}`] &&
                    errorMessages[`circumstances${index}`]
                      ? errorMessages[`circumstances${index}`]
                      : null
                  "
                />
              </div>
            </item-person>
          </transition-group>
        </div>
      </transition>

      <div class="text-label my-4">Add an attorney:</div>
      <div class="mb-2">
        <btn-add-large
          label="Add person"
          class="mb-2"
          @click="openDrawerPerson = true"
          @clickDisabled="disabledMessage()"
          :disabled="isMaximumSelected"
        />
      </div>
      <v-input
        :error-messages="
          showErrors.substituteAttorneys && errorMessages.substituteAttorneys
            ? errorMessages.substituteAttorneys
            : null
        "
      />

      <v-expand-transition>
        <div
          v-if="this.local.substituteAttorneys.length > 1"
          class="mb-4"
        >
          <h2 class="mb-4">
            How would you like to your substitute attorneys to work together?
          </h2>
          <item-select
            v-for="(item, index) in appointmentOptions"
            :key="item.key"
            :value="item.key === localFormatted.substituteAppointment"
            @select="selectAppointmentOption(item.key)"
            :title="item.label"
            :text="item.text"
            :class="{ 'mb-2': index + 1 < appointmentOptions.length }"
          />
          <v-input
            :error-messages="
              showErrors.substituteAppointment &&
              errorMessages.substituteAppointment
                ? errorMessages.substituteAppointment
                : null
            "
          />
        </div>
      </v-expand-transition>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add attorney"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addAttorney"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="['address']"
      />
    </drawer>
  </div>
</template>

<script>
import entitiesForms from "../../../mixins/entitiesForms.js"
import forms from "../../../mixins/forms.js"
import { trimIfExists } from "../../../utils"
import { appointmentOptions } from "../../epaLists"

const storeName = "poa_wa_epa"
const documentId = "poaWaEpa"

export default {
  name: "AppointAttorneys",
  mixins: [
    forms(storeName, documentId),
    entitiesForms("substituteAttorneys", "Maximum of 4 substitute attorneys."),
  ],
  data: () => ({
    showErrors: {
      attorneys: false,
      substituteAppointment: false,
      inSubstitutionOf0: false,
      inSubstitutionOf1: false,
      inSubstitutionOf2: false,
      inSubstitutionOf3: false,
      circumstances0: false,
      circumstances1: false,
      circumstances2: false,
      circumstances3: false,
    },
    editable: { address: true },
    appointmentOptions: appointmentOptions,
  }),
  methods: {
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: {
          ...this.localFormatted,
          substituteAttorneys: this.localFormatted.substituteAttorneys.map(
            (s) => ({
              entity: s.entity,
              inSubstitutionOf: s.inSubstitutionOf,
              circumstances: trimIfExists(s.circumstances),
            })
          ),
        },
      })
    },
    onSubmitFail() {
      for (
        let index = this.local.substituteAttorneys.length;
        index >= 0;
        index--
      ) {
        this.showErrorsSwitch(true, `inSubstitutionOf${index}`)
        this.showErrorsSwitch(true, `circumstances${index}`)
        if (
          this.errorMessages[`inSubstitutionOf${index}`] ||
          this.errorMessages[`circumstances${index}`]
        )
          this.$refs.substituteAttorneysRef[index].$el.scrollIntoView()
      }
    },
    addAttorney(id) {
      this.setAllShowErrors(false)
      this.local.substituteAttorneys.push({
        entity: id,
        inSubstitutionOf: null,
        circumstances: "",
      })
      this.openDrawerPerson = false
    },
    removeAttorney(index) {
      this.setAllShowErrors(false)
      this.$delete(this.local.substituteAttorneys, index)
    },
    selectInSubstitutionOf(index, id) {
      this.setAllShowErrors(false)
      this.local.substituteAttorneys[index].inSubstitutionOf = id
    },
    selectAppointmentOption(option) {
      this.setAllShowErrors(false)
      this.local.substituteAppointment = option
    },
  },
  computed: {
    attorneys() {
      return this.entities_people.filter((o) =>
        this[storeName].attorneys.includes(o.id)
      )
    },
    excludePeople() {
      return [
        ...this.childEntities,
        ...this.localFormatted.substituteAttorneys.map((item) => item.entity),
        ...this.local.attorneys,
      ]
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.localFormatted.substituteAttorneys.length > 4)
        msgs.substituteAttorneys = "Maximum of 4 attorneys."
      if (
        this.localFormatted.substituteAttorneys.length > 1 &&
        !this.localFormatted.substituteAppointment
      )
        msgs.substituteAppointment = "Required"
      this.localFormatted.substituteAttorneys.forEach((attorney, index) => {
        if (!attorney.inSubstitutionOf)
          msgs[`inSubstitutionOf${index}`] = "Required"
      })
      this.localFormatted.substituteAttorneys.forEach((attorney, index) => {
        if (!attorney.circumstances) msgs[`circumstances${index}`] = "Required"
      })
      return msgs
    },
    localFormatted() {
      let substituteAppointment = this.local?.substituteAppointment || null
      let substituteAppointmentOption = substituteAppointment
        ? this.appointmentOptions.find(
            (o) => o.key === substituteAppointment
          ) || null
        : null
      return {
        substituteAttorneys: this.local.substituteAttorneys || [],
        substituteAppointment:
          this.local.substituteAttorneys.length <= 1
            ? null
            : substituteAppointmentOption
            ? substituteAppointment
            : null,
      }
    },
  },
}
</script>
