<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">When financial attorneys powers begin</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            Please select when your attorneys powers begin for financial matters
          </p>
          <p><b>With regards to the first option:</b></p>
          <p>
            If a person is unsure whether you have capacity, they can seek a
            report from a medical practitioner or a declaration from the
            Queensland Civil and Administrative Tribunal (QCAT) or the Supreme
            Court.
          </p>
        </div>
      </notification-box>

      <div class="mb-4">
        <item-select
          :value="localFormatted.commencement === 'lack-capacity'"
          @select="selectCommencementOption('lack-capacity')"
          title="When I do not have capacity to make decisions for financial matters"
          class="mb-2"
        />
        <item-select
          :value="localFormatted.commencement === 'immediately'"
          @select="selectCommencementOption('immediately')"
          title="Immediately"
          class="mb-2"
        />
        <item-select
          :value="localFormatted.commencement === 'other'"
          @select="selectCommencementOption('other')"
          title="Other"
          text="You must specify a time, circumstance or occasion that will trigger the commencement of your attorneys powers for financial matters."
          class="mb-2"
        >
          <v-expand-transition>
            <div
              v-if="localFormatted.commencement === 'other'"
              class="mx-4"
            >
              <h3 class="my-4">Please specify when:</h3>
              <v-textarea
                :rules="[maxLengthRule]"
                v-bind="textAreaFieldGrow"
                v-model="local.commencementOther"
                :error-messages="
                  showErrors.commencementOther &&
                  errorMessages.commencementOther
                    ? errorMessages.commencementOther
                    : null
                "
              />
            </div>
          </v-expand-transition>
        </item-select>
        <v-input
          :error-messages="
            showErrors.commencement && errorMessages.commencement
              ? errorMessages.commencement
              : null
          "
        />
      </div>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from "../../../mixins/forms.js"
import { isEmptyTrimmed, trimIfExists } from "../../../utils"

const storeName = "poa_qld_epa"
const documentId = "poaQldEpa"

export default {
  name: "Commencement",
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      commencement: false,
      commencementOther: false,
    },
  }),
  methods: {
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: {
          ...this.localFormatted,
          commencementOther: trimIfExists(
            this.localFormatted.commencementOther
          ),
        },
      })
    },
    selectCommencementOption(key) {
      this.setAllShowErrors(false)
      this.local.commencement = key
    },
  },
  computed: {
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (!this.local.commencement)
        msgs.commencement = "Please select an option"
      if (
        this.localFormatted.commencement === "other" &&
        isEmptyTrimmed(this.local.commencementOther)
      )
        msgs.commencementOther =
          "Please specify the details of the commencement."
      return msgs
    },
    localFormatted() {
      return {
        commencement: this.local.commencement,
        commencementOther:
          this.local.commencement === "other"
            ? this.local.commencementOther
            : null,
      }
    },
  },
}
</script>
