// poa is mixed into a states EPA, EPG, EG, ACD etc.
import { mapGetters } from "vuex"

import FormPerson from "../../../components/form/Person"
import DownloadDoc from "../../../components/site/DownloadDoc"
import ChipRequired from "../../../components/ui/ChipRequired"
import DialogFull from "../../../components/wrapper/DialogFull"
import Drawer from "../../../components/wrapper/Drawer"
import SectionItem from "../../../components/wrapper/SectionItem"

export default function (storeName) {
  return {
    components: {
      Drawer,
      DownloadDoc,
      SectionItem,
      DialogFull,
      ChipRequired,
      FormPerson,
    },
    data: function () {
      return {
        openForm: false,
        editId: null,
        openEdit: false,
        gaUrl: "/",
        shake: false,
        required: [],
      }
    },
    methods: {
      close() {
        this.$emit("stepOneCompleted", this.isDataComplete)
        this.openForm = null
      },
      next() {
        this.$emit("stepOneCompleted", this.isDataComplete)
        this.openForm = null
      },
      open(key) {
        this.openForm = key
      },
      openEditPerson(id) {
        this.editId = id
        this.openEdit = true
      },
      shakeRequired() {
        this.shake = true
        setTimeout(() => {
          this.shake = false
        }, 1500)
      },
      allRequiredFieldsComplete(entity) {
        return this.required.every((field) => {
          return field === "dob"
            ? entity.dob &&
                entity.dob.day &&
                entity.dob.month &&
                entity.dob.year
            : entity[field] && entity[field].trim().length
        })
      },
    },
    computed: {
      ...mapGetters("account", [
        storeName,
        "entities_people",
        "user_name",
        "user_phone",
        "user_dob",
        "user_address",
        "user_occupation",
      ]),
      local() {
        return this[storeName]
      },
      dialogOpen: {
        get() {
          return Boolean(this.openForm)
        },
        set(val) {
          if (!val) {
            this.close()
          }
        },
      },
    },
  }
}
