<template>
  <div>
    <div v-if="local">
      <div class="mb-4">
        <h2 class="mb-4">What is your Date of Birth?</h2>
        <notification-box
          type="warning"
          class="mb-6"
          v-if="!editable"
          :flat="true"
        >
          <div>
            The date of birth can not be updated once the account has been paid
            for. If you have entered the wrong date please contact support for
            assistance.
          </div>
        </notification-box>
        <p>You must be over 18 years old to make a Will.</p>

        <div class="d-flex">
          <div
            class="pr-2"
            style="width: 15%; min-width: 90px"
          >
            <v-select
              label="Day"
              hide-details
              v-bind="textField"
              :items="[
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
              ]"
              v-model="local.dob.day"
              @input="onDateEntered()"
              :disabled="!editable"
            />
          </div>
          <div
            class="pr-2 flex-grow-1"
            style="max-width: 200px"
          >
            <v-select
              label="Month"
              hide-details
              v-bind="textField"
              :items="monthOptions()"
              v-model="local.dob.month"
              @input="onDateEntered()"
              :disabled="!editable"
            />
          </div>
          <div style="width: 20%; min-width: 100px">
            <v-select
              label="Year"
              hide-details
              v-bind="textField"
              :items="yearOptions()"
              v-model="local.dob.year"
              :error-messages="
                showErrors.year && errorMessages.year
                  ? errorMessages.year
                  : null
              "
              @input="onDateEntered()"
              :disabled="!editable"
            />
          </div>
        </div>
        <v-input
          :error-messages="
            showErrors.date && errorMessages.date ? errorMessages.date : null
          "
        />
      </div>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEqual, range } from "lodash"
import moment from "moment"
import { mapGetters } from "vuex"

import NotificationBox from "../../../../components/ui/NotificationBox"

export default {
  name: "WillFormAboutDob",
  components: { NotificationBox },
  props: {
    closeLabel: {
      default: "back",
    },
    submitLabel: {
      default: "save",
    },
    accessStatus: {
      default: null,
    },
  },
  data: () => ({
    local: null,
    showErrors: {
      day: false,
      month: false,
      year: false,
      date: false,
    },
    processing: false,
  }),
  mounted() {
    this.local = {
      dob: cloneDeep(this.user_dob.value),
    }
  },
  methods: {
    async submit() {
      if (!this.editable) this.next()

      this.processing = true
      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
        }
        setTimeout(() => {
          this.processing = false
          this.next()
        }, 500)
        return
      }
      this.showErrorsSwitch()
      this.processing = false
    },
    async save() {
      await this.$store.dispatch("account/save_user_data", this.localFormatted)
      if (this.$store.getters["account/user_profile_complete"]) {
        let sections = ["about"]
        if (this.$store.getters["account/user_partner"].isComplete) {
          sections.push("partner")
        }
        await this.$store.dispatch("account/update_will_progress", sections)
      }
    },
    close() {
      this.$emit("close")
    },
    next() {
      this.$emit("next")
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showErrors[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
    monthOptions() {
      return this.$store.getters["account/list_group"]("months").map((item) => {
        return {
          text: item.label,
          value: item.key,
        }
      })
    },
    yearOptions() {
      const d = new Date()
      const yr = d.getFullYear()

      return range(yr - 18, 1900, -1)
    },
    isValidDate(day, month, year) {
      const d = moment(`${day}-${month}-${year}`, "DD-MM-YYYY")

      return d.isValid()
    },
    getAge(day, month, year) {
      const today = new Date()
      const birthDate = new Date(year, month - 1, day)
      let age = today.getFullYear() - birthDate.getFullYear()
      const m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }
      return age
    },
    onDateEntered() {
      if (this.isComplete) {
        this.showErrorsSwitch()
      }
    },
  },
  computed: {
    ...mapGetters("account", ["user_dob"]),
    editable() {
      if (this.accessStatus === "unactivated" || !this.user_dob.isComplete) {
        return true
      }

      return false
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {
        day: null,
        month: null,
        year: null,
        date: null,
      }

      const dob = this.localFormatted.dob

      if (this.isComplete) {
        msgs.date = this.isValidDate(dob.day, dob.month, dob.year)
          ? msgs.date
          : "Invalid date"
        msgs.date =
          this.getAge(dob.day, dob.month, dob.year) >= 18
            ? msgs.date
            : "Must be over 18 years old"
      }

      if (!dob.day) {
        msgs.day = " "
        msgs.date = "Day required"
      }
      if (!dob.month) {
        msgs.month = " "
        msgs.date = "Month required"
      }
      if (!dob.year) {
        msgs.year = " "
        msgs.date = "Year Required"
      }

      return msgs
    },
    isComplete() {
      return (
        this.localFormatted.dob.day &&
        this.localFormatted.dob.month &&
        this.localFormatted.dob.year
      )
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(this.localFormatted, { dob: this.user_dob.value })
    },
    localFormatted() {
      return {
        dob: {
          day: Number(this.local.dob.day) || null,
          month: Number(this.local.dob.month) || null,
          year: Number(this.local.dob.year) || null,
        },
      }
    },
  },
}
</script>
