<template>
  <v-dialog
    v-model="isOpen"
    :max-width="500"
    persistent
  >
    <v-card>
      <v-list-item
        dense
        class="bg-sand"
      >
        <v-list-item-content>
          <h3 class="download-doc-title">
            Download Power Of Attorney document{{ multiple ? `s` : `` }}
          </h3>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click="$emit('close')"
            ><v-icon color="black">mdi-close</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
      <v-list-item class="py-4">
        <v-list-item-action>
          <img
            src="@/v2/assets/images/download.png"
            style="width: 60px; height: auto"
          />
        </v-list-item-action>
        <v-list-item-content>
          <div class="font-weight-bold mb-4">
            Your document{{ multiple ? `s are` : ` is` }} ready.
          </div>
          <div>
            Click the button below to download your document to your computer or
            device.
          </div>
          <slot />
        </v-list-item-content>
      </v-list-item>
      <div class="divider" />
      <v-btn
        text
        tile
        x-large
        block
        download
        @click="download"
        ><v-icon
          left
          color="primary"
          >mdi-download</v-icon
        >Download</v-btn
      >
      <div class="divider" />
      <v-btn
        text
        tile
        block
        class="bg-grey-10"
        @click="$emit('close')"
        >Close</v-btn
      >
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DownloadDoc",
  props: {
    token: {
      default: null,
    },
    multiple: {
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    isOpen: {
      get() {
        return Boolean(this.token)
      },
      set(val) {
        if (!val) {
          this.$emit("update:token", null)
        }
      },
    },
    downloadUrl() {
      return `${
        process.env.VUE_APP_DOCUMENT_DOWNLOAD_URL
      }?token=${encodeURIComponent(this.token)}`
    },
  },
  methods: {
    download() {
      const newTab = window.open(this.downloadUrl, "_new")
      newTab.blur()
      window.focus()

      this.$emit("download")
      this.isOpen = false
    },
  },
}
</script>

<style lang="scss">
.download-doc-title {
  font-family: CooperBT;
}
</style>
