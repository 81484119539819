<template>
  <v-card
    outlined
    class="btn-add-large"
    :class="{ disabled: disabled }"
  >
    <v-list-item
      @click="disabled ? $emit('clickDisabled') : $emit('click')"
      class="item"
    >
      <v-list-item-avatar
        color="primary"
        size="28"
      >
        <v-icon
          color="white"
          size="24"
          >{{ icon }}</v-icon
        >
      </v-list-item-avatar>
      <v-list-item-content class="py-2">
        <div class="text-lg font-weight-medium">{{ label }}</div>
        <div
          v-if="subtitle"
          class="subtitle"
        >
          {{ subtitle }}
        </div>
        <div v-if="text">{{ text }}</div>
      </v-list-item-content>
      <v-list-item-action
        v-if="img"
        tile
        class="my-0"
      >
        <img
          :src="img"
          height="48"
          width="auto"
        />
      </v-list-item-action>
    </v-list-item>
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  name: "BtnAddOther",
  props: {
    label: {
      default: "Add",
    },
    disabled: {
      default: false,
    },
    tooltip: {
      default: null,
    },
    subtitle: {
      default: null,
    },
    text: {
      default: null,
    },
    img: {
      default: null,
    },
    error: {
      default: null,
    },
    icon: {
      default: "mdi-plus",
    },
  },
  data: () => ({}),
}
</script>
