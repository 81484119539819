<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Choosing your guardians</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>Add up to 4 people as your guardians.</p>
        </div>
      </notification-box>

      <h3 class="mb-4">Your guardians:</h3>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <div
          v-if="!localFormatted.guardians.length"
          class="text-no-result"
          key="empty"
        >
          - Add at least one guardian -
        </div>
        <div
          key="list"
          v-else
        >
          <transition-group
            name="component-fade"
            mode="out-in"
          >
            <item-person
              v-for="(item, index) in localFormatted.guardians"
              :key="item + index"
              class="mb-2"
              :id="item"
              :editable="editable"
              type="person"
              @remove="removeGuardian(index)"
            >
              <template v-slot:details="{ entity }">
                <div
                  v-if="entity.type === 'person'"
                  class="text-sm text-cutoff opacity-70 pt-1"
                >
                  <span
                    v-if="!allRequiredFieldsComplete(entity)"
                    class="font-italic"
                    >- Information required -</span
                  >
                  <span v-else>
                    {{ entity.address }}
                  </span>
                </div>
              </template>
            </item-person>
          </transition-group>
        </div>
      </transition>

      <div class="text-label my-4">Add a guardian:</div>
      <div class="mb-2">
        <btn-add-large
          label="Add person"
          class="mb-2"
          @click="openDrawerPerson = true"
          @clickDisabled="disabledMessage()"
          :disabled="isMaximumSelected"
        />
      </div>
      <v-input
        :error-messages="
          showErrors.guardians && errorMessages.guardians
            ? errorMessages.guardians
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add guardian"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addGuardian"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="required"
      />
    </drawer>
  </div>
</template>

<script>
import { tas_eg_required } from "../../../../../../config/poaRequiredFields"
import entitiesForms from "../../../mixins/entitiesForms.js"
import forms from "../../../mixins/forms.js"

const storeName = "poa_tas_eg"
const documentId = "poaTasEg"

export default {
  name: "AppointGuardians",
  mixins: [
    forms(storeName, documentId),
    entitiesForms("guardians", "Maximum of 4 guardians."),
  ],
  data: () => ({
    showErrors: {
      guardians: false,
    },
    editable: { address: true, occupation: true, phone: true },
    required: tas_eg_required,
  }),
  methods: {
    addGuardian(id) {
      this.local.guardians.push(id)
      this.openDrawerPerson = false
    },
    removeGuardian(index) {
      this.$delete(this.local.guardians, index)
    },
  },
  computed: {
    excludePeople() {
      return [
        ...this.childEntities,
        ...this.localFormatted.guardians,
        this.local.substitute,
      ]
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (!this.localFormatted.guardians.length)
        msgs.guardians = "Select at least one guardian."
      if (this.localFormatted.guardians.length > 4)
        msgs.guardians = "Maximum of 4 guardians."
      return msgs
    },
    localFormatted() {
      return {
        guardians: this.local.guardians || [],
      }
    },
  },
}
</script>
