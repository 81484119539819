<template>
  <div>
    <div>
      <section-item
        @edit="open('appoint-attorneys')"
        class="mb-1"
      >
        <div class="text-label mb-2">Attorneys</div>
        <ul
          v-if="attorneys.length"
          class="mt-2"
        >
          <li
            v-for="item in attorneys"
            :key="item.id"
            class="mb-1"
          >
            {{ item.name }}
            <chip-required
              v-if="!item.address || !item.address.trim().length"
              text="Address required"
              @click="openEditPerson(item.id)"
              :class="{ shake: shake }"
            />
          </li>
        </ul>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('appoint-attorneys')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to add at least 1 attorney.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('substitute-attorneys')"
        :disabled="local.attorneys.length"
        @clickDisabled="showSubstituteDisabledMsg = true"
        class="mb-1"
      >
        <div class="text-label mb-2">
          Substitute attorneys <span class="optional">(optional)</span>
        </div>
        <ul
          v-if="substitutes.length"
          class="mt-2"
        >
          <li
            v-for="item in substitutes"
            :key="item.id"
            class="mb-1"
          >
            {{ item.entity.name }}
            {{
              item.inSubstitutionOfEntity &&
              local.substitutesStyle === "per-primary"
                ? `as substitute attorney for ${item.inSubstitutionOfEntity.name}`
                : ""
            }}
            <chip-required
              v-if="!item.entity.address || !item.entity.address.trim().length"
              text="Address required"
              @click="openEditPerson(item.entity.id)"
              :class="{ shake: shake }"
            />
          </li>
        </ul>
        <div v-else>
          <div class="text-no-result text-left">
            Click 'Edit' on the right to optionally add substitute attorneys.
          </div>
        </div>
      </section-item>
      <transition
        name="component-fade"
        mode="out-in"
      >
        <v-input
          class="-mt-2 mb-2"
          v-if="showSubstituteDisabledMsg && !local.attorneys.length"
          :error-messages="
            showSubstituteDisabledMsg && !local.attorneys.length
              ? `You must add at least 1 attorney before
        adding substitute attorneys.`
              : null
          "
        />
      </transition>

      <section-item
        @edit="open('powers')"
        class="mb-1"
      >
        <div class="text-label">
          Additional powers <span class="optional">(optional)</span>
        </div>
        <div
          v-if="local.authorisations?.reasonableGifts"
          class="mb-2"
        >
          <v-icon
            color="primary"
            size="16"
            class="mx-1"
            >$tick</v-icon
          >Reasonable gifts
        </div>
        <div
          v-if="local.authorisations?.benefitsAttorney"
          class="mb-2"
        >
          <v-icon
            color="primary"
            size="16"
            class="mx-1"
            >$tick</v-icon
          >Benefits to attorney{{ attorneySuffix }}
        </div>
        <div
          v-if="benefitsPeople.length"
          class="mb-2"
        >
          <v-icon
            color="primary"
            size="16"
            class="mx-1"
            >$tick</v-icon
          >Benefits to the following
          {{ benefitsPeople.length === 1 ? " person" : " people" }}:
          <ul class="mt-2 ml-6">
            <li
              v-for="item in benefitsPeople"
              :key="item.id"
              class="mb-1"
            >
              {{ item.name }}
              <chip-required
                v-if="!item.address || !item.address.trim().length"
                text="Address required"
                @click="openEditPerson(item.id)"
                :class="{ shake: shake }"
              />
            </li>
          </ul>
        </div>
        <div
          v-if="
            !benefitsPeople.length &&
            !local.authorisations.benefitsAttorney &&
            !local.authorisations.reasonableGifts
          "
          class="text-no-result text-left"
        >
          You have not selected any powers. Click 'Edit' on the right to add
          powers.
        </div>
      </section-item>

      <section-item
        @edit="open('conditions-and-limitations')"
        class="mb-1"
      >
        <div class="text-label">
          Conditions and limitations <span class="optional">(optional)</span>
        </div>
        <div
          v-if="!local.conditions"
          class="text-no-result text-left"
        >
          You have not added any conditions or limitations. Click 'Edit' on the
          right to add conditions and limitations.
        </div>
        <div
          v-else
          class="clamp-3"
        >
          {{ local.conditions }}
        </div>
      </section-item>

      <section-item
        @edit="open('commencement')"
        class="mb-1"
      >
        <div class="text-label">Commencement</div>
        <div
          v-if="local.commencement"
          :class="{ 'clamp-3': local.commencement === 'other' }"
        >
          {{ commencementSummary }}
        </div>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('commencement')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to specify when this Enduring Power of
            Attorney is to commence.
          </div>
        </div>
      </section-item>

      <dialog-full
        :open.sync="dialogOpen"
        title="Enduring Power of Attorney"
        :path="gaUrl"
      >
        <component
          v-if="openForm"
          :is="`dialog-${openForm}`"
          @close="close"
          @next="next"
        />
      </dialog-full>
    </div>
    <drawer
      title="Edit Person"
      :open.sync="openEdit"
    >
      <div class="pa-4">
        <slot name="form">
          <form-person
            v-if="openEdit"
            :id="editId"
            @close="openEdit = false"
            @next="openEdit = false"
            :editable="editable"
          />
        </slot>
      </div>
    </drawer>
  </div>
</template>

<script>
import { nsw_epa_required } from "../../../../config/poaRequiredFields"
import poa from "../mixins/poa.js"
import { commencementOptions } from "./epaLists"
import DialogAppointAttorneys from "./forms/epa/AppointAttorneys.vue"
import DialogCommencement from "./forms/epa/Commencement.vue"
import DialogConditionsAndLimitations from "./forms/epa/ConditionsAndLimitations.vue"
import DialogPowers from "./forms/epa/Powers.vue"
import DialogSubstituteAttorneys from "./forms/epa/SubstituteAttorneys.vue"

const storeName = "poa_nsw_epa"
const documentDir = "nswEpa"

export default {
  name: "PoaEnduringPowerOfAttorney",
  mixins: [poa(storeName)],
  components: {
    DialogAppointAttorneys,
    DialogSubstituteAttorneys,
    DialogPowers,
    DialogConditionsAndLimitations,
    DialogCommencement,
  },
  data: () => ({
    editable: { address: true },
    required: nsw_epa_required,
    commencementOptions: commencementOptions,
    documentDir: documentDir,
    showSubstituteDisabledMsg: false,
  }),
  methods: {},
  computed: {
    attorneys() {
      return this[storeName].attorneys.map((a) =>
        this.entities_people.find((o) => o.id === a)
      )
    },
    attorneySuffix() {
      return this.attorneys.length === 1 ? "" : "s"
    },
    substitutes() {
      return this.local.substitutesStyle === "per-primary"
        ? this.attorneys
            .flatMap((a) =>
              this[storeName].substitutes.filter(
                (s) => s.inSubstitutionOf === a.id
              )
            )
            .map((s) => ({
              ...s,
              entity: this.entities_people.find((o) => o.id === s.entity),
              inSubstitutionOfEntity: this.entities_people.find(
                (o) => o.id === s.inSubstitutionOf
              ),
            }))
        : this[storeName].substitutes.map((s) => ({
            ...s,
            entity: this.entities_people.find((o) => o.id === s.entity),
          }))
    },
    benefitsPeople() {
      return this.entities_people.filter((o) =>
        this[storeName].authorisations.benefitsPeople.includes(o.id)
      )
    },
    commencementSummary() {
      const option = this.commencementOptions.find(
        (o) => o.key === this.local.commencement
      )
      if (option.key === "other") return this.local.commencementOther
      return option.text
    },
  },
}
</script>
