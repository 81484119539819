const appointmentOptions = [
  {
    label: "Jointly",
    text: `Your attorneys must all act together.`,
    key: "jointly",
  },
  {
    label: "Jointly and severally",
    text: `Your attorneys may act individually or can act together with the other attorney(s) if they so choose.`,
    key: "jointly-severally",
  },
]

const substituteAppointmentOptions = [
  {
    label: "Jointly",
    text: `Your substitute attorneys must all act together.`,
    key: "jointly",
  },
  {
    label: "Jointly and severally",
    text: `Your substitute attorneys may act individually or can act together with the other attorney(s) if they so choose.`,
    key: "jointly-severally",
  },
]

const appointmentTerminationOptions = [
  {
    label: "Terminate appointment",
    text: `I want the appointment to be terminated if one of the attorneys dies, resigns or otherwise vacates office.`,
    key: "terminated",
  },
  {
    label: "Do not terminate appointment",
    text: `I do not want the appointment to be terminated if one of the attorneys dies, resigns or otherwise vacates office.`,
    key: "not-terminated",
  },
]

const substituteAppointmentTerminationOptions = [
  {
    label: "Terminate appointment",
    text: `I want the appointment to be terminated if one of the substitute attorneys dies, resigns or otherwise vacates office.`,
    key: "terminated",
  },
  {
    label: "Do not terminate appointment",
    text: `I do not want the appointment to be terminated if one of the substitute attorneys dies, resigns or otherwise vacates office.`,
    key: "not-terminated",
  },
]

const commencementOptions = [
  {
    label: "Immediately",
    text: `Immediately, subject to the attorney having accepted their appointment by signing the Enduring Power of Attorney document.`,
    key: "attorney-appointed",
  },
  {
    label: "Medical practitioner decides",
    text: `Once a medical practitioner considers that I am unable to manage my affairs (and provides a document to that effect).`,
    key: "medical-practitioner",
  },
  {
    label: "When I need assistance",
    text: `Once my attorney considers that I need assistance managing my affairs.`,
    key: "need-assistance",
  },
  {
    label: "Other",
    text: `Select this option to specify your own commencement date.`,
    key: "other",
  },
]

export {
  appointmentOptions,
  substituteAppointmentOptions,
  appointmentTerminationOptions,
  substituteAppointmentTerminationOptions,
  commencementOptions,
}
