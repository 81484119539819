<template>
  <v-window
    v-model="window"
    touchless
  >
    <span
      v-intersect="onIntersect"
      id="anchor"
    ></span>
    <v-window-item value="attorneys">
      <transition name="fade">
        <!-- v-if directive mounts component again, refreshing this.local -->
        <appoint-attorneys
          v-if="window === 'attorneys'"
          @back="$emit('close')"
          @next="$emit('top'), (window = 'alternatives')"
        />
      </transition>
    </v-window-item>
    <v-window-item value="alternatives">
      <transition name="fade">
        <!-- v-if directive mounts component again, refreshing this.local -->
        <appoint-alternatives
          v-if="window === 'alternatives'"
          @back="$emit('top'), changeWindow('attorneys')"
          @close="$emit('close')"
          @top="$emit('top')"
        />
      </transition>
    </v-window-item>
  </v-window>
</template>

<script>
import scroll from "../../../../../../pages/will/mixins/scroll"
import AppointAlternatives from "./AppointAlternatives.vue"
import AppointAttorneys from "./AppointAttorneys.vue"

export default {
  name: "Attorneys",
  mixins: [scroll],
  components: {
    AppointAlternatives,
    AppointAttorneys,
  },
  data: function () {
    return {
      window: "attorneys",
    }
  },
}
</script>
