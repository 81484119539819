<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Directions</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            You may give your Enduring Guardian{{ guardianSuffix }} directions
            about how you want them to carry out their functions.
          </p>
          <p>
            If you have directions for your enduring guardian{{
              guardianSuffix
            }}, please add them here.
          </p>
          <p>For example:</p>
          <ul>
            <li>
              If I need to move into residential care, I want to live in a
              facility located near my current home.
            </li>
            <li>
              I would prefer to continue seeing my current general practitioner,
              Dr X, for my general medical needs as she has been my doctor for
              many years.
            </li>
            <li>
              If possible, all of my children are to be consulted before any
              major decisions are made on my behalf.
            </li>
          </ul>
        </div>
      </notification-box>

      <h3 class="mb-4">
        Would you like to provide directions for your Enduring Guardian{{
          guardianSuffix
        }}.
      </h3>

      <div class="toggle-yes-no">
        <item-select
          :value="showTextbox"
          @select="setShowTextbox(true)"
          title="Yes"
        />
        <item-select
          :value="!showTextbox"
          @select="setShowTextbox(false)"
          title="No"
        />
      </div>

      <v-textarea
        :rules="[maxLengthRule]"
        v-if="showTextbox"
        v-bind="textAreaFieldGrow"
        v-model="local.directions"
        :error-messages="
          showErrors.directions && errorMessages.directions
            ? errorMessages.directions
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from "../../../mixins/forms.js"
import { isEmptyTrimmed, trimIfExists } from "../../../utils"

const storeName = "poa_wa_epg"
const documentId = "poaWaEpg"

export default {
  name: "Directions",
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      directions: false,
    },
    showTextbox: false,
  }),
  mounted() {
    this.showTextbox = !!this.local.directions
  },
  methods: {
    setShowTextbox(value) {
      this.setAllShowErrors(false)
      this.showTextbox = value
    },
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: {
          directions: trimIfExists(this.localFormatted.directions),
        },
      })
    },
  },
  computed: {
    guardianSuffix() {
      return this.local.guardians.length === 1 ? "" : "s"
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.showTextbox && isEmptyTrimmed(this.localFormatted.directions))
        msgs.directions = "Please enter your directions."
      return msgs
    },
    localFormatted() {
      return {
        directions: this.showTextbox ? this.local.directions : null,
      }
    },
  },
}
</script>
