const appointmentOptions = [
  {
    label: "Jointly",
    text: `Your attorneys must all act together.`,
    key: "jointly",
  },
  {
    label: "Jointly and severally",
    text: `Your attorneys may act individually or can act with the other attorneys if they choose.`,
    key: "jointly-severally",
  },
]

function getCommencementOptions(multiple) {
  return [
    {
      label: "Powers Continue Regardless of Legal Incapacity",
      text: `Your attorney${
        multiple ? "s" : ""
      } will have the authority to act on your behalf in financial matters even if you become legally incapacitated. This means that their authority will not be revoked if you are unable to make or communicate informed decisions for yourself due to illness, injury, or other conditions.`,
      short: `Your attorney${
        multiple ? "s" : ""
      } will have the authority to act on your behalf in financial matters even if you become legally incapacitated.`,
      key: `notwithstanding`,
    },
    {
      label: "Powers Only During Legal Incapacity",
      text: `Your attorney${
        multiple ? "s'" : "'s"
      } authority to act on your behalf in financial matters will be limited to periods when you are declared legally incapacitated by the State Administrative Tribunal. Their authority will cease when you regain your legal capacity.`,
      short: `Your attorney${
        multiple ? "s'" : "'s"
      } authority to act on your behalf in financial matters will be limited to periods when you are declared legally incapacitated by the State Administrative Tribunal.`,
      key: `incapacity`,
    },
  ]
}

export { appointmentOptions, getCommencementOptions }
