<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Appointment of attorneys</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            You can appoint anyone as your attorney, provided they are 18 years
            of age or older and have full legal capacity.
          </p>
          <p>
            Your attorney does not need to live in Western Australia, although
            their availability and ability to make property and financial
            decisions on your behalf from another State or country should be
            considered.
          </p>
          <p>Add up to 4 people as your attorneys.</p>
        </div>
      </notification-box>

      <h3 class="mb-4">Your attorneys:</h3>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <div
          v-if="!localFormatted.attorneys.length"
          class="text-no-result"
          key="empty"
        >
          - Add at least one attorney -
        </div>
        <div
          key="list"
          v-else
        >
          <transition-group
            name="component-fade"
            mode="out-in"
          >
            <item-person
              v-for="(item, index) in localFormatted.attorneys"
              :key="item + index"
              class="mb-2"
              :id="item"
              :editable="editable"
              type="person"
              @remove="removeAttorney(index)"
            >
              <template v-slot:details="{ entity }">
                <div
                  v-if="entity.type === 'person'"
                  class="text-sm text-cutoff opacity-70 py-1"
                >
                  <span
                    v-if="!entity.address"
                    class="font-italic"
                    >- Address Required -</span
                  >
                  <span v-else>
                    {{ entity.address }}
                  </span>
                </div>
              </template>
            </item-person>
          </transition-group>
        </div>
      </transition>

      <div class="text-label my-4">Add an attorney:</div>
      <div class="mb-2">
        <btn-add-large
          label="Add person"
          class="mb-2"
          @click="openDrawerPerson = true"
          @clickDisabled="disabledMessage()"
          :disabled="isMaximumSelected"
        />
      </div>
      <v-input
        :error-messages="
          showErrors.attorneys && errorMessages.attorneys
            ? errorMessages.attorneys
            : null
        "
      />

      <v-expand-transition>
        <div
          v-if="this.local.attorneys.length > 1"
          class="mb-4"
        >
          <h3 class="mb-4">
            How would you like to your attorneys to work together?
          </h3>
          <item-select
            v-for="(item, index) in appointmentOptions"
            :key="item.key"
            :value="item.key === localFormatted.attorneysAppointment"
            @select="selectAppointmentOption(item.key)"
            :title="item.label"
            :text="item.text"
            :class="{ 'mb-2': index + 1 < appointmentOptions.length }"
          />
          <v-input
            :error-messages="
              showErrors.attorneysAppointment &&
              errorMessages.attorneysAppointment
                ? errorMessages.attorneysAppointment
                : null
            "
          />
        </div>
      </v-expand-transition>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add attorney"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addAttorney"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="['address']"
      />
    </drawer>
  </div>
</template>

<script>
import { cloneDeep } from "lodash"

import entitiesForms from "../../../mixins/entitiesForms.js"
import forms from "../../../mixins/forms.js"
import { appointmentOptions } from "../../epaLists"

const storeName = "poa_wa_epa"
const documentId = "poaWaEpa"

export default {
  name: "AppointAttorneys",
  mixins: [
    forms(storeName, documentId),
    entitiesForms("attorneys", "Maximum of 4 attorneys."),
  ],
  data: () => ({
    showErrors: {
      attorneys: false,
      attorneysAppointment: false,
      originalSubstitutes: [],
    },
    editable: { address: true },
    appointmentOptions: appointmentOptions,
  }),
  mounted() {
    this.originalSubstitutes = cloneDeep(this[storeName].substituteAttorneys)
  },
  methods: {
    async save() {
      const removedAttorneys = this.getRemovedAttorneys()
      if (removedAttorneys.length) {
        let modifiedSubstitutes = this.originalSubstitutes
        modifiedSubstitutes.forEach((s) => {
          if (removedAttorneys.includes(s.inSubstitutionOf))
            s.inSubstitutionOf = null
        })
        modifiedSubstitutes = modifiedSubstitutes.filter(
          (s) => s.inSubstitutionOf !== null
        )
        this.$store.dispatch("account/save_poa", {
          id: documentId,
          data: {
            ...this.localFormatted,
            substituteAttorneys: modifiedSubstitutes,
          },
        })
      } else
        this.$store.dispatch("account/save_poa", {
          id: documentId,
          data: this.localFormatted,
        })
    },
    getRemovedAttorneys() {
      const removed = []
      this[storeName].attorneys.forEach((attorney) => {
        if (!this.localFormatted.attorneys.includes(attorney)) {
          removed.push(attorney)
        }
      })
      return removed
    },
    addAttorney(id) {
      this.local.attorneys.push(id)
      this.openDrawerPerson = false
    },
    removeAttorney(index) {
      this.$delete(this.local.attorneys, index)
    },
    selectAppointmentOption(option) {
      this.local.attorneysAppointment = option
    },
  },
  computed: {
    excludePeople() {
      return [
        ...this.childEntities,
        ...this.localFormatted.attorneys,
        ...this.local.substituteAttorneys.map((s) => s.entity),
      ]
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })

      if (!this.localFormatted.attorneys.length)
        msgs.attorneys = "Select at least one attorney."
      if (this.localFormatted.attorneys.length > 4)
        msgs.attorneys = "Maximum of 4 attorneys."
      if (
        this.localFormatted.attorneys.length > 1 &&
        !this.localFormatted.attorneysAppointment
      )
        msgs.attorneysAppointment = "Required"
      return msgs
    },
    localFormatted() {
      let attorneysAppointment = this.local?.attorneysAppointment || null
      let attorneysAppointmentOption = attorneysAppointment
        ? this.appointmentOptions.find((o) => o.key === attorneysAppointment) ||
          null
        : null
      return {
        attorneys: this.local.attorneys || [],
        attorneysAppointment:
          this.local.attorneys.length <= 1
            ? null
            : attorneysAppointmentOption
            ? attorneysAppointment
            : null,
      }
    },
  },
}
</script>
