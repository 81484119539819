<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Where to die/finish up</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            It is recommended that you discuss this section with your decision
            maker(s), family or doctor as it is important that anything you
            write should be readily understood by the people who are supporting
            and treating you.
          </p>
        </div>
      </notification-box>

      <h3 class="mb-4">Where would you like to die/finish up?</h3>

      <item-select
        :value="local.advanceCareStatement.locationOfDeath === 'home'"
        @select="setLocationOfDeath('home')"
        title="at home / on country"
        class="mb-2"
      >
        <v-expand-transition>
          <div
            v-if="local.advanceCareStatement.locationOfDeath === 'home'"
            class="mx-4"
          >
            <h3 class="my-4">Please list the location:</h3>
            <v-textarea
              :rules="[maxLengthRule]"
              v-bind="textAreaFieldGrow"
              v-model="local.advanceCareStatement.homeLocation"
              :error-messages="
                showErrors.homeLocation && errorMessages.homeLocation
                  ? errorMessages.homeLocation
                  : null
              "
            />
          </div>
        </v-expand-transition>
      </item-select>

      <item-select
        :value="local.advanceCareStatement.locationOfDeath === 'hospital'"
        @select="setLocationOfDeath('hospital')"
        title="in hospital or hospice"
        class="mb-2"
      />

      <item-select
        :value="local.advanceCareStatement.locationOfDeath === 'other'"
        @select="setLocationOfDeath('other')"
        title="other"
      >
        <v-expand-transition>
          <div
            v-if="local.advanceCareStatement.locationOfDeath === 'other'"
            class="mx-4"
          >
            <h3 class="my-4">Please list the location:</h3>
            <v-textarea
              :rules="[maxLengthRule]"
              v-bind="textAreaFieldGrow"
              v-model="local.advanceCareStatement.otherLocation"
              :error-messages="
                showErrors.otherLocation && errorMessages.otherLocation
                  ? errorMessages.otherLocation
                  : null
              "
            />
          </div>
        </v-expand-transition>
      </item-select>

      <div class="d-flex align-center mt-4">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from "../../../mixins/forms.js"
import { trimIfExists } from "../../../utils"

const storeName = "poa_nt_app"
const documentId = "poaNtApp"

export default {
  name: "WhereToDie",
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      lifeMeaning: false,
      homeLocation: false,
      otherLocation: false,
    },
  }),
  methods: {
    setLocationOfDeath(location) {
      this.setAllShowErrors(false)
      this.local.advanceCareStatement.locationOfDeath = location
    },
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: {
          advanceCareStatement: {
            ...this.localFormatted.advanceCareStatement,
            homeLocation: trimIfExists(
              this.localFormatted.advanceCareStatement.homeLocation
            ),
            otherLocation: trimIfExists(
              this.localFormatted.advanceCareStatement.otherLocation
            ),
          },
        },
      })
    },
  },
  computed: {
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (
        this.local.advanceCareStatement.locationOfDeath === "home" &&
        !this.local.advanceCareStatement.homeLocation
      )
        msgs.homeLocation = "Please enter a location"
      if (
        this.local.advanceCareStatement.locationOfDeath === "other" &&
        !this.local.advanceCareStatement.otherLocation
      )
        msgs.otherLocation = "Please enter a location"
      return msgs
    },
    localFormatted() {
      return {
        advanceCareStatement: {
          ...this.local.advanceCareStatement,
          locationOfDeath:
            this.local && this.local.advanceCareStatement.locationOfDeath
              ? this.local.advanceCareStatement.locationOfDeath
              : null,
          homeLocation:
            this.local &&
            this.local.advanceCareStatement.locationOfDeath === "home"
              ? this.local.advanceCareStatement.homeLocation
              : null,
          otherLocation:
            this.local &&
            this.local.advanceCareStatement.locationOfDeath === "other"
              ? this.local.advanceCareStatement.otherLocation
              : null,
        },
      }
    },
  },
}
</script>
