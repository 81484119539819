<template>
  <div>
    <div>
      <section-item
        @edit="open('appoint-attorneys')"
        class="mb-1"
      >
        <div class="text-label mb-2">Attorneys</div>
        <ul
          v-if="attorneys.length"
          class="mt-2"
        >
          <li
            v-for="item in attorneys"
            :key="item.id"
            class="mb-1"
          >
            {{ item.name }}
            <chip-required
              v-if="!item.address || !item.address.trim().length"
              text="Address required"
              @click="openEditPerson(item.id)"
              :class="{ shake: shake }"
            />
          </li>
        </ul>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('appoint-attorneys')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to add at least 1 attorney.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('substitute-attorneys')"
        :disabled="local.attorneys.length"
        @clickDisabled="showSubstituteDisabledMsg = true"
        class="mb-1"
      >
        <div class="text-label mb-2">Substitute attorneys</div>
        <ul
          v-if="substitutes.length"
          class="mt-2"
        >
          <li
            v-for="item in substitutes"
            :key="item.id"
            class="mb-1"
          >
            {{ item.name }}
            (in substitution of <i>{{ item.inSubstitutionOf.name }}</i
            >)
            <chip-required
              v-if="!item.address || !item.address.trim().length"
              text="Address required"
              @click="openEditPerson(item.id)"
              :class="{ shake: shake }"
            />
          </li>
        </ul>
        <div v-else>
          <div class="text-no-result text-left">
            Click 'Edit' on the right to optionally add substitute attorneys.
          </div>
        </div>
      </section-item>
      <transition
        name="component-fade"
        mode="out-in"
      >
        <v-input
          class="-mt-2 mb-2"
          v-if="showSubstituteDisabledMsg && !local.attorneys.length"
          :error-messages="
            showSubstituteDisabledMsg && !local.attorneys.length
              ? `You must add at least 1 attorney before
        adding substitute attorneys.`
              : null
          "
        />
      </transition>

      <section-item
        @edit="open('conditions')"
        class="mb-1"
      >
        <div class="text-label">Conditions and restrictions</div>
        <div
          v-if="!local.conditions"
          class="text-no-result text-left"
        >
          You have not added any conditions or restrictions. Click 'Edit' on the
          right to add conditions and restrictions.
        </div>
        <div
          v-else
          class="clamp-3"
        >
          {{ local.conditions }}
        </div>
      </section-item>

      <section-item
        @edit="open('commencement')"
        class="mb-1"
      >
        <div class="text-label">Commencement</div>
        <div v-if="local.commencement">
          {{
            commencementOptions.find((item) => item.key === local.commencement)
              ?.short
          }}
        </div>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('commencement')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to specify when this Enduring Power of
            Attorney is to commence.
          </div>
        </div>
      </section-item>

      <dialog-full
        :open.sync="dialogOpen"
        title="Enduring Power of Attorney"
        :path="gaUrl"
      >
        <component
          v-if="openForm"
          :is="`dialog-${openForm}`"
          @close="close"
          @next="next"
        />
      </dialog-full>
    </div>
    <drawer
      title="Edit Person"
      :open.sync="openEdit"
    >
      <div class="pa-4">
        <slot name="form">
          <form-person
            v-if="openEdit"
            :id="editId"
            @close="openEdit = false"
            @next="openEdit = false"
            :editable="editable"
          />
        </slot>
      </div>
    </drawer>
  </div>
</template>

<script>
import { wa_epa_required } from "../../../../config/poaRequiredFields"
import poa from "../mixins/poa.js"
import { getCommencementOptions } from "./epaLists"
import DialogAppointAttorneys from "./forms/epa/AppointAttorneys.vue"
import DialogCommencement from "./forms/epa/Commencement.vue"
import DialogConditions from "./forms/epa/Conditions.vue"
import DialogSubstituteAttorneys from "./forms/epa/SubstituteAttorneys.vue"

const storeName = "poa_wa_epa"
const documentDir = "waEpa"

export default {
  name: "PoaEnduringPowerOfAttorney",
  mixins: [poa(storeName)],
  components: {
    DialogAppointAttorneys,
    DialogSubstituteAttorneys,
    DialogConditions,
    DialogCommencement,
  },

  data: () => ({
    editable: { address: true },
    required: wa_epa_required,
    commencementOptions: getCommencementOptions(false),
    documentDir: documentDir,
    showSubstituteDisabledMsg: false,
  }),
  mounted() {
    this.commencementOptions = getCommencementOptions(
      this.local.attorneys.length > 1
    )
  },
  methods: {},
  computed: {
    attorneys() {
      return this.entities_people.filter((o) =>
        this[storeName].attorneys.includes(o.id)
      )
    },
    attorneySuffix() {
      return this.attorneys.length === 1 ? "" : "s"
    },
    substitutes() {
      return this[storeName].substituteAttorneys.map((o) => ({
        ...this.entities_people.find((e) => e.id === o.entity),
        inSubstitutionOf: this.entities_people.find(
          (e) => e.id === o.inSubstitutionOf
        ),
      }))
    },
  },
}
</script>
