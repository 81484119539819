<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Commencement</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            You may choose when this power of attorney is to commence operation.
            Select the option that corresponds to when you want this power of
            attorney to operate.
          </p>
        </div>
      </notification-box>

      <div class="mb-4">
        <item-select
          v-for="item in commencementOptions"
          :key="item.key"
          :value="item.key === localFormatted.commencement"
          @select="selectCommencementOption(item.key)"
          :title="item.label"
          :text="item.text"
          class="mb-2"
        />
        <v-input
          :error-messages="
            showErrors.commencement && errorMessages.commencement
              ? errorMessages.commencement
              : null
          "
        />
      </div>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from "../../../mixins/forms.js"
import { getCommencementOptions } from "../../epaLists"

const storeName = "poa_wa_epa"
const documentId = "poaWaEpa"

export default {
  name: "Commencement",
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      commencement: false,
    },
    commencementOptions: getCommencementOptions(false),
  }),
  mounted() {
    this.commencementOptions = getCommencementOptions(
      this.local.attorneys.length > 1
    )
  },
  methods: {
    selectCommencementOption(key) {
      this.local.commencement = key
    },
  },
  computed: {
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (!this.local.commencement)
        msgs.commencement = "Please select an option"
      return msgs
    },
    localFormatted() {
      return {
        commencement: this.local.commencement,
      }
    },
  },
}
</script>
