<template>
  <div>
    <div v-if="local">
      <div class="mb-4">
        <h2 class="mb-4">What is your current address?</h2>
        <p>
          Your current residential address. You must live in Australia to make a
          Will with Willed.
        </p>
        <address-picker
          v-model="local.address"
          v-bind="textField"
          :inline-label="true"
          :only-au="true"
          input-type="object"
          label="Address"
          @input="showErrorsSwitch(true, 'street')"
          @blur="showErrorsSwitch(true, 'street')"
          :error-messages="
            showErrors.street && errorMessages.street
              ? errorMessages.street
              : null
          "
        />
        <div class="d-flex">
          <div class="pr-4 flex-grow-1">
            <v-text-field
              v-bind="textField"
              label="Suburb"
              v-model="local.address.suburb"
              :error-messages="
                showErrors.suburb && errorMessages.suburb
                  ? errorMessages.suburb
                  : null
              "
              @input="showErrorsSwitch(true, 'suburb')"
              @blur="showErrorsSwitch(true, 'suburb')"
            />
          </div>
          <div
            class=""
            style="max-width: 30%"
          >
            <v-text-field
              v-bind="textField"
              label="Postcode"
              v-model="local.address.postcode"
              :error-messages="
                showErrors.postcode && errorMessages.postcode
                  ? errorMessages.postcode
                  : null
              "
              @input="showErrorsSwitch(true, 'postcode')"
              @blur="showErrorsSwitch(true, 'postcode')"
            />
          </div>
        </div>
        <v-select
          v-bind="textField"
          label="State"
          :items="auStates"
          return-object
          v-model="local.address.state"
          :error-messages="
            showErrors.state && errorMessages.state ? errorMessages.state : null
          "
        />
        <v-select
          v-show="false"
          v-bind="textField"
          label="Country"
          disabled
          :items="countries"
          v-model="local.address.country"
          :error-messages="
            showErrors.country && errorMessages.country
              ? errorMessages.country
              : null
          "
        />
      </div>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>

    <confirm-change-state
      :showConfirmChangeState="showConfirmChangeState"
      :currentStateLabel="currentStateLabel"
      @close="showConfirmChangeState = false"
      @confirmChangeState="confirmChangeState"
    />
  </div>
</template>

<script>
// Shares alot of code with pages/will/forms/AboutAddress.vue
// If making changes, consider making them in both places
import { cloneDeep, isEqual } from "lodash"
import { mapGetters } from "vuex"

import AddressPicker from "../../../../components/ui/AddressPicker"
import ConfirmChangeState from "./ConfirmChangeState.vue"
export default {
  name: "PoaFormAboutAddress",
  components: { AddressPicker, ConfirmChangeState },
  props: {
    closeLabel: {
      default: "back",
    },
    submitLabel: {
      default: "save",
    },
  },
  data: () => ({
    local: null,
    showErrors: {
      street: false,
      suburb: false,
      postcode: false,
      state: false,
      country: false,
    },
    processing: false,
    countries: ["Australia"],
    showConfirmChangeState: false,
    stateChangeConfirmed: false,
    currentStateLabel: "",
  }),
  mounted() {
    this.local = {
      address: {
        ...cloneDeep(this.user_address.value),
        state: this.auStates.find(
          (x) => x.value === this.user_address.value.state
        ),
        country: "Australia",
      },
    }
    this.currentStateLabel = this.getStateLabel(this.local.address.state.value)
  },
  methods: {
    async submit() {
      if (
        this.user_access.hasPurchasedPoa &&
        !this.stateChangeConfirmed &&
        this.user_address.value.state &&
        this.user_address.value.state !== this.local.address.state.value &&
        this.existingDocs.length > 0
      ) {
        this.showConfirmChangeState = true
        return
      }
      this.processing = true
      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
        }
        setTimeout(() => {
          this.processing = false
          this.next()
        }, 500)
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    async save() {
      await this.$store.dispatch("account/save_user_data", this.localFormatted)
      if (this.$store.getters["account/user_profile_complete"]) {
        let sections = ["about"]
        if (this.$store.getters["account/user_partner"].isComplete) {
          sections.push("partner")
        }
        await this.$store.dispatch("account/update_will_progress", sections)
      }
    },
    getStateLabel(state) {
      const label = this.auStates.find((x) => x.value === state).text
      const prefix = state === "nt" || state === "act" ? "the " : ""
      return `${prefix}${label}`
    },

    confirmChangeState() {
      this.stateChangeConfirmed = true
      this.showConfirmChangeState = false
      this.$store.dispatch("account/delete_state_poas", {
        state: this.user_address.value.state,
      })
      this.submit()
    },
    close() {
      this.$emit("close")
    },
    next() {
      this.$emit("next")
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showErrors[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
  },
  computed: {
    ...mapGetters("account", ["user_address", "user_access", "existingDocs"]),
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {
        street: null,
        suburb: null,
        postcode: null,
        state: null,
        country: null,
      }

      if (!this.localFormatted.address.street) {
        msgs.street = "Required field"
      }
      if (!this.localFormatted.address.suburb) {
        msgs.suburb = "Required field"
      }

      if (!this.localFormatted.address.postcode) {
        msgs.postcode = "Required field"
      }

      if (
        !msgs.postcode &&
        !/^[0-9]*$/.test(this.localFormatted.address.postcode)
      ) {
        msgs.postcode = "Should only contain numbers"
      }

      if (
        !msgs.postcode &&
        ![4].includes(this.localFormatted.address.postcode?.length)
      ) {
        msgs.postcode = "Must be 4 digits"
      }

      if (!this.localFormatted.address.state) {
        msgs.state = "Required field"
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(this.localFormatted, this.user_address.value)
    },
    localFormatted() {
      return {
        address: {
          street: this.local.address.street?.trim(),
          suburb: this.local.address.suburb?.trim(),
          postcode: this.local.address.postcode?.trim(),
          state: this.local.address.state?.value,
          country: "Australia",
        },
      }
    },
    auStates() {
      return this.$store.getters["account/list_group"]("auStates").map((x) => ({
        text: x.label,
        value: x.key,
      }))
    },
  },
}
</script>
