<template>
  <v-card
    outlined
    class="item-select"
  >
    <v-list-item
      link
      @click="$emit('select')"
    >
      <v-list-item-content>
        <div
          v-if="value"
          class="text-base font-weight-medium"
        >
          {{ value }}
        </div>
      </v-list-item-content>
      <v-btn
        icon
        color="black"
        height="32"
        class="bg-grey-30 radius-4"
        @click="$emit('delete')"
        ><v-icon>mdi-trash-can-outline</v-icon></v-btn
      >
    </v-list-item>
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  name: "OtherFunction",
  props: {
    value: {
      default: null,
    },
  },
}
</script>
