<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Substitute attorneys</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            If your attorney vacates office, you have the option to nominate
            someone else to take their place.
          </p>
          <p>Add up to 4 people as your substitute attorneys.</p>
        </div>
      </notification-box>

      <h3 class="mb-4">Your substitute attorneys:</h3>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <div
          v-if="!localFormatted.substitutes.length"
          class="text-no-result"
          key="empty"
        >
          - You have not added any substitute attorneys -
        </div>
        <div
          key="list"
          v-else
        >
          <transition-group
            name="component-fade"
            mode="out-in"
          >
            <item-person
              v-for="(item, index) in localFormatted.substitutes"
              :key="item + index"
              class="mb-2"
              :id="item"
              :editable="editable"
              type="person"
              @remove="removeSubstituteAttorney(index)"
            >
              <template v-slot:details="{ entity }">
                <div
                  v-if="entity.type === 'person'"
                  class="text-sm text-cutoff opacity-70 py-1"
                >
                  <span
                    v-if="!entity.address"
                    class="font-italic"
                    >- Address Required -</span
                  >
                  <span v-else>
                    {{ entity.address }}
                  </span>
                </div>
              </template>
            </item-person>
          </transition-group>
        </div>
      </transition>

      <div class="text-label my-4">Add a substitute attorney:</div>
      <div class="mb-2">
        <btn-add-large
          label="Add person"
          class="mb-2"
          @click="openDrawerPerson = true"
          @clickDisabled="disabledMessage()"
          :disabled="isMaximumSelected"
        />
      </div>
      <v-input
        :error-messages="
          showErrors.substitutes && errorMessages.substitutes
            ? errorMessages.substitutes
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add attorney"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addSubstituteAttorney"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="['address']"
      />
    </drawer>
  </div>
</template>

<script>
import entitiesForms from "../../../mixins/entitiesForms.js"
import forms from "../../../mixins/forms.js"

const storeName = "poa_act_epa"
const documentId = "poaActEpa"

export default {
  name: "SubstituteAttorneys",
  mixins: [
    forms(storeName, documentId),
    entitiesForms("substitutes", "Maximum of 4 substitute attorneys."),
  ],
  data: () => ({
    showErrors: {
      substitutes: false,
    },
    editable: { address: true },
  }),
  methods: {
    addSubstituteAttorney(id) {
      this.local.substitutes.push(id)
      this.openDrawerPerson = false
    },
    removeSubstituteAttorney(index) {
      this.$delete(this.local.substitutes, index)
    },
  },
  computed: {
    excludePeople() {
      return [
        ...this.childEntities,
        ...this.localFormatted.substitutes,
        ...this.local.attorneys,
      ]
    },
    localFormatted() {
      return {
        substitutes: this.local.substitutes || [],
      }
    },
  },
}
</script>
