<template>
  <div>
    <div>
      <section-item
        @edit="open('appoint-guardians')"
        class="mb-1"
      >
        <div class="text-label mb-2">Enduring Guardians</div>
        <ul
          v-if="guardians.length"
          class="mt-2"
        >
          <li
            v-for="item in guardians"
            :key="item.id"
            class="mb-1"
          >
            {{ item.name }}
            <chip-required
              v-if="!allRequiredFieldsComplete(item)"
              text="Information required"
              @click="openEditPerson(item.id)"
              :class="{ shake: shake }"
            />
          </li>
        </ul>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('appoint-guardians')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to add at least 1 guardian
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('substitute-guardian')"
        class="mb-1"
      >
        <div class="text-label mb-2">
          Substitute Enduring Guardian(s)
          <span class="optional">(optional)</span>
        </div>
        <div
          v-if="!substituteFull"
          class="text-no-result text-left"
        >
          Click 'Edit' on the right to optionally add a substitute Enduring
          Guardian.
        </div>
        <div v-else>
          {{ substituteFull.name }}
          <chip-required
            v-if="!allRequiredFieldsComplete(substituteFull)"
            text="Information required"
            @click="openEditPerson(local.substitute)"
            :class="{ shake: shake }"
          />
        </div>
      </section-item>

      <section-item
        @edit="open('functions-and-limitations')"
        class="mb-1"
      >
        <div class="text-label">Functions and limits</div>
        <div v-if="local.functions.livingSituation">
          <v-icon
            color="primary"
            size="16"
            class="mx-1"
            >$tick</v-icon
          >Living situation
        </div>
        <div v-if="local.functions.healthCare">
          <v-icon
            color="primary"
            size="16"
            class="mx-1"
            >$tick</v-icon
          >Health care
        </div>
        <div v-if="local.functions.personalServices">
          <v-icon
            color="primary"
            size="16"
            class="mx-1"
            >$tick</v-icon
          >Personal services
        </div>
        <div v-if="local.functions.medicalDental">
          <v-icon
            color="primary"
            size="16"
            class="mx-1"
            >$tick</v-icon
          >Medical and Dental
        </div>
        <div v-if="local.functions.other">
          <v-icon
            color="primary"
            size="16"
            class="mx-1"
            >$tick</v-icon
          >Other
        </div>
        <div v-if="!functionsComplete">
          <chip-required
            @click="open('functions-and-limitations')"
            :class="{ shake: shake }"
          />
          <div class="text-no-result text-left">
            You have not selected any functions. Click 'Edit' on the right to
            add functions.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('directions')"
        class="mb-1"
      >
        <div class="text-label">
          Directions <span class="optional">(optional)</span>
        </div>
        <div
          v-if="!local.directions"
          class="text-no-result text-left"
        >
          You have not added any directions. Click 'Edit' on the right to add
          directions.
        </div>
        <div
          v-else
          class="clamp-3"
        >
          {{ local.directions }}
        </div>
      </section-item>

      <dialog-full
        :open.sync="dialogOpen"
        title="Enduring Guardianship"
        :path="gaUrl"
      >
        <component
          v-if="openForm"
          :is="`dialog-${openForm}`"
          @close="close"
          @next="next"
        />
      </dialog-full>
    </div>
    <drawer
      title="Edit Person"
      :open.sync="openEdit"
    >
      <div class="pa-4">
        <slot name="form">
          <form-person
            v-if="openEdit"
            :id="editId"
            @close="openEdit = false"
            @next="openEdit = false"
            :editable="editable"
            :required="required"
          />
        </slot>
      </div>
    </drawer>
  </div>
</template>

<script>
import { nsw_eg_required } from "../../../../config/poaRequiredFields"
import poa from "../mixins/poa.js"
import DialogAppointGuardians from "./forms/eg/AppointGuardians.vue"
import DialogDirections from "./forms/eg/Directions.vue"
import DialogFunctionsAndLimitations from "./forms/eg/FunctionsAndLimitations.vue"
import DialogSubstituteGuardian from "./forms/eg/SubstituteGuardian.vue"

const storeName = "poa_nsw_eg"
const documentDir = "nswEg"

export default {
  name: "PoaEnduringPowerOfGuardian",
  mixins: [poa(storeName)],
  components: {
    DialogAppointGuardians,
    DialogSubstituteGuardian,
    DialogDirections,
    DialogFunctionsAndLimitations,
  },
  methods: {},
  data: () => ({
    editable: { address: true, occupation: true, phone: true },
    required: nsw_eg_required,
    documentDir: documentDir,
  }),
  computed: {
    guardians() {
      return this.entities_people.filter((o) =>
        this[storeName].guardians.includes(o.id)
      )
    },
    guardianSuffix() {
      return this.guardians.length === 1 ? "" : "s"
    },
    substituteFull() {
      return this.entities_people.find((p) => p.id === this.local.substitute)
    },
    functionsComplete() {
      return (
        this.local.functions.livingSituation ||
        this.local.functions.healthCare ||
        this.local.functions.personalServices ||
        this.local.functions.medicalDental ||
        this.local.functions.other
      )
    },
  },
}
</script>
