<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Matters</h2>

      <h3 class="mb-4">
        What decisions can your attorney{{ attorneySuffix }} make?
      </h3>

      <div class="mb-4">
        <item-select
          :value="localFormatted.personalMatters"
          @select="toggleMatters('personalMatters')"
          title="Personal (including health) matters"
          text="Personal matters relate to personal or lifestyle decisions. This includes decisions about support services, where and with whom you live, health care and legal matters that do not relate to your financial or property matters."
          class="mb-2"
        />
        <item-select
          :value="localFormatted.financialMatters"
          @select="toggleMatters('financialMatters')"
          title="Financial matters"
          text="Financial matters relate to your financial or property affairs including paying expenses, making investments, selling property or carrying on a business."
          class="mb-2"
        />
        <v-input
          :error-messages="
            showErrors.matters && errorMessages.matters
              ? errorMessages.matters
              : null
          "
        />
      </div>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from "../../../mixins/forms.js"

const storeName = "poa_qld_epa"
const documentId = "poaQldEpa"

export default {
  name: "Commencement",
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      matters: false,
    },
  }),
  methods: {
    toggleMatters(key) {
      this.setAllShowErrors(false)
      this.local[key] = !this.local[key]
    },
  },
  computed: {
    attorneySuffix() {
      return this.local.attorneys.length > 1 ? "s" : ""
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (!this.local.personalMatters && !this.local.financialMatters)
        msgs.matters = "Please select at least one option."
      return msgs
    },
    localFormatted() {
      return {
        financialMatters: this.local.financialMatters,
        personalMatters: this.local.personalMatters,
      }
    },
  },
}
</script>
