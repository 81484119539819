<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Refusal or withdrawal of medical treatment</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>Please select the option that applies.</p>
        </div>
      </notification-box>

      <div class="mb-4">
        <item-select
          v-for="(item, index) in refusalOptions.slice(0, -1)"
          :key="item.key"
          :value="item.key === localFormatted.refuseMedicalTreatment"
          @select="selectOption(item.key)"
          :title="item.label"
          :text="item.text"
          :class="{ 'mb-2': index + 1 < refusalOptions.length }"
        />
        <item-select
          :key="otherOption.key"
          :value="otherOption.key === localFormatted.refuseMedicalTreatment"
          @select="selectOption(otherOption.key)"
          :title="otherOption.label"
          :text="otherOption.text"
        >
          <v-expand-transition>
            <div
              v-if="otherOption.key === localFormatted.refuseMedicalTreatment"
              class="mx-4"
            >
              <h3 class="my-4">Please specify the medical treatments:</h3>
              <v-textarea
                :rules="[maxLengthRule]"
                v-bind="textAreaFieldGrow"
                v-model="local.refuseMedicalTreatmentSome"
                :error-messages="
                  showErrors.refuseMedicalTreatmentSome &&
                  errorMessages.refuseMedicalTreatmentSome
                    ? errorMessages.refuseMedicalTreatmentSome
                    : null
                "
              />
            </div>
          </v-expand-transition>
        </item-select>
        <v-input
          :error-messages="
            showErrors.refuseMedicalTreatment &&
            errorMessages.refuseMedicalTreatment
              ? errorMessages.refuseMedicalTreatment
              : null
          "
        />
      </div>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from "../../../mixins/forms.js"
import { isEmptyTrimmed, trimIfExists } from "../../../utils"
import { getRefusalOptions } from "../../lists"

const storeName = "poa_act_epa"
const documentId = "poaActEpa"

export default {
  name: "RefusalsOrWithdrawals",
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      refuseMedicalTreatment: false,
      refuseMedicalTreatmentSome: false,
    },
    refusalOptions: null,
    otherOption: null,
  }),
  mounted() {
    this.refusalOptions = getRefusalOptions(this.local.attorneys.length > 1)
    this.otherOption = this.refusalOptions[this.refusalOptions.length - 1]
  },
  methods: {
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: {
          ...this.localFormatted,
          refuseMedicalTreatmentSome: trimIfExists(
            this.localFormatted.refuseMedicalTreatmentSome
          ),
        },
      })
    },
    selectOption(key) {
      this.setAllShowErrors(false)
      this.local.refuseMedicalTreatment = key
    },
  },
  computed: {
    attorneySuffix() {
      return this.local.attorneys.length > 1 ? "s" : ""
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (
        this.localFormatted.refuseMedicalTreatment === "some" &&
        isEmptyTrimmed(this.local.refuseMedicalTreatmentSome)
      ) {
        msgs.refuseMedicalTreatmentSome = "Please specify the powers."
      }
      if (this.localFormatted.refuseMedicalTreatment === null) {
        msgs.refuseMedicalTreatment = "Please select an option."
      }
      return msgs
    },
    localFormatted() {
      return {
        refuseMedicalTreatment: this.local.refuseMedicalTreatment,
        refuseMedicalTreatmentSome:
          this.local.refuseMedicalTreatment === "some"
            ? this.local.refuseMedicalTreatmentSome
            : null,
      }
    },
  },
}
</script>
