<template>
  <div>
    <div>
      <section-item
        @edit="open('appoint-attorneys')"
        class="mb-1"
      >
        <div class="text-label mb-2">Attorneys</div>
        <ul
          v-if="attorneys.length"
          class="mt-2"
        >
          <li
            v-for="item in attorneys"
            :key="item.id"
            class="mb-1"
          >
            {{ item.name }}
            <chip-required
              v-if="!item.address || !item.address.trim().length"
              text="Address required"
              @click="openEditPerson(item.id)"
              :class="{ shake: shake }"
            />
          </li>
        </ul>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('appoint-attorneys')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to add at least 1 attorney.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('substitute-attorneys')"
        class="mb-1"
      >
        <div class="text-label mb-2">Substitute attorneys</div>
        <ul
          v-if="substitutes.length"
          class="mt-2"
        >
          <li
            v-for="item in substitutes"
            :key="item.id"
            class="mb-1"
          >
            {{ item.name }}
            <chip-required
              v-if="!item.address || !item.address.trim().length"
              text="Address required"
              @click="openEditPerson(item.id)"
              :class="{ shake: shake }"
            />
          </li>
        </ul>
        <div v-else>
          <div class="text-no-result text-left">
            Click 'Edit' on the right to optionally add substitutes.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('authority-to-someone-else')"
        class="mb-1"
      >
        <div class="text-label">Authority to Someone Else</div>
        <div
          v-if="local.attorneyAuthoriseOther"
          class="clamp-3"
        >
          {{ attorneyAuthoriseOtherSummary }}
        </div>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('authority-to-someone-else')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to answer this question.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('functions')"
        class="mb-1"
      >
        <div class="text-label">Functions and limitations</div>
        <div v-if="local.functions.property">
          <v-icon
            color="primary"
            size="16"
            class="mx-1"
            >$tick</v-icon
          >Property matters
        </div>
        <div v-if="local.functions.personalCare">
          <v-icon
            color="primary"
            size="16"
            class="mx-1"
            >$tick</v-icon
          >Personal care matters
        </div>
        <div v-if="local.functions.healthCare">
          <v-icon
            color="primary"
            size="16"
            class="mx-1"
            >$tick</v-icon
          >Health care matters
        </div>
        <div v-if="local.functions.medicalResearch">
          <v-icon
            color="primary"
            size="16"
            class="mx-1"
            >$tick</v-icon
          >Medical research matters
        </div>
        <div v-if="!functionsComplete">
          <chip-required
            :class="{ shake: shake }"
            @click="open('functions')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to choose the functions of your attorneys.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('refusals-or-withdrawals')"
        class="mb-1"
      >
        <div class="text-label">
          Refusals or withdrawals of medical treatment
        </div>
        <div
          v-if="local.refuseMedicalTreatment"
          class="clamp-3"
        >
          {{ refuseMedicalTreatmentSummary }}
        </div>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('refusals-or-withdrawals')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to answer this question.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('commencement')"
        class="mb-1"
      >
        <div class="text-label">Commencement</div>
        <div v-if="local.commencement">
          <chip-required
            v-if="!commencementDateIsInFuture"
            :class="{ shake: shake }"
            class="mb-2"
            @click="open('commencement')"
            text="Commencement date must be in the future"
          />
          <div>{{ commencementSummary }}</div>
        </div>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('commencement')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to specify when this Enduring Power of
            Attorney is to commence.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('marriage')"
        class="mb-1"
      >
        <div class="text-label mb-2">
          Marriage, civil union or civil partnership
        </div>
        <div
          v-if="!marriageFull"
          class="text-no-result text-left"
        >
          Click 'Edit' on the right to optionally add someone.
        </div>
        <div v-else>
          {{ marriageFull.name }}
          <chip-required
            v-if="!marriageFull.address || !marriageFull.address.trim().length"
            text="Information required"
            @click="openEditPerson(local.marriage)"
            :class="{ shake: shake }"
          />
        </div>
      </section-item>

      <dialog-full
        :open.sync="dialogOpen"
        title="Enduring Power of Attorney"
        :path="gaUrl"
      >
        <component
          v-if="openForm"
          :is="`dialog-${openForm}`"
          @close="close"
          @next="next"
        />
      </dialog-full>
    </div>
    <drawer
      title="Edit Person"
      :open.sync="openEdit"
    >
      <div class="pa-4">
        <slot name="form">
          <form-person
            v-if="openEdit"
            :id="editId"
            @close="openEdit = false"
            @next="openEdit = false"
            :editable="editable"
          />
        </slot>
      </div>
    </drawer>
  </div>
</template>

<script>
import { act_epa_required } from "../../../../config/poaRequiredFields"
import poa from "../mixins/poa.js"
import DialogAppointAttorneys from "./forms/epa/AppointAttorneys.vue"
import DialogAuthorityToSomeoneElse from "./forms/epa/AuthorityToSomeoneElse.vue"
import DialogCommencement from "./forms/epa/Commencement.vue"
import DialogFunctions from "./forms/epa/Functions.vue"
import DialogMarriage from "./forms/epa/Marriage.vue"
import DialogRefusalsOrWithdrawals from "./forms/epa/RefusalsOrWithdrawals.vue"
import DialogSubstituteAttorneys from "./forms/epa/SubstituteAttorneys.vue"
import { getAuthorityOptions, getRefusalOptions } from "./lists"

const storeName = "poa_act_epa"
const documentDir = "actEpa"

export default {
  name: "PoaEnduringPowerOfAttorney",
  mixins: [poa(storeName)],
  components: {
    DialogAppointAttorneys,
    DialogSubstituteAttorneys,
    DialogAuthorityToSomeoneElse,
    DialogRefusalsOrWithdrawals,
    DialogFunctions,
    DialogCommencement,
    DialogMarriage,
  },
  data: () => ({
    editable: { address: true },
    required: act_epa_required,
    documentDir: documentDir,
  }),
  methods: {},
  computed: {
    attorneys() {
      return this.entities_people.filter((o) =>
        this[storeName].attorneys.includes(o.id)
      )
    },
    attorneySuffix() {
      return this.attorneys.length === 1 ? "" : "s"
    },
    substitutes() {
      return this.entities_people.filter((o) =>
        this[storeName].substitutes.includes(o.id)
      )
    },
    marriageFull() {
      return this.entities_people.find((p) => p.id === this.local.marriage)
    },
    attorneyAuthoriseOtherSummary() {
      const options = getAuthorityOptions(this.attorneys.length > 1)
      const option = options.find(
        (o) => o.key === this.local.attorneyAuthoriseOther
      )
      if (option.key === "specified")
        return this.local.attorneyAuthoriseOtherSpecified
      return option.text
    },
    refuseMedicalTreatmentSummary() {
      const options = getRefusalOptions(this.attorneys.length > 1)
      const option = options.find(
        (o) => o.key === this.local.refuseMedicalTreatment
      )
      if (option.key === "some") return this.local.refuseMedicalTreatmentSome
      return option.text
    },
    commencementSummary() {
      if (this.local.commencement === "immediately")
        return `This Enduring Power of Attorney commences immediately.`
      if (this.local.commencement === "from-date")
        return `This Enduring Power of Attorney commences on ${this.local.commencementDate.day}/${this.local.commencementDate.month}/${this.local.commencementDate.year}`
      if (this.local.commencement === "from-event")
        return `This Enduring Power of Attorney commences from an event: ${this.local.commencementEvent}`
      if (this.local.commencement === "impaired")
        return `This Enduring Power of Attorney commences only when I become a person with impaired decision-making capacity.`
      return ``
    },
    commencementDateIsInFuture() {
      if (this.local.commencement !== "from-date") return true
      const currentDate = new Date()
      const commencementDate = new Date(
        this.local.commencementDate.year,
        this.local.commencementDate.month - 1,
        this.local.commencementDate.day
      )
      return commencementDate.getTime() > currentDate.getTime()
    },
    functionsComplete() {
      return (
        this.local.functions.property ||
        this.local.functions.healthCare ||
        this.local.functions.personalCare ||
        this.local.functions.medicalResearch
      )
    },
  },
}
</script>
