<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Circumstances</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            If there are certain circumstances in which you want your enduring
            guardian{{ guardianSuffix }} to act, please specify them here.
          </p>
          <p>For example:</p>
          <ul>
            <li>
              My enduring guardian is only to act while they live in the same
              town as me.
            </li>
            <li>
              I have a diagnosed mental illness and my enduring guardian is to
              act only at times when my doctor states I am unwell and do not
              have capacity.
            </li>
          </ul>
        </div>
      </notification-box>

      <h3 class="mb-4">
        Would you like to specify circumstances under which your Enduring
        Guardian{{ guardianSuffix }} may act?
      </h3>

      <div class="toggle-yes-no">
        <item-select
          :value="showTextbox"
          @select="setShowTextbox(true)"
          title="Yes"
        />
        <item-select
          :value="!showTextbox"
          @select="setShowTextbox(false)"
          title="No"
        />
      </div>

      <v-textarea
        :rules="[maxLengthRule]"
        v-if="showTextbox"
        v-bind="textAreaFieldGrow"
        v-model="local.circumstances"
        :error-messages="
          showErrors.circumstances && errorMessages.circumstances
            ? errorMessages.circumstances
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from "../../../mixins/forms.js"
import { isEmptyTrimmed, trimIfExists } from "../../../utils"

const storeName = "poa_wa_epg"
const documentId = "poaWaEpg"

export default {
  name: "Circumstances",
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      circumstances: false,
    },
    showTextbox: false,
  }),
  mounted() {
    this.showTextbox = !!this.local.circumstances
  },
  methods: {
    setShowTextbox(value) {
      this.setAllShowErrors(false)
      this.showTextbox = value
    },
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: {
          circumstances: trimIfExists(this.localFormatted.circumstances),
        },
      })
    },
  },
  computed: {
    guardianSuffix() {
      return this.local.guardians.length === 1 ? "" : "s"
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.showTextbox && isEmptyTrimmed(this.localFormatted.circumstances))
        msgs.circumstances = "Please enter the circumstances."
      return msgs
    },
    localFormatted() {
      return {
        circumstances: this.showTextbox ? this.local.circumstances : null,
      }
    },
  },
}
</script>
