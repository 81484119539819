<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">When to notify for financial matters</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            Please select each option that you would like to be notified about
            when it occurs.
          </p>
        </div>
      </notification-box>

      <div class="mb-4">
        <item-select
          :value="localFormatted.financialNotify.when.onRequest"
          @select="
            local.financialNotify.when.onRequest =
              !local.financialNotify.when.onRequest
          "
          title="On request"
          text="My attorney(s) must give the information on request at any time by the nominated person."
          class="mb-2"
        />
        <item-select
          :value="showTimeframeTextbox"
          @select="toggleShowTimeframeTextbox"
          title="On timeframe"
          text="My attorney(s) must give the information on a regular timeframe."
          class="mb-2"
        >
          <v-expand-transition>
            <div
              v-if="showTimeframeTextbox"
              class="mx-4"
            >
              <h3 class="my-4">Please state the timeframe:</h3>
              <p>
                <i>
                  e.g. annually on 1 July of each year or quarterly starting on
                  1 July
                </i>
              </p>
              <v-textarea
                :rules="[maxLengthRule]"
                v-bind="textAreaFieldGrow"
                v-model="local.financialNotify.when.onTimeframe"
                :error-messages="
                  showErrors.onTimeframe && errorMessages.onTimeframe
                    ? errorMessages.onTimeframe
                    : null
                "
              />
            </div>
          </v-expand-transition>
        </item-select>
        <item-select
          :value="showOtherTextbox"
          @select="toggleShowOtherTextbox"
          title="Other"
          class="mb-2"
        >
          <v-expand-transition>
            <div
              v-if="showOtherTextbox"
              class="mx-4"
            >
              <h3 class="my-4">Please specify:</h3>
              <v-textarea
                :rules="[maxLengthRule]"
                v-bind="textAreaFieldGrow"
                v-model="local.financialNotify.when.onOther"
                :error-messages="
                  showErrors.onOther && errorMessages.onOther
                    ? errorMessages.onOther
                    : null
                "
              />
            </div>
          </v-expand-transition>
        </item-select>
        <v-input
          :error-messages="
            showErrors.commencement && errorMessages.commencement
              ? errorMessages.commencement
              : null
          "
        />
      </div>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import DateField from "@/components/fields/DateField"

import forms from "../../../mixins/forms.js"
import { isEmptyTrimmed, trimIfExists } from "../../../utils"

const storeName = "poa_qld_epa"
const documentId = "poaQldEpa"

export default {
  name: "Commencement",
  mixins: [forms(storeName, documentId)],
  components: {
    DateField,
  },
  data: () => ({
    showErrors: {
      onTimeframe: false,
      onOther: false,
    },
    showTimeframeTextbox: false,
    showOtherTextbox: false,
  }),
  mounted() {
    this.showTimeframeTextbox = this.local.financialNotify.when.onTimeframe
      ? true
      : false
    this.showOtherTextbox = this.local.financialNotify.when.onOther
      ? true
      : false
  },
  methods: {
    toggleShowTimeframeTextbox() {
      this.setAllShowErrors(false)
      this.showTimeframeTextbox = !this.showTimeframeTextbox
    },
    toggleShowOtherTextbox() {
      this.setAllShowErrors(false)
      this.showOtherTextbox = !this.showOtherTextbox
    },
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: {
          financialNotify: {
            ...this.localFormatted.financialNotify,
            when: {
              ...this.localFormatted.financialNotify.when,
              onTimeframe: trimIfExists(
                this.localFormatted.financialNotify.when.onTimeframe
              ),
              onOther: trimIfExists(
                this.localFormatted.financialNotify.when.onOther
              ),
            },
          },
        },
      })
    },
  },
  computed: {
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (
        this.showTimeframeTextbox &&
        isEmptyTrimmed(this.local.financialNotify.when.onTimeframe)
      )
        msgs.onTimeframe = "Please specify the timeframe."
      if (
        this.showOtherTextbox &&
        isEmptyTrimmed(this.local.financialNotify.when.onTimeframe)
      )
        msgs.onOther = "Please specify the details."

      return msgs
    },
    localFormatted() {
      return {
        financialNotify: {
          ...this.local.financialNotify,
          when: {
            onRequest: this.local.financialNotify.when.onRequest,
            onTimeframe: this.showTimeframeTextbox
              ? this.local.financialNotify.when.onTimeframe
              : null,
            onOther: this.showOtherTextbox
              ? this.local.financialNotify.when.onOther
              : null,
          },
        },
      }
    },
  },
}
</script>
