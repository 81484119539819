<template>
  <div>
    <div>
      <section-item
        @edit="open('appoint-substitutes')"
        class="mb-1"
      >
        <div class="text-label mb-2">Appointing Substitute Decision-Makers</div>
        <ul
          v-if="substitutes.length"
          class="mt-2"
        >
          <li
            v-for="item in substitutes"
            :key="item.id"
            class="mb-1"
          >
            {{ item.name }}
            <chip-required
              v-if="!allRequiredFieldsComplete(item)"
              text="Information required"
              @click="openEditPerson(item.id)"
              :class="{ shake: shake }"
            />
          </li>
        </ul>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('appoint-substitutes')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to add at least 1 substitute
            decision-maker.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('important-to-me')"
        class="mb-1"
      >
        <div class="text-label mb-2">
          What is important to me - my values and wishes:
        </div>
        <div v-if="hasImportantToMe">
          <h5>Your decision-makers will consider:</h5>
          <div class="clamp-2 mb-2">
            {{ local.importantToMe.considerations }}
          </div>
          <h5>Outcomes you wish to avoid:</h5>
          <div class="clamp-2 mb-2">
            {{ local.importantToMe.avoidOutcomes }}
          </div>
          <h5>Health care you prefer:</h5>
          <div class="clamp-2 mb-2">
            {{ local.importantToMe.preferredHealthCare }}
          </div>
          <h5>Where you wish to live:</h5>
          <div class="clamp-2 mb-2">{{ local.importantToMe.whereToLive }}</div>
          <h5>Other personal arrangements</h5>
          <div class="clamp-2 mb-2">
            {{ local.importantToMe.personalArrangements }}
          </div>
          <h5>Dying wishes:</h5>
          <div class="clamp-2">{{ local.importantToMe.dyingWishes }}</div>
        </div>
        <div
          v-else
          class="text-no-result text-left"
        >
          You have not yet provided any information. Click 'Edit' on the right
          to do so.
        </div>
      </section-item>

      <section-item
        @edit="open('binding-refusals')"
        class="mb-1"
      >
        <div class="text-label mb-2">Binding refusals of health care</div>
        <div v-if="local.bindingRefusals">
          <div class="clamp-3">
            {{ local.bindingRefusals }}
          </div>
        </div>
        <div
          v-else
          class="text-no-result text-left"
        >
          You have not yet provided any binding refusals of health care. Click
          'Edit' on the right to do so.
        </div>
      </section-item>

      <dialog-full
        :open.sync="dialogOpen"
        title="Advance Care Directive"
        :path="gaUrl"
      >
        <component
          v-if="openForm"
          :is="`dialog-${openForm}`"
          @close="close"
          @next="next"
        />
      </dialog-full>
    </div>
    <drawer
      title="Edit Person"
      :open.sync="openEdit"
    >
      <div class="pa-4">
        <slot name="form">
          <form-person
            v-if="openEdit"
            :id="editId"
            @close="openEdit = false"
            @next="openEdit = false"
            :editable="editable"
          />
        </slot>
      </div>
    </drawer>
  </div>
</template>

<script>
import { sa_acd_required } from "../../../../config/poaRequiredFields"
import poa from "../mixins/poa.js"
import DialogAppointSubstitutes from "./forms/acd/AppointSubstitutes.vue"
import DialogBindingRefusals from "./forms/acd/BindingRefusals.vue"
import DialogImportantToMe from "./forms/acd/ImportantToMe.vue"

const storeName = "poa_sa_acd"
const documentDir = "saAcd"

export default {
  name: "PoaAdvanceCareDirective",
  mixins: [poa(storeName)],
  components: {
    DialogAppointSubstitutes,
    DialogImportantToMe,
    DialogBindingRefusals,
  },
  data: () => ({
    editable: { address: true, phone: true, dob: true },
    required: sa_acd_required,
    documentDir: documentDir,
  }),
  methods: {},
  computed: {
    hasImportantToMe() {
      return (
        this.local.importantToMe.considerations ||
        this.local.importantToMe.avoidOutcomes ||
        this.local.importantToMe.preferredHealthCare ||
        this.local.importantToMe.whereToLive ||
        this.local.importantToMe.personalArrangements ||
        this.local.importantToMe.dyingWishes
      )
    },
    substitutes() {
      return this.entities_people.filter((o) =>
        this[storeName].substitutes.includes(o.id)
      )
    },
  },
}
</script>
