<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">
        Additional powers <span class="optional">(optional)</span>
      </h2>
      <p>
        You may choose to allow your attorney to use your money and assets to
        pay for those things listed.
      </p>

      <h3 class="mb-4">
        Optionally select additional powers you would like to give your
        attorney{{ attorneySuffix }}:
      </h3>

      <div class="mb-4">
        <item-select
          :value="localFormatted.authorisations.reasonableGifts"
          @select="toggleReasonableGifts()"
          title="Reasonable gifts"
          text="I authorise my attorney to give reasonable gifts."
          class="mb-3"
        >
          <p
            class="definition-link pr-4 pl-14 my-0 py-4"
            @click="openDefinition('gifts')"
          >
            What is a reasonable gift?
          </p>
        </item-select>
        <item-select
          :value="localFormatted.authorisations.benefitsAttorney"
          @select="toggleBenefits()"
          title="Benefits to attorney"
          text="I authorise my attorney to confer benefits on the attorney to meet their reasonable living and medical expenses."
          class="mb-2"
        >
          <p
            class="definition-link pr-4 pl-14 my-0 py-4"
            @click="openDefinition('benefits')"
          >
            What are benefits to attorney?
          </p>
        </item-select>
        <item-select
          :value="showSelectPersons"
          @select="toggleBeneitsSelected()"
          title="Benefits to selected persons"
          text="I authorise my attorney to confer benefits on selected persons to meet their reasonable living and medical expenses."
        >
          <p
            class="definition-link pr-4 pl-14 my-0 py-4"
            @click="openDefinition('benefits-selected')"
          >
            What are benefits to selected persons?
          </p>
          <v-expand-transition>
            <div
              v-if="showSelectPersons"
              class="mx-4"
            >
              <transition
                name="component-fade"
                mode="out-in"
              >
                <div
                  v-if="!localFormatted.authorisations.benefitsPeople.length"
                  class="text-no-result"
                  key="empty"
                >
                  - You have not added any people to be eligible for benefits -
                </div>
                <div
                  key="list"
                  v-else
                >
                  <transition-group
                    name="component-fade"
                    mode="out-in"
                  >
                    <item-person
                      v-for="(item, index) in localFormatted.authorisations
                        .benefitsPeople"
                      :key="item + index"
                      class="mb-2"
                      :id="item"
                      :editable="editable"
                      type="person"
                      @remove="removeBenifitsPerson(index)"
                    >
                      <template v-slot:details="{ entity }">
                        <div
                          v-if="entity.type === 'person'"
                          class="text-sm text-cutoff opacity-70 py-1"
                        >
                          <span
                            v-if="!entity.address"
                            class="font-italic"
                            >- Address Required -</span
                          >
                          <span v-else>
                            {{ entity.address }}
                          </span>
                        </div>
                      </template>
                    </item-person>
                  </transition-group>
                </div>
              </transition>
              <div class="mb-2">
                <btn-add-large
                  label="Add person"
                  class="mb-2"
                  @click="openDrawerPerson = true"
                  @clickDisabled="disabledMessage()"
                  :disabled="isMaximumSelected"
                />
              </div>
              <v-input
                :error-messages="
                  showErrors.benefitsPeople && errorMessages.benefitsPeople
                    ? errorMessages.benefitsPeople
                    : null
                "
              />
              <br />
            </div>
          </v-expand-transition>
        </item-select>
      </div>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add person"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addBenefitsPerson"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="['address']"
      />
    </drawer>

    <drawer
      :open.sync="showDefinition"
      title="Definitions"
      class="faq-drawer"
    >
      <div
        v-if="showGiftsDefinition"
        class="pa-4"
      >
        <div class="text-lg font-weight-bold mb-4">Reasonable gifts</div>
        <p>
          According to Schedule 3 of the <i>Powers of Attorney Act 2003</i>, an
          attorney is authorised to give a gift only if:
        </p>

        <ol style="list-style: lower-alpha">
          <li>
            the gift is:
            <ol style="list-style: lower-roman">
              <li>to a relative or close friend of the principal, and</li>
              <li>
                of a seasonal nature or because of a special event (including,
                for example, a birth or marriage), or
              </li>
            </ol>
          </li>
          <li>
            the gift is a donation of the nature that the principal made when
            the principal had capacity or the principal might reasonably be
            expected to make, and the gift's value is not more than what is
            reasonable having regard to all the circumstances and, in
            particular, the principal's financial circumstances and the size of
            the principal's estate.
          </li>
        </ol>
        <br />
        <br />
        <div class="text-lg font-weight-bold mb-4">Note:</div>
        <p>
          <b>Close friend</b> of a principal means another individual who has a
          close personal relationship with the principal and a personal interest
          in the principal's welfare.
        </p>
        <br />
        <p><b>Relative</b> of a principal means:</p>
        <ol style="list-style: lower-alpha">
          <li>
            a mother, father, spouse, daughter, son, step-daughter, step-son,
            sister, brother, half-sister, half-brother or grandchild of the
            principal, or
          </li>
          <li>
            if the principal is a party to a registered relationship or
            interstate registered relationship, within the meaning of the
            <i>Relationships Register Act 2010</i>, or a domestic relationship
            within the meaning of the <i>Property (Relationships) Act 1984</i>,
            any person who is a relative, of the kind mentioned in paragraph
            (a), of either party to the relationship.
          </li>
        </ol>
      </div>

      <div
        v-if="showBenefitsDefinition"
        class="pa-4"
      >
        <div class="text-lg font-weight-bold mb-4">Benefits to attorney</div>
        <p>
          According to Schedule 3 of the <i>Powers of Attorney Act 2003</i>, an
          attorney is authorised to confer a benefit on the attorney only if:
        </p>

        <ol style="list-style: lower-alpha">
          <li>
            the benefit meets (whether in whole or in part) any expenses
            incurred (or to be incurred) by the attorney in respect of any of
            the following:
            <ol style="list-style: lower-roman">
              <li>housing,</li>
              <li>food,</li>
              <li>education,</li>
              <li>transportation,</li>
              <li>medical care and medication, and</li>
            </ol>
          </li>
          <li>
            the benefit is not more than what is reasonable having regard to all
            the circumstances and, in particular, the principal’s financial
            circumstances and the size of the principal’s estate.
          </li>
        </ol>
      </div>

      <div
        v-if="showBenefitsSelectedDefinition"
        class="pa-4"
      >
        <div class="text-lg font-weight-bold mb-4">
          Benefits to selected persons
        </div>
        <p>
          According to Schedule 3 of the <i>Powers of Attorney Act 2003</i>, an
          attorney is authorised to confer a benefit on a named third party only
          if:
        </p>

        <ol style="list-style: lower-alpha">
          <li>
            the benefit meets (whether in whole or in part) any expenses
            incurred (or to be incurred) by the third party in respect of any of
            the following:
            <ol style="list-style: lower-roman">
              <li>housing,</li>
              <li>food,</li>
              <li>education,</li>
              <li>transportation,</li>
              <li>medical care and medication, and</li>
            </ol>
          </li>
          <li>
            the benefit is not more than what is reasonable having regard to all
            the circumstances and, in particular, the principal’s financial
            circumstances and the size of the principal’s estate.
          </li>
        </ol>
      </div>
    </drawer>
  </div>
</template>

<script>
import entitiesForms from "../../../mixins/entitiesForms.js"
import forms from "../../../mixins/forms.js"

const storeName = "poa_nsw_epa"
const documentId = "poaNswEpa"

export default {
  name: "Powers",
  mixins: [forms(storeName, documentId), entitiesForms("attorneys", "")],
  data: () => ({
    showErrors: {
      benefitsPeople: false,
    },
    editable: { address: true },
    showSelectPersons: false,
    showDefinition: false,
    showGiftsDefinition: false,
    showBenefitsDefinition: false,
    showBenefitsSelectedDefinition: false,
  }),
  mounted() {
    this.showSelectPersons = this.local.authorisations.benefitsPeople.length
  },
  methods: {
    addBenefitsPerson(id) {
      this.local.authorisations.benefitsPeople.push(id)
      this.openDrawerPerson = false
    },
    removeBenifitsPerson(index) {
      this.$delete(this.local.authorisations.benefitsPeople, index)
    },
    toggleReasonableGifts() {
      this.local.authorisations.reasonableGifts =
        !this.local.authorisations.reasonableGifts
    },
    toggleBenefits() {
      this.local.authorisations.benefitsAttorney =
        !this.local.authorisations.benefitsAttorney
    },
    toggleBeneitsSelected() {
      this.showSelectPersons = !this.showSelectPersons
    },
    openDefinition(type) {
      this.showGiftsDefinition = false
      this.showBenefitsDefinition = false
      this.showBenefitsSelectedDefinition = false
      this.showDefinition = true
      this.showGiftsDefinition = type === "gifts"
      this.showBenefitsDefinition = type === "benefits"
      this.showBenefitsSelectedDefinition = type === "benefits-selected"
    },
  },
  computed: {
    excludePeople() {
      return [
        ...this.childEntities,
        ...this.localFormatted.authorisations.benefitsPeople,
      ]
    },
    attorneySuffix() {
      return this.local.attorneys.length > 1 ? "s" : ""
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.localFormatted.authorisations.benefitsPeople.length > 20)
        msgs.benefitsPeople = "Maximum of 20 people with benefits."
      if (
        this.showSelectPersons &&
        this.localFormatted.authorisations.benefitsPeople.length < 1
      )
        msgs.benefitsPeople =
          "You have selected to add people with benefits. Please add them."
      return msgs
    },
    isMaximumSelected() {
      return this.localFormatted.authorisations.benefitsPeople.length >= 20
    },
    localFormatted() {
      return {
        authorisations: {
          reasonableGifts: this.local.authorisations.reasonableGifts
            ? true
            : false,
          benefitsAttorney: this.local.authorisations.benefitsAttorney
            ? true
            : false,
          benefitsPeople: this.showSelectPersons
            ? this.local.authorisations.benefitsPeople
            : [],
        },
      }
    },
  },
}
</script>
