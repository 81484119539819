<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Commencement</h2>
      <h3 class="mb-4">
        When can the attorney{{ attorneySuffix }} start making decisions?
      </h3>
      <item-select
        :value="'incapacity' === localFormatted.commencement"
        @select="selectCommencementOption('incapacity')"
        :title="incapacityText"
        class="mb-2"
      >
        <div
          class="px-14 pb-4"
          @click="selectCommencementOption('incapacity')"
        >
          <ol
            class="mb-1"
            style="list-style-type: lower-alpha"
          >
            <li>
              my
              <span
                class="definition-link"
                @click.stop="showLetter = true"
                >written instruction</span
              >
              to commence acting under this Enduring Powers of Attorney; or
            </li>
            <li>
              a written opinion from a medical practitioner that indicates to
              the satisfaction of my attorney(s) that I do not have the legal
              capacity to make or revoke an Enduring Power of Attorney,
            </li>
          </ol>
          whichever occurs first.
        </div>
      </item-select>
      <item-select
        :value="'immediately' === localFormatted.commencement"
        @select="selectCommencementOption('immediately')"
        title="Immediately"
        class="mb-2"
      >
        <div
          class="px-14 pb-4"
          @click="selectCommencementOption('immediately')"
        >
          Immediately on the making of this enduring power of attorney.
        </div>
      </item-select>
      <v-input
        :error-messages="
          showErrors.commencement && errorMessages.commencement
            ? errorMessages.commencement
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="showLetter"
      title="Example letter"
      class="faq-drawer"
    >
      <div class="pa-4">
        <div class="text-md font-weight-bold mb-4">
          The following is an example of a letter that you may send to your
          attorney to inform them that they are to start making decisions for
          you:
        </div>
        <p>1 January 2023</p>
        <br />
        <div>John Smith</div>
        <div>1 Green Avenue</div>
        <div>Melbourne</div>
        <p>Victoria 3000</p>
        <br />
        <p>Dear John,</p>
        <p>
          I have appointed you as my attorney pursuant to the Enduring Power of
          Attorney dated 15 June 2018 (‘EPA’). At clause 3 of the EPA it is
          written as follows:
        </p>
        <p>
          “My attorney can start making decisions from the time, in the
          circumstances or on the occasion: When I inform my attorneys in
          writing that I want them to start making decisions.”
        </p>
        <p>
          Accordingly, I hereby inform you in writing that I want you to
          commence acting as my attorney as of today, 1 January 2023.
        </p>
        <p>Yours sincerely,</p>
        <p>James Brown</p>
      </div>
    </drawer>
  </div>
</template>

<script>
import forms from "../../../mixins/forms.js"

const storeName = "poa_vic_epa"
const documentId = "poaVicEpa"

export default {
  name: "Commencement",
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      commencement: false,
    },
    showLetter: false,
  }),
  methods: {
    selectCommencementOption(key) {
      this.setAllShowErrors(false)
      this.$set(this.local, "commencement", key)
    },
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: {
          ...this.localFormatted,
        },
      })
    },
  },
  computed: {
    attorneySuffix() {
      return this.local.attorneys.length > 1 ? "s" : ""
    },
    incapacityText() {
      return `Upon my attorney${this.attorneySuffix} receiving:`
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (!this.local.commencement)
        msgs.commencement = "Please select an option"
      return msgs
    },
    localFormatted() {
      return {
        commencement: this.local.commencement ? this.local.commencement : null,
      }
    },
  },
}
</script>
