<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Advance Care Directive</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            If you have made an Advance Care Directive (ACD), you can provide
            details of the ACD here. The provisions of the ACD will take
            precedence over this instrument if there is any inconsistency.
          </p>
        </div>
      </notification-box>

      <h3 class="mb-4">Have you made an Advance Care Directive?</h3>
      <div class="toggle-yes-no mb-8">
        <item-select
          :value="showForm"
          @select="setShowForm(true)"
          title="Yes"
        />
        <item-select
          :value="!showForm"
          @select="setShowForm(false)"
          title="No"
        />
      </div>

      <div
        v-if="showForm"
        class="mb-2"
      >
        <h3 class="mb-4">Date ACD made?</h3>
        <date-field
          v-bind.sync="date"
          :valid.sync="dateValid"
          :in-past-not-dob="true"
          ref="dateRef"
        />
      </div>

      <div v-if="showForm">
        <h3 class="mb-4">
          Is the ACD is registered with the Tasmanian Civil and Administrative
          Tribunal?
        </h3>
        <div class="toggle-yes-no mb-6">
          <item-select
            :value="showRegNumberInput"
            @select="setShowRegNumberInput(true)"
            title="Yes"
          />
          <item-select
            :value="!showRegNumberInput"
            @select="setShowRegNumberInput(false)"
            title="No"
          />
        </div>
        <div v-if="showRegNumberInput">
          <h3 class="mb-4">ACD registration number:</h3>
          <v-text-field
            v-bind="textField"
            v-model="local.advanceCareDirective.registrationNumber"
            :error-messages="
              showErrors.registrationNumber && errorMessages.registrationNumber
                ? errorMessages.registrationNumber
                : null
            "
          />
        </div>
      </div>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import DateField from "@/components/fields/DateField"

import forms from "../../../mixins/forms.js"
import { isEmptyTrimmed, trimIfExists } from "../../../utils"

const storeName = "poa_tas_eg"
const documentId = "poaTasEg"

export default {
  name: "Directions",
  mixins: [forms(storeName, documentId)],
  components: {
    DateField,
  },
  data: () => ({
    showErrors: {
      registrationNumber: false,
      date: false,
    },
    date: {
      day: "",
      month: "",
      year: "",
    },
    dateValid: false,
    showForm: false,
    showRegNumberInput: false,
  }),
  mounted() {
    this.showForm = !!this.local.advanceCareDirective.date?.day
    this.date =
      this.local.advanceCareDirective && this.local.advanceCareDirective.date
        ? this.local.advanceCareDirective.date
        : {
            day: "",
            month: "",
            year: "",
          }
    this.showRegNumberInput =
      this.local.advanceCareDirective &&
      this.local.advanceCareDirective.registered
  },
  methods: {
    setShowForm(value) {
      this.setAllShowErrors(false)
      this.showForm = value
    },
    setShowRegNumberInput(value) {
      this.setAllShowErrors(false)
      this.showRegNumberInput = value
    },
    onSubmitFail() {
      this.$refs.dateRef.setErrorMessage("Please enter a valid date.")
    },
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: {
          advanceCareDirective: this.showForm
            ? {
                ...this.localFormatted.advanceCareDirective,
                registrationNumber: this.showRegNumberInput
                  ? trimIfExists(
                      this.local.advanceCareDirective.registrationNumber
                    )
                  : null,
              }
            : null,
        },
      })
    },
  },
  computed: {
    guardianSuffix() {
      return this.local.guardians.length === 1 ? "" : "s"
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (
        this.showForm &&
        this.showRegNumberInput &&
        isEmptyTrimmed(this.local.advanceCareDirective.registrationNumber)
      )
        msgs.registrationNumber = "Please enter a registration number"
      return msgs
    },
    isValid() {
      return (
        Object.values(this.errorMessages).every((val) => val === null) &&
        (this.showForm ? this.dateValid : true) &&
        this.textareaValid
      )
    },
    localFormatted() {
      return {
        advanceCareDirective: this.showForm
          ? {
              date: this.dateValid ? this.date : null,
              registered: this.showRegNumberInput,
              registrationNumber: this.showRegNumberInput
                ? this.local.advanceCareDirective.registrationNumber
                : null,
            }
          : null,
      }
    },
  },
}
</script>
