<template>
  <div>
    <div v-if="local">
      <div class="mb-4">
        <h2 class="mb-4">What is the alternate email?</h2>
        <v-text-field
          v-bind="textField"
          label="Alternate email"
          v-model="local.alternateEmail"
          :error-messages="
            showErrors.alternateEmail && errorMessages.alternateEmail
              ? errorMessages.alternateEmail
              : null
          "
          @input="showErrorsSwitch(true, 'altetnateEmail')"
          @blur="showErrorsSwitch(true, 'altetnateEmail')"
        />
      </div>
    </div>

    <div class="d-flex align-center">
      <v-btn
        v-bind="backButton"
        @click="close"
        ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
      >
      <v-spacer />
      <v-btn
        v-bind="buttonType"
        depressed
        @click="submit"
        >{{ submitLabel }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from "lodash"
import { mapGetters } from "vuex"

export default {
  name: "WillFormAboutAddress",
  props: {
    closeLabel: {
      default: "back",
    },
    submitLabel: {
      default: "save",
    },
  },
  data: () => ({
    local: null,
    showErrors: {
      alternateEmail: false,
    },
    processing: false,
  }),
  mounted() {
    this.local = {
      alternateEmail: cloneDeep(this.user_alternate_email.value),
    }
  },
  methods: {
    async submit() {
      this.processing = true
      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
        }
        setTimeout(() => {
          this.processing = false
          this.next()
        }, 500)
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    async save() {
      await this.$store.dispatch("account/save_user_data", this.localFormatted)
      if (this.$store.getters["account/user_profile_complete"]) {
        let sections = ["about"]
        if (this.$store.getters["account/user_partner"].isComplete) {
          sections.push("partner")
        }
        await this.$store.dispatch("account/update_will_progress", sections)
      }
    },
    close() {
      this.$emit("close")
    },
    next() {
      this.$emit("next")
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showErrors[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
    isValidEmail(email) {
      return /^.+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
    },
  },
  computed: {
    ...mapGetters("account", ["user_alternate_email"]),
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {
        alternateEmail: null,
      }

      if (
        this.localFormatted.alternateEmail &&
        !this.isValidEmail(this.localFormatted.alternateEmail)
      )
        msgs.alternateEmail = "Please enter a valid email."

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(this.localFormatted, this.user_alternate_email.value)
    },
    localFormatted() {
      return {
        alternateEmail:
          this.local && this.local.alternateEmail
            ? this.local.alternateEmail.trim()
            : null,
      }
    },
  },
}
</script>
