// forms is mixed into the components that are used in the forms.
// eg. poa>nsw>forms>eg>AppointGuardians.vue
import { cloneDeep, isEqual } from "lodash"
import { mapGetters } from "vuex"

import BtnAddLarge from "../../../components/ui/BtnAddLarge"
import ItemSelect from "../../../components/ui/ItemSelect"
import NotificationBox from "../../../components/ui/NotificationBox"
import Drawer from "../../../components/wrapper/Drawer"

export default function (storeName, documentId) {
  return {
    components: {
      ItemSelect,
      BtnAddLarge,
      NotificationBox,
      Drawer,
    },
    data: function () {
      return {
        local: null,
        processing: false,
        openDrawerPerson: false,
        textareaValid: true,
        maxLengthRule: (v) => {
          if (!v) {
            this.textareaValid = true
            return true
          }
          const valid = v?.length <= 10000 || `Max 10000 characters allowed`
          this.textareaValid = valid === true
          return valid
        },
      }
    },
    mounted() {
      this.local = cloneDeep(this[storeName])
    },
    props: {
      closeLabel: {
        default: "back",
      },
      submitLabel: {
        default: "Save",
      },
    },
    methods: {
      async submit() {
        this.processing = true
        if (this.isValid) {
          if (this.isChanged) {
            await this.save()
          }
          setTimeout(() => {
            this.processing = false
            this.next()
          }, 500)
          return
        }

        this.onSubmitFail()
        this.showErrorsSwitch()
        this.processing = false
      },
      async save() {
        this.$store.dispatch("account/save_poa", {
          id: documentId,
          data: this.localFormatted,
        })
      },
      close() {
        this.$emit("close")
      },
      next() {
        this.$emit("next")
      },
      onSubmitFail() {},
      showErrorsSwitch(show = true, field = null) {
        if (field) {
          this.showErrors[field] = show
          return
        }
        Object.keys(this.showErrors).forEach((key) => {
          this.showErrors[key] = show
        })
      },
      setAllShowErrors(toValue) {
        for (const key in this.showErrors) {
          if (Object.prototype.hasOwnProperty.call(this.showErrors, key)) {
            this.showErrorsSwitch(toValue, key)
          }
        }
      },
      allRequiredFieldsComplete(entity) {
        return this.required.every((field) => {
          return field === "dob"
            ? entity.dob &&
                entity.dob.day &&
                entity.dob.month &&
                entity.dob.year
            : entity[field] && entity[field].trim().length
        })
      },
    },
    computed: {
      ...mapGetters("account", [storeName]),
      buttonType() {
        if (this.processing) return this.btnProcessing
        if (this.isValid) return this.btnActive
        return this.btnInactive
      },
      errorMessages() {
        let msgs = {}
        Object.keys(this.showErrors).forEach((field) => {
          msgs[field] = null
        })
        return msgs
      },
      isValid() {
        return (
          Object.values(this.errorMessages).every((val) => val === null) &&
          this.textareaValid
        )
      },
      isChanged() {
        return !isEqual(this.localFormatted, this[storeName].value)
      },
    },
  }
}
