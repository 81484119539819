// entitiesForm is mixed into the components that are used in the forms used to
// manage types of entities, such as attorneys, substitutes, guardians etc.
// for forms that capture information for multiple entities or a single entity.
// eg. poa>nsw>forms>eg>AppointGuardians.vue
import { mapGetters } from "vuex"

import Drawer from "../../../components/wrapper/Drawer"
import DrawerPersonSelect from "../../../components/wrapper/DrawerPersonSelect"
import ItemPerson from "../../../components/wrapper/ItemPerson"

export default function (entityType, disabledMessageText) {
  return {
    components: {
      ItemPerson,
      DrawerPersonSelect,
      Drawer,
    },
    data: function () {
      return {
        openDrawerPerson: false,
        childEntities: [],
      }
    },
    mounted() {
      this.childEntities = [
        ...this.entities_people
          .filter((x) => x.guardians && x.guardians.length)
          .map((x) => x.id),
      ]
    },
    methods: {
      disabledMessage() {
        if (this.isMaximumSelected) {
          this.$store.commit("app/SET_MESSAGE", {
            type: null,
            text: disabledMessageText,
          })
          return
        }
      },
    },
    computed: {
      ...mapGetters("account", ["entities_people"]),
      isMaximumSelected() {
        return this.localFormatted[entityType].length >= 4
      },
    },
  }
}
