<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">What is important to you</h2>

      <h3 class="mb-4">
        When decisions are being made for you, what do you want the
        decision-makers to consider?
      </h3>
      <v-textarea
        :rules="[maxLengthRule]"
        v-bind="textAreaFieldShort"
        v-model="local.importantToMe.considerations"
        :dense="true"
        :error-messages="
          showErrors.considerations && errorMessages.considerations
            ? errorMessages.considerations
            : null
        "
      />

      <h3 class="mb-4">What are the outcomes of care you wish to avoid?</h3>
      <v-textarea
        :rules="[maxLengthRule]"
        v-bind="textAreaFieldShort"
        v-model="local.importantToMe.avoidOutcomes"
        :error-messages="
          showErrors.avoidOutcomes && errorMessages.avoidOutcomes
            ? errorMessages.avoidOutcomes
            : null
        "
      />

      <h3 class="mb-4">What health care do you prefer?</h3>
      <v-textarea
        :rules="[maxLengthRule]"
        v-bind="textAreaFieldShort"
        v-model="local.importantToMe.preferredHealthCare"
        :error-messages="
          showErrors.preferredHealthCare && errorMessages.preferredHealthCare
            ? errorMessages.preferredHealthCare
            : null
        "
      />

      <h3 class="mb-4">Where do you wish to live?</h3>
      <v-textarea
        :rules="[maxLengthRule]"
        v-bind="textAreaFieldShort"
        v-model="local.importantToMe.whereToLive"
        :error-messages="
          showErrors.whereToLive && errorMessages.whereToLive
            ? errorMessages.whereToLive
            : null
        "
      />

      <h3 class="mb-4">Please list other personal arrangements:</h3>
      <v-textarea
        :rules="[maxLengthRule]"
        v-bind="textAreaFieldShort"
        v-model="local.importantToMe.personalArrangements"
        :error-messages="
          showErrors.personalArrangements && errorMessages.personalArrangements
            ? errorMessages.personalArrangements
            : null
        "
      />

      <h3 class="mb-4">What are your dying wishes?</h3>
      <v-textarea
        :rules="[maxLengthRule]"
        v-bind="textAreaFieldShort"
        v-model="local.importantToMe.dyingWishes"
        :error-messages="
          showErrors.dyingWishes && errorMessages.dyingWishes
            ? errorMessages.dyingWishes
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from "../../../mixins/forms.js"
import { trimIfExists } from "../../../utils"

const storeName = "poa_sa_acd"
const documentId = "poaSaAcd"

export default {
  name: "ImportantToMe",
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {},
  }),
  methods: {
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: {
          importantToMe: {
            considerations: trimIfExists(
              this.localFormatted.importantToMe.considerations
            ),
            avoidOutcomes: trimIfExists(
              this.localFormatted.importantToMe.avoidOutcomes
            ),
            preferredHealthCare: trimIfExists(
              this.localFormatted.importantToMe.preferredHealthCare
            ),
            whereToLive: trimIfExists(
              this.localFormatted.importantToMe.whereToLive
            ),
            personalArrangements: trimIfExists(
              this.localFormatted.importantToMe.personalArrangements
            ),
            dyingWishes: trimIfExists(
              this.localFormatted.importantToMe.dyingWishes
            ),
          },
        },
      })
    },
  },
  computed: {
    localFormatted() {
      return {
        importantToMe: {
          considerations: this.local.importantToMe.considerations
            ? this.local.importantToMe.considerations
            : null,
          avoidOutcomes: this.local.importantToMe.avoidOutcomes
            ? this.local.importantToMe.avoidOutcomes
            : null,
          preferredHealthCare: this.local.importantToMe.preferredHealthCare
            ? this.local.importantToMe.preferredHealthCare
            : null,
          whereToLive: this.local.importantToMe.whereToLive
            ? this.local.importantToMe.whereToLive
            : null,
          personalArrangements: this.local.importantToMe.personalArrangements
            ? this.local.importantToMe.personalArrangements
            : null,
          dyingWishes: this.local.importantToMe.dyingWishes
            ? this.local.importantToMe.dyingWishes
            : null,
        },
      }
    },
  },
}
</script>
