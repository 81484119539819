<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Goals and priorities</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            It is recommended that you discuss this section with your decision
            maker(s), family or doctor as it is important that anything you
            write should be readily understood by the people who are supporting
            and treating you.
          </p>
        </div>
      </notification-box>

      <h3 class="mb-4">
        If nearing death, what are your goals/priorities? What is most important
        to you?
      </h3>
      <p>
        For example, dignity, to be comfortable, and to have my friends and
        family around me etc.
      </p>

      <v-textarea
        :rules="[maxLengthRule]"
        v-bind="textAreaFieldGrow"
        v-model="local.advanceCareStatement.goalsPriorities"
        :error-messages="
          showErrors.goalsPriorities && errorMessages.goalsPriorities
            ? errorMessages.goalsPriorities
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from "../../../mixins/forms.js"
import { trimIfExists } from "../../../utils"

const storeName = "poa_nt_app"
const documentId = "poaNtApp"

export default {
  name: "GoalsAndPriorities",
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      goalsPriorities: false,
    },
  }),
  methods: {
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: {
          advanceCareStatement: {
            ...this.localFormatted.advanceCareStatement,
            goalsPriorities: trimIfExists(
              this.localFormatted.advanceCareStatement.goalsPriorities
            ),
          },
        },
      })
    },
  },
  computed: {
    localFormatted() {
      return {
        advanceCareStatement: {
          ...this.local.advanceCareStatement,
          goalsPriorities:
            this.local && this.local.advanceCareStatement.goalsPriorities
              ? this.local.advanceCareStatement.goalsPriorities
              : null,
        },
      }
    },
  },
}
</script>
