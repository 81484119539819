<template>
  <div>
    <div>
      <section-item
        @edit="open('appoint-donees')"
        class="mb-1"
      >
        <div class="text-label mb-2">Donees</div>
        <ul
          v-if="donees.length"
          class="mt-2"
        >
          <li
            v-for="item in donees"
            :key="item.id"
            class="mb-1"
          >
            {{ item.name }}
            <chip-required
              v-if="!item.address || !item.address.trim().length"
              text="Address required"
              @click="openEditPerson(item.id)"
              :class="{ shake: shake }"
            />
          </li>
        </ul>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('appoint-donees')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to add at least 1 donee.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('commencement')"
        class="mb-1 financial-section-item"
      >
        <div class="text-label">Commencement</div>
        <div v-if="local.commencement">
          {{
            commencementOptions.find((o) => o.key === local.commencement).text
          }}
        </div>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('commencement')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to specify when your donees powers are to
            begin.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('conditions-limitations-exclusions')"
        class="mb-1"
      >
        <div class="text-label">Conditions, limitations or exclusions</div>
        <div
          v-if="!local.exclusions"
          class="text-no-result text-left"
        >
          You have not yet provided any information. Click 'Edit' on the right
          to do so.
        </div>
        <div
          v-else
          class="clamp-3"
        >
          {{ local.exclusions }}
        </div>
      </section-item>

      <dialog-full
        :open.sync="dialogOpen"
        title="Enduring Power of Donee"
        :path="gaUrl"
      >
        <component
          v-if="openForm"
          :is="`dialog-${openForm}`"
          @close="close"
          @next="next"
        />
      </dialog-full>
    </div>
    <drawer
      title="Edit Person"
      :open.sync="openEdit"
    >
      <div class="pa-4">
        <slot name="form">
          <form-person
            v-if="openEdit"
            :id="editId"
            @close="openEdit = false"
            @next="openEdit = false"
            :editable="editable"
          />
        </slot>
      </div>
    </drawer>
  </div>
</template>

<script>
import { sa_epa_required } from "../../../../config/poaRequiredFields"
import poa from "../mixins/poa.js"
import { commencementOptions } from "./epaLists"
import DialogAppointDonees from "./forms/epa/AppointDonees.vue"
import DialogCommencement from "./forms/epa/Commencement.vue"
import DialogConditionsLimitationsExclusions from "./forms/epa/ConditionsLimitationsExclusions.vue"

const storeName = "poa_sa_epa"
const documentDir = "saEpa"

export default {
  name: "PoaEnduringPowerOfDonee",
  mixins: [poa(storeName)],
  components: {
    DialogAppointDonees,
    DialogConditionsLimitationsExclusions,
    DialogCommencement,
  },
  data: () => ({
    editable: { address: true },
    required: sa_epa_required,
    documentDir: documentDir,
    commencementOptions: commencementOptions,
  }),
  methods: {},
  computed: {
    donees() {
      return this.entities_people.filter((o) =>
        this[storeName].donees.includes(o.id)
      )
    },
    doneeSuffix() {
      return this.donees.length === 1 ? "" : "s"
    },
  },
}
</script>
