<template>
  <div>
    <div class="inner">
      <div class="d-flex align-center">
        <div>
          <h1 class="font-cooper">Power of Attorney</h1>
          <component
            :is="`${user_address.value.state}-blurb`"
            class="mt-4"
          />
          <div v-if="this.user_access.poaAccessState === 'unpurchased'">
            <section-item class="text-center">
              <div
                v-if="poaPrices.price < poaPrices.original"
                class="price-originally"
              >
                ${{ poaPrices.original }}
              </div>
              <h1 class="py-4">${{ poaPrices.price }}</h1>
              <div v-if="poaPrices.price < poaPrices.original">
                For a limited time save ${{
                  poaPrices.original - poaPrices.price
                }}
                as an existing Willed client.
              </div>
            </section-item>
            <div class="pa-4 text-center">
              <div class="mb-4">
                <v-icon
                  color="primary"
                  small
                  class="mr-4"
                  >$tick</v-icon
                >Simple and secure
              </div>
              <div class="mb-4">
                <v-icon
                  color="primary"
                  small
                  class="mr-4"
                  >$tick</v-icon
                >Unlimited updates for 12 months
              </div>
              <div
                class="mb-4 font-weight-bold d-flex align-center justify-center"
              >
                <v-chip
                  color="primary"
                  text-color="black"
                  class="mr-4 flex-shrink-0"
                  >BONUS</v-chip
                >
                <div>Store important information in your Digital Vault</div>
              </div>
            </div>
            <v-btn
              v-bind="largeButton"
              @click="openCheckout = true"
              style="display: block; margin: 0 auto 12px"
              >Buy now</v-btn
            >
          </div>
        </div>
        <v-spacer />
      </div>
    </div>
    <div v-if="this.user_access.poaAccessState !== 'unpurchased'">
      <div class="divider" />
      <div
        class="mx-6 mt-5"
        v-if="!aboutYouComplete"
      >
        <h2 class="text-lg">About You</h2>
        <p
          class="opacity-70 mt-1 mb-0"
          style="font-size: 15px"
        >
          Enter the details below to get started. Once this is complete you will
          able to create the Enduring Power of Attorney documents for your
          state.
        </p>
      </div>
      <About
        v-if="!aboutYouComplete"
        class="px-6 pt-8 pb-3"
      />
      <GetStarted
        v-else
        :poaAccessState="this.user_access.poaAccessState"
      />
      <div class="divider" />
      <div
        class="text-center pa-4"
        :class="{ 'disabled-workaround': noDocumentsDownloadable }"
      >
        <transition
          name="slide"
          mode="out-in"
        >
          <v-alert
            outlined
            color="orange"
            class="mb-8"
            icon="mdi-alert-circle"
            v-if="showDocsIncompleteAlert"
          >
            You must have completed at least one document before you can
            continue to download.
          </v-alert>
        </transition>
        <v-btn
          v-bind="largeButton"
          @click="submitDownload()"
          >Download
        </v-btn>
      </div>
    </div>

    <drawer
      :open.sync="openCheckout"
      title="Payment"
    >
      <payment-form
        v-if="openCheckout"
        v-bind="localFormatted"
        @completed="openCheckout = false"
      />
    </drawer>

    <select-documents
      v-if="showSelectDocuments"
      @close="showSelectDocuments = false"
    ></select-documents>
    <download-poa
      title="Download Poa"
      @download="downloadCompleted"
    ></download-poa>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import PaymentForm from "@/v2/layouts/purchase/PaymentForm.vue"

import Drawer from "../components/wrapper/Drawer"
import SectionItem from "../components/wrapper/SectionItem"
import About from "./poa/About"
import NswBlurb from "./poa/blurbs/NswBlurb.vue"
import VicBlurb from "./poa/blurbs/VicBlurb.vue"
import DownloadPoa from "./poa/DownloadPoa.vue"
import GetStarted from "./poa/GetStarted"
import SelectDocuments from "./poa/SelectDocuments.vue"

export default {
  name: "PagePOA",
  components: {
    PaymentForm,
    DownloadPoa,
    SelectDocuments,
    Drawer,
    About,
    GetStarted,
    SectionItem,
    NswBlurb,
    VicBlurb,
  },
  mounted() {
    if (!this.poa_states.includes(this.user_address.value.state))
      this.$router.push("/")
  },
  data: () => ({
    openCheckout: false,
    showSelectDocuments: false,
    showDocsIncompleteAlert: false,
    local: {
      partner: false, // TODO: is this required?
      subscription: true,
    },
    tab: 0,
    tabs: [
      {
        key: "poa",
        label: "POA",
      },
      {
        key: "download",
        label: "Download",
      },
    ],
  }),
  methods: {
    downloadCompleted() {},
    submitDownload() {
      if (!this.noDocumentsDownloadable) this.showSelectDocuments = true
      else {
        this.showDocsIncompleteAlert = true
        setTimeout(() => {
          this.showDocsIncompleteAlert = false
        }, 10000)
      }
    },
  },
  watch: {
    aboutYouComplete(val) {
      if (val) {
        document.documentElement.classList.remove("dialog-open")
      }
    },
  },
  computed: {
    ...mapGetters("account", [
      "user_access",
      "user_address",
      "user_phone",
      "user_name",
      "user_dob",
      "user_occupation",
      "docsToDownload",
      "aboutYouComplete",
    ]),
    ...mapGetters("app", ["pricing", "poa_states", "price_structure"]),
    poaPrice() {
      return this.user_access.hasPurchasedWill
        ? this.price_structure.poa.default - this.price_structure.addOnDiscount
        : this.price_structure.poa.default
    },
    poaPrices() {
      return {
        price: this.user_access.hasPurchasedWill
          ? this.price_structure.poa.default -
            this.price_structure.addOnDiscount
          : this.price_structure.poa.default,
        original: this.price_structure.poa.default,
      }
    },
    noDocumentsDownloadable() {
      return (
        !this.aboutYouComplete ||
        this.docsToDownload.filter((doc) => doc.isValid).length === 0
      )
    },
    localFormatted() {
      let val = {
        items: [
          {
            name: "Power of Attorney",
            key: "poa",
            price: this.poaPrices.price,
          },
        ],
      }
      return val
    },
  },
}
</script>

<style lang="scss" scoped>
.disabled-workaround .large-button {
  background: #c1c6d0 !important;
  color: #fff !important;
}
.price-originally {
  text-decoration: line-through;
  font-size: 18px;
  margin-bottom: 0;
}
</style>
