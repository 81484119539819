function isEmptyTrimmed(string) {
  if (typeof string === "string" && string.trim() === "") return true
  return string ? false : true
}

function isNumericString(input) {
  if (typeof input !== "string") return false
  const strippedInput = input.replace(/[\s,]/g, "")
  const numericRegex = /^\d+$/
  return numericRegex.test(strippedInput)
}

function stripNumericString(input) {
  if (typeof input !== "string") {
    throw new TypeError("Input must be a string")
  }
  const strippedInput = input.replace(/[\s,]/g, "")
  return strippedInput
}

function formatToAusCurrency(numString) {
  const number = Number(numString)
  const formatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  })
  return formatter.format(number)
}

function trimIfExists(input) {
  const trimmed = input ? input.trim() : input
  return trimmed ? trimmed : null
}

function joinWithAndOnLast(array) {
  if (!array) return array
  if (array.length === 1) return array[0]
  return array.slice(0, -1).join(", ") + " and " + array.slice(-1)
}

export {
  isEmptyTrimmed,
  isNumericString,
  stripNumericString,
  formatToAusCurrency,
  trimIfExists,
  joinWithAndOnLast,
}
