<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Appoint decision makers</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>Add up to 4 people as your decision makers.</p>
        </div>
      </notification-box>

      <h3 class="mb-4">Your decision makers:</h3>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <div key="list">
          <transition-group
            name="component-fade"
            mode="out-in"
          >
            <item-person
              v-for="(item, index) in localFormatted.decisionMakers"
              :key="item.entity"
              class="mb-2"
              :id="item.entity"
              :editable="editable"
              type="person"
              @remove="removeDecisionMaker(index)"
              ref="decisionMakersRef"
            >
              <template v-slot:details="{ entity }">
                <div
                  v-if="entity.type === 'person'"
                  class="text-sm text-cutoff opacity-70 py-1"
                >
                  <span
                    v-if="!allRequiredFieldsComplete(entity)"
                    class="font-italic"
                    >- Information required -</span
                  >
                  <span v-else>
                    {{ entity.address }}
                  </span>
                </div>
              </template>
              <div class="mx-4 my-2">
                <h4 class="mb-2">
                  Which matters shall this decision maker make decisions about:
                </h4>
                <item-select
                  v-for="matter in decisionMakerPredefinedMatters"
                  :key="matter.key + index"
                  :value="item[matter.key]"
                  @select="toggleMatter(index, matter.key)"
                  :title="matter.label"
                  class="mb-2"
                  title-size="text-base"
                />
                <item-select
                  :value="showSpecificMatters[index]"
                  @select="toggleShowSpecificMatters(index)"
                  title="limited matters (specify)"
                  title-size="text-base"
                />
                <v-input
                  class="mb-2"
                  :error-messages="
                    showErrors[`matters${index}`] &&
                    errorMessages[`matters${index}`]
                      ? errorMessages[`matters${index}`]
                      : null
                  "
                />
                <v-expand-transition>
                  <div v-if="showSpecificMatters[index]">
                    <h4 class="mb-2">Please specify:</h4>
                    <v-textarea
                      :rules="[maxLengthRule]"
                      v-bind="textAreaFieldGrow"
                      v-model="item.specificMatters"
                      :error-messages="
                        showErrors[`specificMatters${index}`] &&
                        errorMessages[`specificMatters${index}`]
                          ? errorMessages[`specificMatters${index}`]
                          : null
                      "
                    />
                  </div>
                </v-expand-transition>
              </div>
            </item-person>
          </transition-group>
        </div>
      </transition>

      <div class="text-label my-4">Add a decision maker:</div>
      <div class="mb-2">
        <btn-add-large
          label="Add person"
          class="mb-2"
          @click="openDrawerPerson = true"
          @clickDisabled="disabledMessage()"
          :disabled="isMaximumSelected"
        />
      </div>
      <v-input
        :error-messages="
          showErrors.decisionMakers && errorMessages.decisionMakers
            ? errorMessages.decisionMakers
            : null
        "
      />

      <v-expand-transition>
        <div
          v-if="this.local.decisionMakers.length > 1"
          class="mb-4"
        >
          <h2 class="mb-4">
            How do you prefer your decision maker{{ decisionMakerSuffix }} to
            make decisions?
          </h2>
          <item-select
            v-for="(item, index) in decisionsMadeOptions"
            :key="item.key"
            :value="item.key === localFormatted.decisionsMade"
            @select="selectDecisionsMadeOption(item.key)"
            :title="item.label"
            :class="{ 'mb-2': index + 1 < decisionsMadeOptions.length }"
            title-size="text-base"
          />

          <v-expand-transition>
            <div
              v-if="localFormatted.decisionsMade === 'other'"
              class="mt-2"
            >
              <h4 class="mb-2">Please specify:</h4>
              <v-textarea
                :rules="[maxLengthRule]"
                v-bind="textAreaFieldGrow"
                v-model="local.decisionsMadeOther"
                :error-messages="
                  showErrors.decisionsMadeOther &&
                  errorMessages.decisionsMadeOther
                    ? errorMessages.decisionsMadeOther
                    : null
                "
              />
            </div>
          </v-expand-transition>

          <v-input
            :error-messages="
              showErrors.decisionsMade && errorMessages.decisionsMade
                ? errorMessages.decisionsMade
                : null
            "
          />
        </div>
      </v-expand-transition>

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add decision maker"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addDecisionMaker"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="required"
      />
    </drawer>
  </div>
</template>

<script>
import { nt_app_required } from "../../../../../config/poaRequiredFields"
import entitiesForms from "../../mixins/entitiesForms.js"
import forms from "../../mixins/forms.js"
import { trimIfExists } from "../../utils"
import { decisionMakerPredefinedMatters, decisionsMadeOptions } from "../lists"

const storeName = "poa_nt_app"
const documentId = "poaNtApp"

export default {
  name: "DecisionMakers",
  mixins: [
    forms(storeName, documentId),
    entitiesForms("decisionMakers", "Maximum of 4 decision makers."),
  ],
  data: () => ({
    showErrors: {
      specificMatters0: false,
      specificMatters1: false,
      specificMatters2: false,
      specificMatters3: false,
      matters0: false,
      matters1: false,
      matters2: false,
      matters3: false,
      decisionsMade: false,
      decisionsMadeOther: false,
    },
    showSpecificMatters: [false, false, false, false],
    editable: { address: true, email: true, phone: true },
    required: nt_app_required,
    decisionMakerPredefinedMatters: decisionMakerPredefinedMatters,
    decisionsMadeOptions: decisionsMadeOptions,
  }),
  mounted() {
    this.local.decisionMakers.forEach((item, index) => {
      this.showSpecificMatters[index] = item.specificMatters ? true : false
    })
  },
  methods: {
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: {
          ...this.localFormatted,
          decisionMakers: this.localFormatted.decisionMakers.map((s) => ({
            entity: s.entity,
            allMatters: s.allMatters,
            financialMatters: s.financialMatters,
            personalMatters: s.personalMatters,
            specificMatters: trimIfExists(s.specificMatters),
          })),
          decisionsMadeOther: trimIfExists(
            this.localFormatted.decisionsMadeOther
          ),
        },
      })
    },
    addDecisionMaker(id) {
      this.setAllShowErrors(false)
      this.local.decisionMakers.push({
        entity: id,
        allMatters: false,
        financialMatters: false,
        personalMatters: false,
        specificMatters: "",
      })
      this.openDrawerPerson = false
    },
    removeDecisionMaker(index) {
      this.setAllShowErrors(false)
      this.$delete(this.local.decisionMakers, index)
    },
    selectDecisionsMadeOption(option) {
      this.setAllShowErrors(false)
      this.local.decisionsMade = option
    },
    toggleMatter(index, matter) {
      this.setAllShowErrors(false)
      this.$set(
        this.local.decisionMakers[index],
        matter,
        !this.local.decisionMakers[index][matter]
      )
    },
    toggleShowSpecificMatters(index) {
      this.setAllShowErrors(false)
      this.$set(
        this.showSpecificMatters,
        index,
        !this.showSpecificMatters[index]
      )
    },
  },
  computed: {
    excludePeople() {
      return [
        ...this.childEntities,
        ...this.localFormatted.decisionMakers.map((item) => item.entity),
      ]
    },
    decisionMakerSuffix() {
      return this.local.decisionMakers.length > 1 ? "s" : ""
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.localFormatted.decisionMakers.length > 4)
        msgs.decisionMakers = "Maximum of 4 decision makers."
      this.localFormatted.decisionMakers.forEach((decisionMaker, index) => {
        if (
          !decisionMaker.allMatters &&
          !decisionMaker.financialMatters &&
          !decisionMaker.personalMatters &&
          !this.showSpecificMatters[index]
        )
          msgs[`matters${index}`] = "Please select at least one option."
      })
      this.localFormatted.decisionMakers.forEach((decisionMaker, index) => {
        if (this.showSpecificMatters[index] && !decisionMaker.specificMatters)
          msgs[`specificMatters${index}`] = "Required"
      })
      if (
        this.localFormatted.decisionMakers.length > 1 &&
        !this.local.decisionsMade
      )
        msgs.decisionsMade = "Required"
      if (
        this.localFormatted.decisionMakers.length > 1 &&
        this.local.decisionsMade === "other" &&
        !this.local.decisionsMadeOther
      )
        msgs.decisionsMadeOther = "Required"
      return msgs
    },
    localFormatted() {
      let decisionsMade = this.local?.decisionsMade || null
      let decisionsMadeOption = decisionsMade
        ? this.decisionsMadeOptions.find((o) => o.key === decisionsMade) || null
        : null
      return {
        decisionMakers: this.local.decisionMakers || [],
        decisionsMade:
          this.local.decisionMakers.length <= 1
            ? null
            : decisionsMadeOption
            ? decisionsMade
            : null,
        decisionsMadeOther:
          decisionsMadeOption &&
          this.local.decisionMakers.length > 1 &&
          decisionsMadeOption.key === "other"
            ? this.local.decisionsMadeOther
            : null,
      }
    },
  },
}
</script>
