<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Marriage, civil union, or civil partnership</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            If, after you have appointed your attorney, you marry or enter into
            a civil union or civil partnership with someone who is not your
            attorney, your attorney’s appointment is revoked unless you complete
            this section.
          </p>
          <p>
            If you wish to prevent this enduring power of attorney from being
            revoked in the case that you marry or enter into a civil union or
            civil partnership with someone, please nominate that person here.
          </p>
        </div>
      </notification-box>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <div v-if="local.marriage">
          <item-person
            class="mb-4"
            :id="local.marriage"
            :editable="editable"
            type="person"
            @remove="local.marriage = null"
          >
            <template v-slot:details="{ entity }">
              <div
                v-if="entity.type === 'person'"
                class="text-sm text-cutoff opacity-70 py-1"
              >
                <span
                  v-if="!entity.address"
                  class="font-italic"
                  >- Address Required -</span
                >
                <span v-else>
                  {{ entity.address }}
                </span>
              </div>
            </template>
          </item-person>
        </div>
      </transition>

      <div class="mb-2">
        <btn-add-large
          :label="buttonLabel"
          class="mb-2"
          @click="openDrawerPerson = true"
          @clickDisabled="disabledMessage()"
        />
      </div>
      <v-input
        :error-messages="
          showErrors.marriage && errorMessages.marriage
            ? errorMessages.marriage
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add person"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addSubstituteGuardian"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="['address']"
      />
    </drawer>
  </div>
</template>

<script>
import entitiesForms from "../../../mixins/entitiesForms.js"
import forms from "../../../mixins/forms.js"

const storeName = "poa_act_epa"
const documentId = "poaActEpa"

export default {
  name: "Marriage",
  mixins: [
    forms(storeName, documentId),
    entitiesForms("attorneys", "Maximum of 4 attorneys."),
  ],
  data: () => ({
    showErrors: {
      marriage: false,
    },
    editable: { address: true },
  }),
  methods: {
    addSubstituteGuardian(id) {
      this.local.marriage = id
      this.openDrawerPerson = false
    },
  },
  computed: {
    excludePeople() {
      return [
        ...this.childEntities,
        ...this.local.attorneys,
        this.localFormatted.marriage,
      ]
    },
    buttonLabel() {
      return `${this.local?.marriage ? "Change" : "Add a"} person.`
    },
    localFormatted() {
      return {
        marriage: this.local.marriage || null,
      }
    },
  },
}
</script>
