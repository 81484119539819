<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">
        Terms and instructions for personal (including health) matters
      </h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            This part allows you to provide terms and instructions for your
            attorney(s). You can provide:
          </p>
          <ul>
            <li>general terms and instructions</li>
            <li>
              terms and instructions about who your attorney(s) must notify when
              exercising a power for personal (including health) matters.
            </li>
          </ul>
        </div>
      </notification-box>

      <h3 class="mb-4">
        Would you like to provide terms and instructions for your attorney(s)?
      </h3>

      <div class="toggle-yes-no">
        <item-select
          :value="showTextbox"
          @select="setShowTextbox(true)"
          title="Yes"
        />
        <item-select
          :value="!showTextbox"
          @select="setShowTextbox(false)"
          title="No"
        />
      </div>

      <v-textarea
        :rules="[maxLengthRule]"
        v-if="showTextbox"
        v-bind="textAreaFieldGrow"
        v-model="local.terms"
        :error-messages="
          showErrors.terms && errorMessages.terms ? errorMessages.terms : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from "../../../mixins/forms.js"
import { isEmptyTrimmed, trimIfExists } from "../../../utils"

const storeName = "poa_qld_epa"
const documentId = "poaQldEpa"

export default {
  name: "PersonalTerms",
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      terms: false,
    },
    showTextbox: false,
  }),
  mounted() {
    this.showTextbox = !!this.local.terms
  },
  methods: {
    setShowTextbox(value) {
      this.setAllShowErrors(false)
      this.showTextbox = value
    },
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: {
          terms: trimIfExists(this.localFormatted.terms),
        },
      })
    },
  },
  computed: {
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.showTextbox && isEmptyTrimmed(this.localFormatted.terms)) {
        msgs.terms = "Required"
      }
      return msgs
    },
    localFormatted() {
      return {
        terms: this.showTextbox ? this.local.terms : null,
      }
    },
  },
}
</script>
