<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Beliefs affecting treatment</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            It is strongly recommended that before completing this document you
            discuss your options with your doctor who knows your medical history
            and views. The doctor will also be able to explain any medical terms
            that you are unsure about and will confirm that you were able to
            understand the decisions you have made in the document and that you
            made those decisions voluntarily. You can also ask your doctor to
            witness your signature.
          </p>
          <p>
            It is your legal right to refuse any medical treatment. However, you
            may not be entitled to insist on receiving a particular treatment
            (if, for example, your health-care provider’s professional opinion
            is that the treatment would not be of benefit to you).
          </p>
        </div>
      </notification-box>

      <h3 class="mb-4">
        Do you have any religious or ethical beliefs that may affect your
        treatment?
      </h3>
      <p>If yes, describe how your beliefs might affect your treatment.</p>
      <p>
        For example: ‘Because of my religious beliefs, I do not want to receive
        any blood transfusions or organ transplants’.
      </p>

      <v-textarea
        :rules="[maxLengthRule]"
        v-bind="textAreaFieldGrow"
        v-model="local.advanceConsentDecision.beliefsAffectingTreatment"
        :error-messages="
          showErrors.beliefsAffectingTreatment &&
          errorMessages.beliefsAffectingTreatment
            ? errorMessages.beliefsAffectingTreatment
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from "../../../mixins/forms.js"
import { trimIfExists } from "../../../utils"

const storeName = "poa_nt_app"
const documentId = "poaNtApp"

export default {
  name: "ReligiousOrEthicalBeliefs",
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      beliefsAffectingTreatment: false,
    },
  }),
  methods: {
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: {
          advanceConsentDecision: {
            ...this.localFormatted.advanceConsentDecision,
            beliefsAffectingTreatment: trimIfExists(
              this.localFormatted.advanceConsentDecision
                .beliefsAffectingTreatment
            ),
          },
        },
      })
    },
  },
  computed: {
    localFormatted() {
      return {
        advanceConsentDecision: {
          ...this.local.advanceConsentDecision,
          beliefsAffectingTreatment:
            this.local &&
            this.local.advanceConsentDecision.beliefsAffectingTreatment
              ? this.local.advanceConsentDecision.beliefsAffectingTreatment
              : null,
        },
      }
    },
  },
}
</script>
