<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Conditions and restrictions</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            You can limit the attorney{{ attorneyPossessiveSuffix }} power to
            only carry out certain tasks. For example, you can specify that the
            attorney may only act to sell your house, or that the attorney can
            only act to deal with your shares. You can also place any conditions
            on how those tasks are to be carried out. For example, you can
            require that the attorney must submit accounts to a nominated
            accountant every year for audit.
          </p>
        </div>
      </notification-box>

      <h3 class="mb-4">
        Would you like to place any conditions or restrictions on your
        attorney{{ attorneyPossessiveSuffix }}
        power?
      </h3>

      <div class="toggle-yes-no">
        <item-select
          :value="showTextbox"
          @select="setShowTextbox(true)"
          title="Yes"
        />
        <item-select
          :value="!showTextbox"
          @select="setShowTextbox(false)"
          title="No"
        />
      </div>

      <v-textarea
        :rules="[maxLengthRule]"
        v-if="showTextbox"
        v-bind="textAreaFieldGrow"
        v-model="local.conditions"
        :error-messages="
          showErrors.conditions && errorMessages.conditions
            ? errorMessages.conditions
            : null
        "
      />

      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >{{ submitLabel }}</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import forms from "../../../mixins/forms.js"
import { isEmptyTrimmed, trimIfExists } from "../../../utils"

const storeName = "poa_wa_epa"
const documentId = "poaWaEpa"

export default {
  name: "ConditionsAndLimitations",
  mixins: [forms(storeName, documentId)],
  data: () => ({
    showErrors: {
      conditions: false,
    },
    showTextbox: false,
  }),
  mounted() {
    this.showTextbox = !!this.local.conditions
  },
  methods: {
    setShowTextbox(value) {
      this.setAllShowErrors(false)
      this.showTextbox = value
    },
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: {
          conditions: trimIfExists(this.localFormatted.conditions),
        },
      })
    },
  },
  computed: {
    attorneyPossessiveSuffix() {
      return this.local.attorneys.length > 1 ? "s'" : "'s"
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.showTextbox && isEmptyTrimmed(this.localFormatted.conditions))
        msgs.conditions = "Please enter your conditions or restrictions."
      return msgs
    },
    localFormatted() {
      return {
        conditions: this.showTextbox ? this.local.conditions : null,
      }
    },
  },
}
</script>
