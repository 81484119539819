const appointmentOptions = [
  {
    label: "Jointly (together)",
    text: `The attorneys must make decisions together and they must all agree.`,
    key: "joint",
  },
  {
    label: "Severally (separately)",
    text: `Each attorney must make decisions separately.`,
    key: "several",
  },
  {
    label: "Jointly and severally (act together, or act separately)",
    text: `The attorneys can make decisions separately but if they make a joint decision, they must all agree.`,
    key: "joint-and-several",
  },
  {
    label: "Act by majority attorneys",
    text: `Where there are more than two attorneys, decisions are only made when more than half of the attorneys agree. For example, if there are three attorneys, then two out of the three must agree to a decision.`,
    key: "majority",
  },
]

const matterOptions = [
  {
    label: "Personal matters only",
    text: `Personal matters are matters that relate to your personal or lifestyle affairs but do not include matters
    that relate to medical treatment, or to medical research procedures. Common examples include access
    to support services and where and with whom you live.`,
    key: "personal",
  },
  {
    label: "Financial matters only",
    text: `Financial matters are matters (including legal matters) that relate to your financial or property affairs.
    Common examples include paying expenses, making investments, undertaking a real estate transaction
    and carrying on a business.`,
    key: "financial",
  },
  {
    label: "Both personal and financial matters",
    key: "all",
  },
  {
    label: "Specified matters",
    text: `Please specify each matter (such as, one or more personal and financial matters) that you want to authorise.`,
    key: "specified",
  },
]

export { appointmentOptions, matterOptions }
