<template>
  <div>
    <div v-if="local">
      <h2 class="mb-4">Who to notify</h2>
      <notification-box
        type="info"
        class="mb-4"
        :flat="true"
      >
        <div class="py-1">
          <div class="font-weight-medium mb-2">Instructions</div>
          <p>
            If you have appointed one or more attorney(s) for personal
            (including health) matters, you can nominate one or more people who
            your attorney(s) must provide information to.
          </p>
          <p>
            Please select who your attorney(s) for personal (including health)
            matters must notify when exercising power for personal (including
            health) matters in writing.
          </p>
        </div>
      </notification-box>

      <item-select
        :value="local.personalNotify.me"
        @select="local.personalNotify.me = !local.personalNotify.me"
        title="Me"
        class="mb-4"
        ref="meSection"
      />
      <item-select
        :value="local.personalNotify.otherAttorneys"
        @select="
          local.personalNotify.otherAttorneys =
            !local.personalNotify.otherAttorneys
        "
        title="Other attorneys"
        class="mb-4"
        ref="otherAttorneysSection"
      />
      <item-select
        :value="showSelectPersons"
        @select="showSelectPersons = !showSelectPersons"
        title="Nominated persons"
        ref="otherSection"
        class="mb-4"
      >
        <v-expand-transition>
          <div
            v-if="showSelectPersons"
            class="mx-4"
          >
            <transition
              name="component-fade"
              mode="out-in"
            >
              <div
                v-if="!localFormatted.personalNotify.other.length"
                class="text-no-result"
                key="empty"
              >
                - You have not added any people -
              </div>
              <div
                key="list"
                v-else
              >
                <transition-group
                  name="component-fade"
                  mode="out-in"
                >
                  <item-person
                    v-for="(item, index) in localFormatted.personalNotify.other"
                    :key="item + index"
                    class="mb-2"
                    :id="item"
                    :editable="editable"
                    type="person"
                    @remove="removePersonToNotify(index)"
                  >
                    <template v-slot:details="{ entity }">
                      <div
                        v-if="entity.type === 'person'"
                        class="text-sm text-cutoff opacity-70 py-1"
                      >
                        <span
                          v-if="!allRequiredFieldsComplete(entity)"
                          class="font-italic"
                          >- Information required -</span
                        >
                        <span v-else>
                          {{ entity.address }}
                        </span>
                      </div>
                    </template>
                  </item-person>
                </transition-group>
              </div>
            </transition>
            <div class="mb-2">
              <btn-add-large
                label="Add person"
                class="mb-2"
                @click="openDrawerPerson = true"
                @clickDisabled="disabledMessage()"
                :disabled="isMaximumSelected"
              />
            </div>
            <v-input
              :error-messages="
                showErrors.otherPeople && errorMessages.otherPeople
                  ? errorMessages.otherPeople
                  : null
              "
            />
            <br />
          </div>
        </v-expand-transition>
      </item-select>
    </div>

    <div class="d-flex align-center">
      <v-btn
        v-bind="backButton"
        @click="close"
        ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
      >
      <v-spacer />
      <v-btn
        v-bind="buttonType"
        depressed
        @click="submit"
        >{{ submitLabel }}</v-btn
      >
    </div>
    <drawer
      :open.sync="openDrawerPerson"
      title="Add person"
    >
      <drawer-person-select
        v-if="openDrawerPerson"
        @select="addPersonToNotify"
        :exclude="excludePeople"
        :editable="{ ...editable, name: false }"
        :mandatory="required"
      />
    </drawer>
  </div>
</template>

<script>
import { qld_epa_required } from "../../../../../../config/poaRequiredFields"
import entitiesForms from "../../../mixins/entitiesForms.js"
import forms from "../../../mixins/forms.js"

const storeName = "poa_qld_epa"
const documentId = "poaQldEpa"

export default {
  name: "PersonalNotifyWho",
  mixins: [forms(storeName, documentId), entitiesForms("", "")],
  data: () => ({
    showErrors: {
      otherPeople: false,
    },
    editable: { name: true, address: true, phone: true, email: true },
    required: qld_epa_required,
    showSelectPersons: false,
  }),
  mounted() {
    this.showSelectPersons = this.local.personalNotify.other.length
  },
  methods: {
    async save() {
      this.$store.dispatch("account/save_poa", {
        id: documentId,
        data: this.localFormatted,
      })
    },
    addPersonToNotify(id) {
      this.setAllShowErrors(false)
      this.local.personalNotify.other.push(id)
      this.openDrawerPerson = false
    },
    removePersonToNotify(index) {
      this.setAllShowErrors(false)
      this.$delete(this.local.personalNotify.other, index)
    },
    disabledMessage() {
      if (this.isMaximumSelected) {
        this.$store.commit("app/SET_MESSAGE", {
          type: null,
          text: "Maximum of 20 people.",
        })
        return
      }
    },
  },
  computed: {
    excludePeople() {
      return [...this.localFormatted.personalNotify.other]
    },
    errorMessages() {
      let msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })
      if (this.localFormatted.personalNotify.other.length > 20)
        msgs.other = "Maximum of 20 people with benefits."
      if (
        this.showSelectPersons &&
        this.localFormatted.personalNotify.other.length < 1
      )
        msgs.otherPeople =
          "You have selected to nominate people to notify. Please add them."
      return msgs
    },
    isMaximumSelected() {
      return this.localFormatted.personalNotify.other.length >= 20
    },
    localFormatted() {
      return {
        personalNotify: {
          what: this.local ? this.local.personalNotify.what : null,
          me: this.local && this.local.personalNotify.me ? true : false,
          otherAttorneys:
            this.local && this.local.personalNotify.otherAttorneys
              ? true
              : false,
          other:
            this.showSelectPersons && this.local
              ? this.local.personalNotify.other
              : [],
        },
      }
    },
  },
}
</script>
