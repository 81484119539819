<template>
  <div>
    <div>
      <section-item
        @edit="open('appoint-guardians')"
        class="mb-1"
      >
        <div class="text-label mb-2">Guardians</div>
        <ul
          v-if="guardians.length"
          class="mt-2"
        >
          <li
            v-for="item in guardians"
            :key="item.id"
            class="mb-1"
          >
            {{ item.name }}
            <chip-required
              v-if="!allRequiredFieldsComplete(item)"
              text="Information required"
              @click="openEditPerson(item.id)"
              :class="{ shake: shake }"
            />
          </li>
        </ul>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('appoint-guardians')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to add at least 1 guardian
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('substitute-guardian')"
        class="mb-1"
      >
        <div class="text-label mb-2">Substitute guardian</div>
        <div
          v-if="!substituteFull"
          class="text-no-result text-left"
        >
          Click 'Edit' on the right to optionally add a substitute guardian.
        </div>
        <div v-else>
          {{ substituteFull.name }}
          <chip-required
            v-if="!allRequiredFieldsComplete(substituteFull)"
            text="Information required"
            @click="openEditPerson(local.substitute)"
            :class="{ shake: shake }"
          />
        </div>
      </section-item>

      <section-item
        @edit="open('conditions')"
        class="mb-1"
      >
        <div class="text-label">Conditions</div>
        <div
          v-if="!local.conditions"
          class="text-no-result text-left"
        >
          You have not added any conditions. Click 'Edit' on the right to add
          conditions.
        </div>
        <div
          v-else
          class="clamp-3"
        >
          {{ local.conditions }}
        </div>
      </section-item>

      <section-item
        @edit="open('advance-care-directive')"
        class="mb-1"
      >
        <div class="text-label">Advance Care Directive</div>
        <div
          v-if="!local.advanceCareDirective?.date?.day"
          class="text-no-result text-left"
        >
          You have not provided any details abotu an Advance Care Directive.
          Click 'Edit' on the right to do so.
        </div>
        <div v-else>
          Your ACD was made on: {{ local.advanceCareDirective?.date?.day }}/{{
            local.advanceCareDirective?.date?.month
          }}/{{ local.advanceCareDirective?.date?.year }}
          <p v-if="local.advanceCareDirective?.registered">
            Registration number:
            {{ local.advanceCareDirective.registrationNumber }}
          </p>
        </div>
      </section-item>

      <dialog-full
        :open.sync="dialogOpen"
        title="Enduring Guardianship"
        :path="gaUrl"
      >
        <component
          v-if="openForm"
          :is="`dialog-${openForm}`"
          @close="close"
          @next="next"
        />
      </dialog-full>
    </div>
    <drawer
      title="Edit Person"
      :open.sync="openEdit"
    >
      <div class="pa-4">
        <slot name="form">
          <form-person
            v-if="openEdit"
            :id="editId"
            @close="openEdit = false"
            @next="openEdit = false"
            :editable="editable"
          />
        </slot>
      </div>
    </drawer>
  </div>
</template>

<script>
import { tas_eg_required } from "../../../../config/poaRequiredFields"
import poa from "../mixins/poa.js"
import DialogAdvanceCareDirective from "./forms/eg/AdvanceCareDirective.vue"
import DialogAppointGuardians from "./forms/eg/AppointGuardians.vue"
import DialogConditions from "./forms/eg/Conditions.vue"
import DialogSubstituteGuardian from "./forms/eg/SubstituteGuardian.vue"

const storeName = "poa_tas_eg"
const documentDir = "tasEg"

export default {
  name: "PoaEnduringGuardians",
  mixins: [poa(storeName)],
  components: {
    DialogAppointGuardians,
    DialogSubstituteGuardian,
    DialogAdvanceCareDirective,
    DialogConditions,
  },
  methods: {},
  data: () => ({
    editable: { address: true, occupation: true, phone: true },
    required: tas_eg_required,
    documentDir: documentDir,
  }),
  computed: {
    guardians() {
      return this.entities_people.filter((o) =>
        this[storeName].guardians.includes(o.id)
      )
    },
    guardianSuffix() {
      return this.guardians.length === 1 ? "" : "s"
    },
    substituteFull() {
      return this.entities_people.find((p) => p.id === this.local.substitute)
    },
  },
}
</script>
