<template>
  <div>
    <div>
      <h3>Advance Care Statement</h3>
      <p class="opacity-70 mt-1">
        An Advance Care Statement is a statement of your views, wishes and
        beliefs about how you would like your appointed decision maker(s),
        health professionals and any other person providing care for you to act.
      </p>

      <section-item
        @edit="open('life-meaning')"
        class="mb-1"
      >
        <div class="text-label mb-2">What gives your life meaning?</div>
        <div
          v-if="local.advanceCareStatement.lifeMeaning"
          class="clamp-box clamp-2"
        >
          {{ local.advanceCareStatement.lifeMeaning }}
        </div>
        <div v-else>
          <div class="text-no-result text-left">
            Click 'Edit' on the right to optionally answer this question.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('goals-and-priorities')"
        class="mb-1"
      >
        <div class="text-label mb-2">
          If nearing death, what are your goals/priorities?
        </div>
        <div
          v-if="local.advanceCareStatement.goalsPriorities"
          class="clamp-box clamp-2"
        >
          {{ local.advanceCareStatement.goalsPriorities }}
        </div>
        <div v-else>
          <div class="text-no-result text-left">
            Click 'Edit' on the right to optionally answer this question.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('unacceptable-to-you')"
        class="mb-1"
      >
        <div class="text-label mb-2">
          If nearing death, what is unacceptable to you?
        </div>
        <div
          v-if="local.advanceCareStatement.unacceptable"
          class="clamp-box clamp-2"
        >
          {{ local.advanceCareStatement.unacceptable }}
        </div>
        <div v-else>
          <div class="text-no-result text-left">
            Click 'Edit' on the right to optionally answer this question.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('consent-to-care')"
        class="mb-1"
      >
        <div class="text-label mb-2">
          Consent to palliative and comfort care
        </div>
        <div
          v-if="local.advanceCareStatement.consentToCare !== null"
          class="clamp-box clamp-2"
        >
          <div v-if="local.advanceCareStatement.consentToCare">
            <v-icon
              color="success"
              size="16"
              class="mx-1"
              >$tick</v-icon
            >
            I would like to receive palliation and comfort care
          </div>
          <div v-else>
            <v-icon color="#ff4444">mdi-close</v-icon>
            I do not want to receive palliation and comfort care
          </div>
        </div>
        <div v-else>
          <div class="text-no-result text-left">
            Click 'Edit' on the right to optionally answer this question.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('where-to-die')"
        class="mb-1"
      >
        <div class="text-label mb-2">Where do you want to die/finish up?</div>
        <div
          class="clamp-box clamp-2"
          v-if="locationOfDeathSummary !== null"
        >
          {{ locationOfDeathSummary }}
        </div>
        <div v-else>
          <div class="text-no-result text-left">
            Click 'Edit' on the right to optionally answer this question.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('after-death')"
        class="mb-1"
      >
        <div class="text-label mb-2">What is important to you after death?</div>
        <div
          v-if="local.advanceCareStatement.afterDeathImportant"
          class="clamp-box clamp-2"
        >
          {{ local.advanceCareStatement.afterDeathImportant }}
        </div>
        <div v-else>
          <div class="text-no-result text-left">
            Click 'Edit' on the right to optionally answer this question.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('other-information')"
        class="mb-1"
      >
        <div class="text-label mb-2">Any other information?</div>
        <div
          v-if="local.advanceCareStatement.otherInformation"
          class="clamp-box clamp-2"
        >
          {{ local.advanceCareStatement.otherInformation }}
        </div>
        <div v-else>
          <div class="text-no-result text-left">
            Click 'Edit' on the right to optionally provide additional
            information that may help with making medical decisions.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('cultural-or-spiritual-requests')"
        class="mb-1"
      >
        <div class="text-label mb-2">Cultural or spiritual requests?</div>
        <div
          v-if="local.advanceCareStatement.spiritualRequests"
          class="clamp-box clamp-2"
        >
          {{ local.advanceCareStatement.spiritualRequests }}
        </div>
        <div v-else>
          <div class="text-no-result text-left">
            Click 'Edit' on the right to optionally provide cultural or
            spiritual requests.
          </div>
        </div>
      </section-item>

      <h3 class="mt-8">Advance Consent Decision</h3>
      <p class="opacity-70 mt-1">
        Advance Consent Decisions are legally binding on your health care
        provider and can include decisions about organ transplants, palliative
        care, instructions not to be put on life support, or directions about
        not receiving blood transfusions.
      </p>

      <section-item
        @edit="open('heart-stops')"
        class="mb-1"
      >
        <div class="text-label mb-2">Do you want to consent to CPR?</div>
        <div v-if="local.advanceConsentDecision.cpr !== null">
          <div
            class="my-4"
            v-if="local.advanceConsentDecision.cpr"
          >
            <v-icon
              color="success"
              size="16"
              class="mx-1"
              >$tick</v-icon
            >I consent to Cardio Pulmonary Resuscitation.
          </div>
          <div v-else>
            <v-icon color="#ff4444">mdi-close</v-icon>
            I <b>do not</b> consent to Cardio Pulmonary Resuscitation.
          </div>
        </div>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('heart-stops')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to complete this section.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('medical-treatments')"
        class="mb-1"
      >
        <div class="text-label mb-2">
          Do you have any unwanted medical treatments?
        </div>
        <div v-if="unwantedMedicalTreatmentsComplete">
          <div
            v-if="
              local.advanceConsentDecision.unwantedMedicalTreatments.feeding !==
              null
            "
          >
            <div
              v-if="
                local.advanceConsentDecision.unwantedMedicalTreatments.feeding
              "
            >
              I <b>do not</b> want artificial feeding/tube feeding.
            </div>
            <div v-else>I <b>do</b> want artificial feeding/tube feeding.</div>
          </div>
          <div
            v-if="
              local.advanceConsentDecision.unwantedMedicalTreatments
                .dialysis !== null
            "
          >
            <div
              v-if="
                local.advanceConsentDecision.unwantedMedicalTreatments.dialysis
              "
            >
              I <b>do not</b> want renal dialysis.
            </div>
            <div v-else>I <b>do</b> want renal dialysis.</div>
          </div>
          <div
            v-if="
              local.advanceConsentDecision.unwantedMedicalTreatments
                .transfusions !== null
            "
          >
            <div
              v-if="
                local.advanceConsentDecision.unwantedMedicalTreatments
                  .transfusions
              "
            >
              I <b>do not</b> want blood transfusions.
            </div>
            <div v-else>I <b>do</b> want blood transfusions.</div>
          </div>
          <div
            v-if="local.advanceConsentDecision.unwantedMedicalTreatments.other"
            class="clamp-2"
          >
            {{ local.advanceConsentDecision.unwantedMedicalTreatments.other }}
          </div>
        </div>
        <div v-else>
          <chip-required
            :class="{ shake: shake }"
            @click="open('medical-treatments')"
          />
          <div class="text-no-result text-left">
            Click 'Edit' on the right to complete this section.
          </div>
        </div>
      </section-item>

      <section-item
        @edit="open('religious-or-ethical-beliefs')"
        class="mb-1"
      >
        <div class="text-label mb-2">
          Do you have any religious or ethical beliefs that may affect your
          treatment?
        </div>
        <div
          v-if="local.advanceConsentDecision.beliefsAffectingTreatment"
          class="clamp-box clamp-2"
        >
          {{ local.advanceConsentDecision.beliefsAffectingTreatment }}
        </div>
        <div v-else>
          <div class="text-no-result text-left">
            Click 'Edit' on the right to optionally answer this question.
          </div>
        </div>
      </section-item>

      <h3 class="mt-8">Decision makers</h3>
      <p class="opacity-70 mt-1">
        Appoint decision maker(s) to make decisions on your behalf about any
        matter relevant to your health, financial or lifestyle matters.
      </p>
      <section-item
        @edit="open('decision-makers')"
        class="mb-1"
      >
        <div class="text-label mb-2">
          {{
            decisionMakers.length
              ? "Your decsion makers"
              : "Appoint decision makers"
          }}
        </div>
        <ul
          v-if="decisionMakers.length"
          class="mt-2"
        >
          <li
            v-for="item in decisionMakers"
            :key="item.id"
            class="mb-1"
          >
            {{ item.name }}
            <chip-required
              v-if="!allRequiredFieldsComplete(item)"
              text="Information required"
              @click="openEditPerson(item.id)"
              :class="{ shake: shake }"
            />
          </li>
        </ul>
        <div v-else>
          <div class="text-no-result text-left">
            Click 'Edit' on the right to optionally add decision makers.
          </div>
        </div>
      </section-item>

      <dialog-full
        :open.sync="dialogOpen"
        title="Advance Personal Plan"
        :path="gaUrl"
      >
        <component
          v-if="openForm"
          :is="`dialog-${openForm}`"
          @close="close"
          @next="next"
        />
      </dialog-full>
    </div>
    <drawer
      title="Edit Person"
      :open.sync="openEdit"
    >
      <div class="pa-4">
        <slot name="form">
          <form-person
            v-if="openEdit"
            :id="editId"
            @close="openEdit = false"
            @next="openEdit = false"
            :editable="editable"
          />
        </slot>
      </div>
    </drawer>
  </div>
</template>

<script>
import { nt_app_required } from "../../../../config/poaRequiredFields"
import poa from "../mixins/poa.js"
import DialogHeartStops from "./forms/acd/HeartStops.vue"
import DialogMedicalTreatments from "./forms/acd/MedicalTreatments.vue"
import DialogReligiousOrEthicalBeliefs from "./forms/acd/ReligiousOrEthicalBeliefs.vue"
import DialogAfterDeath from "./forms/acs/AfterDeath.vue"
import DialogConsentToCare from "./forms/acs/ConsentToCare.vue"
import DialogCulturalOrSpiritualRequests from "./forms/acs/CulturalOrSpiritualRequests.vue"
import DialogGoalsAndPriorities from "./forms/acs/GoalsAndPriorities.vue"
import DialogLifeMeaning from "./forms/acs/LifeMeaning.vue"
import DialogOtherInformation from "./forms/acs/OtherInformation.vue"
import DialogUnacceptableToYou from "./forms/acs/UnacceptableToYou.vue"
import DialogWhereToDie from "./forms/acs/WhereToDie.vue"
import DialogDecisionMakers from "./forms/DecisionMakers.vue"

const storeName = "poa_nt_app"
const documentDir = "ntApp"

export default {
  name: "PoaAdvancePersonalPlan",
  mixins: [poa(storeName)],
  components: {
    DialogLifeMeaning,
    DialogGoalsAndPriorities,
    DialogUnacceptableToYou,
    DialogWhereToDie,
    DialogOtherInformation,
    DialogCulturalOrSpiritualRequests,
    DialogConsentToCare,
    DialogAfterDeath,
    DialogHeartStops,
    DialogMedicalTreatments,
    DialogReligiousOrEthicalBeliefs,
    DialogDecisionMakers,
  },

  data: () => ({
    editable: { address: true, email: true, phone: true },
    required: nt_app_required,
    documentDir: documentDir,
  }),
  methods: {},
  computed: {
    decisionMakers() {
      return this.entities_people.filter((o) =>
        this[storeName].decisionMakers.map((s) => s.entity).includes(o.id)
      )
    },
    locationOfDeathSummary() {
      if (this.local.advanceCareStatement.locationOfDeath) {
        if (
          this.local.advanceCareStatement.locationOfDeath === "home" &&
          this.local.advanceCareStatement.homeLocation
        )
          return this.local.advanceCareStatement.homeLocation

        if (this.local.advanceCareStatement.locationOfDeath === "hospital")
          return `Hospital or hospice.`
        if (
          this.local.advanceCareStatement.locationOfDeath === "other" &&
          this.local.advanceCareStatement.otherLocation
        )
          return this.local.advanceCareStatement.otherLocation
      }
      return null
    },
    unwantedMedicalTreatmentsComplete() {
      if (
        !this.local ||
        !this.local.advanceConsentDecision.unwantedMedicalTreatments
      )
        return false
      return (
        this.local.advanceConsentDecision.unwantedMedicalTreatments.feeding !==
          null ||
        this.local.advanceConsentDecision.unwantedMedicalTreatments.dialysis !==
          null ||
        this.local.advanceConsentDecision.unwantedMedicalTreatments
          .transfusions !== null ||
        this.local.advanceConsentDecision.unwantedMedicalTreatments.other !==
          null
      )
    },
  },
}
</script>
